import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Button } from 'antd'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import classes from './Navigation.module.scss'

const SideNavItems = ({
  responsive,
  closeDrawer,
  userLoggedIn,
  logOut,
  user,
  t,
}) => {
  const partnerLinks = [
    {
      location: '/dashboard',
      icon: (
        <Icon type="dashboard" className={classes.navigation__icon} />
      ),
      text: <span>{t('component.SideNav.dashboard')}</span>,
      exact: true,
    },
    {
      location: '/profile',
      icon: (
        <Icon type="profile" className={classes.navigation__icon} />
      ),
      text: <span>{t('component.SideNav.profile')}</span>,
      exact: true,
    },
  ]

  const contractorLinks = [
    {
      location: '/dashboard',
      icon: (
        <Icon type="dashboard" className={classes.navigation__icon} />
      ),
      text: <span>{t('component.SideNav.dashboard')}</span>,
      exact: true,
    },
    {
      location: '/profile',
      icon: (
        <Icon type="profile" className={classes.navigation__icon} />
      ),
      text: <span>{t('component.SideNav.profile')}</span>,
      exact: true,
    },
  ]

  const cashierLinks = [
    {
      location: '/profile',
      icon: (
        <Icon type="profile" className={classes.navigation__icon} />
      ),
      text: <span>{t('component.SideNav.profile')}</span>,
      exact: true,
    },
    {
      location: '/dashboard',
      icon: (
        <Icon type="dashboard" className={classes.navigation__icon} />
      ),
      text: <span>{t('component.SideNav.orders')}</span>,
      exact: true,
    },
  ]

  const clientLinksLoggedIn = [
    {
      location: '/',
      icon: <Icon type="home" className={classes.navigation__icon} />,
      text: <span>{t('component.SideNav.landing')}</span>,
      exact: true,
    },
    // {
    //   location: '/order-service',
    //   icon: (
    //     <Icon
    //       type="shopping-cart"
    //       className={classes.navigation__icon}
    //     />
    //   ),
    //   text: <span>{t('component.SideNav.orderService')}</span>,
    //   exact: false,
    // },
    {
      location: '/profile',
      icon: (
        <Icon type="profile" className={classes.navigation__icon} />
      ),
      text: <span>{t('component.SideNav.profile')}</span>,
      exact: true,
    },
    {
      location: '/dashboard',
      icon: (
        <Icon type="dashboard" className={classes.navigation__icon} />
      ),
      text: <span>{t('component.SideNav.dashboard')}</span>,
      exact: true,
    },
  ]

  const boothmanLinks = [
    {
      location: '/boothman',
      icon: <Icon type="home" className={classes.navigation__icon} />,
      text: <span>{t('component.SideNav.landing')}</span>,
      exact: true,
    },
    {
      location: '/order-service',
      icon: (
        <Icon
          type="shopping-cart"
          className={classes.navigation__icon}
        />
      ),
      text: <span>{t('component.SideNav.orderService')}</span>,
      exact: false,
    },
    {
      location: '/profile',
      icon: (
        <Icon type="profile" className={classes.navigation__icon} />
      ),
      text: <span>{t('component.SideNav.profile')}</span>,
      exact: true,
    },
  ]

  const linksLoggedOut = [
    // {
    //   location: '/order-service',
    //   icon: (
    //     <Icon
    //       type="shopping-cart"
    //       className={classes.navigation__icon}
    //     />
    //   ),
    //   text: <span>{t('component.SideNav.orderService')}</span>,
    //   exact: false,
    // },
    {
      location: '/user/login',
      icon: <Icon type="user" className={classes.navigation__icon} />,
      text: <span>{t('component.SideNav.login')}</span>,
      exact: false,
    },
    {
      location: '/user/register',
      icon: (
        <Icon type="user-add" className={classes.navigation__icon} />
      ),
      text: <span>{t('component.SideNav.register')}</span>,
      exact: false,
    },
  ]

  let renderNav = () => {
    switch (user.user_type) {
      case 'contractor':
        return contractorLinks
      case 'client':
        return clientLinksLoggedIn
      case 'cashier':
        return cashierLinks
      case 'boothman':
        return boothmanLinks
      case 'partner':
        return partnerLinks
      default:
        return linksLoggedOut
    }
  }

  return (
    <div className={classes.navigation__container}>
      {(userLoggedIn ? renderNav() : linksLoggedOut).map(link => {
        return (
          <NavLink
            key={link.location}
            to={link.location}
            exact={link.exact}
            className={
              !responsive
                ? classes.navigation__link
                : classes.navigation__linkMobile
            }
            activeClassName={
              !responsive ? classes.active : classes.activeMobile
            }
            onClick={closeDrawer}
            activeStyle={{
              color: '#f05400',
            }}
          >
            {link.icon}
            {link.text}
          </NavLink>
        )
      })}
      {userLoggedIn && (
        <Button
          onClick={() => {
            logOut()
            closeDrawer()
          }}
          icon="poweroff"
          size="large"
          style={{
            position: 'absolute',
            bottom: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '90%',
          }}
        >
          Odjavi se
        </Button>
      )}
    </div>
  )
}

SideNavItems.propTypes = {
  responsive: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
}

export default withTranslation()(SideNavItems)
