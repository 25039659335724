import React, { Fragment } from 'react'

import JobForm from './JobForm'

import classes from './JobApplication.module.scss'

const JobApplication = ({ history }) => {
  return (
    <Fragment>
      <div className={classes.jobApplication__description}>
        <h3 className={classes.jobApplication__heading}>
          Prijavi se za posao
        </h3>
        <h3 className={classes.jobApplication__subHeading}>
          Zaradi sastavljući nameštaj!
        </h3>
        <p className={classes.jobApplication__text}>
          Tražimo osobe koje žele da zarade radeći na poslovima
          montaže i demontaže nameštaja, kod kupaca u domu. Radeći
          preko Homea platforme, imaćeš slobodu da sam definišeš svoje
          radno vreme i lokaciju obavljanja posla.
        </p>
        <h3 className={classes.jobApplication__subHeading}>
          Uslovi da radiš preko Homea platforme su da:
        </h3>
        <ul>
          <li>
            si sklopio/la više od 3 različita komada nameštaja
            različitih proizvođača,
          </li>
          <li>
            imaš sopstveni alat i opremu (električnu šrafilicu,
            čekiće, i sl.)
          </li>
          <li>nisi osuđivan/a i nisi u krivičnom postupku</li>
          <li>
            poseduješ pametni telefon i znaš da koristiš mobilne
            aplikacije i internet,
          </li>
          <li>
            poseduješ vozačku dozvolu i po mogućstvu sopstveno
            registrovano vozilo (preporučljivo),
          </li>
          <li>
            si kulturan/a, vaspitan/a, uredan i vodiš računa o ličnoj
            higijeni,
          </li>
          <li>se pristojno ponašaš, i da si kooperativan/a,</li>
          <li>si tačan/a, odgovoran/a, dosledan/a i staložen/a</li>
        </ul>
        <p className={classes.jobApplication__text}>
          Nakon što proslediš prijavu, svi detalji u vezi narednih
          koraka selekcije biće prosleđeni na tvoju email adresu.
        </p>
      </div>
      <JobForm history={history} />
    </Fragment>
  )
}

export default JobApplication
