let getBase64 = (img, callback) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(img)
    reader.onload = () => {
      resolve(reader.result)
      if (callback) callback(reader.result)
    }
    reader.onerror = error => reject(error)
  })
}

export default getBase64
