import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Upload, Avatar } from 'antd'
import * as loadImage from 'blueimp-load-image'

import isLowerThan10MB from '../../helpers/isLowerThan10MB'

import classes from './PreviewUploadImage.module.scss'

class PreviewUploadImage extends Component {
  state = {
    selectedFile: null,
    avatarUrl: '',
  }

  render() {
    const { fileList, avatarUrl } = this.state
    const { imageSize, handleImage } = this.props

    const uploadProps = {
      beforeUpload: file => {
        if (isLowerThan10MB(file)) {
          this.setState(
            {
              selectedFile: file,
              avatarUrl: '',
            },
            () => {
              loadImage(
                file,
                canvas => {
                  this.setState({
                    avatarUrl: canvas.toDataURL(),
                  })
                  handleImage(file)
                },
                { orientation: true }
              )
            }
          )
          return false
        }
      },
      fileList,
    }

    return (
      <div>
        <Upload
          {...uploadProps}
          className={classes.PreviewUploadImage__button}
        >
          {avatarUrl ? (
            <div
              className={classes.PreviewUploadImage__imageContainer}
              style={{ width: imageSize, height: imageSize }}
            >
              <img src={avatarUrl} alt="Avatar" />
            </div>
          ) : (
            <Avatar size={imageSize} icon="plus" shape="circle" />
          )}
        </Upload>
        <p className={classes.PreviewUploadImage__label}>
          Vaša fotografija
        </p>
        <p className={classes.PreviewUploadImage__info}>
          (maksimalna veličina fajla je 10MB)
        </p>
      </div>
    )
  }
}

PreviewUploadImage.defaultProps = {
  imageSize: 100,
}

PreviewUploadImage.propTypes = {
  handleImage: PropTypes.func.isRequired,
  imageSize: PropTypes.number,
}

export default PreviewUploadImage
