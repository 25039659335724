import React, { Fragment } from 'react'
import classes from './Terms.module.scss'

const TermsAndConditions = () => {
  return (
    <Fragment>
      <div className={classes.terms__heading}>
        <h2 className={classes.terms__name}>
          Uslovi Korišćenja Homea platforme
        </h2>
        <span className={classes.terms__date}>
          Datum poslednje izmene 27.6.2019.
        </span>
      </div>
      <div className={classes.terms__body}>
        <p>
          Kako biste koristili portal ili mobilnu aplikaciju Homea
          (Platforma), potrebno je da se upoznate i u svemu saglasite
          sa navedenim Uslovima korišćenja:
        </p>
        <p>
          Ovi Uslovi korišćenja Platforme imaju karakter ugovora po
          pristupu, te ukoliko se saglasite sa navedenim odredbama,
          proizvodiće pravno dejstvo između vas kao pojedinačnog
          korisnika Platforme (Korisnik) i HOMEA PLATFORM D.O.O.
          BEOGRAD sa sedištem u Beogradu, Republika Srbija, ul.
          Studentski Trg br. 4, matični broj: 21316482, PIB:
          110205082, šifra delatnosti: 6201 - Računarsko
          programiranje, tel:
          <a
            href="tel:0692048013"
            target="_blank"
            rel="noopener noreferrer"
          >
            0692048013
          </a>
          , email:
          <a
            href="mailto:info@homea.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@homea.io
          </a>
          , web:
          <a
            href="https://homea.rs"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://homea.rs
          </a>
        </p>
        <p>
          Saglasnošću sa ovim Uslovima i daljim korišćenjem Platforme,
          potvrđujete da ste upoznati sa docnijim odredbama.
        </p>
        <p>
          Homea zadržava pravo izmene ovih Opštih uslova u bilo kom
          trenutku kako je određeno u docnijim odredbama ovih Uslova.
        </p>
        <h4 className={classes.terms__header}>
          1. Šta je Homea platforma i način rada Platforme
        </h4>
        <p>
          Homea Platforma (Homea) je digitalni posrednički servis koji
          omogućava ostvarivanje kontakata između Korisnika i
          Sastavljača nameštaja (Sastavljač).
        </p>
        <p>
          Korisnik putem Platforme zakazuje sastavljanje nameštaja od
          strane Sastavljača u željenom mestu i u željeno vreme u
          skladu sa uslovima Platforme i dostupnošću slobodnih
          Sastavljača.
        </p>
        <p>
          Sastavljač je pravno ili fizičko lice koje na osnovu ugovora
          sa Homeom stupa u odnos sa Korisnikom u cilju sastavljanja
          nameštaja.
        </p>
        <p>
          Homea vrši naplatu naknade Korisniku za korišćenje platforme
          na osnovu objedinjenog računa za izvršene usluge (Račun).
        </p>
        <p>
          U smislu Računa iz gornjeg stava smatra se jedan račun čiji
          se ukupan iznos raspodeljuje Sastavljaču, Platformi i
          servisu za obradu platnih transakcija.
        </p>
        <h4 className={classes.terms__header}>
          2. Prava i obaveze Korisnika
        </h4>
        <p>
          Korisnik je dužan da u predviđeno vreme bude na mestu gde je
          naručio sastavljanje nameštaja.Predviđeno vreme je vreme
          koje je sam odredio kad je angažovao sastavljača, za
          pružanje usluge.
        </p>
        <p>
          Korisnik je obavezan da Sastavljaču omogućiti nesmetan rad u
          cilju uspešnog završetka posla i izvršenja usluge.
        </p>
        <p>
          Korisnik je dužan da za sastavljanje nameštaja plati
          određenu naknadu po ispostavljenom Računu.
        </p>
        <p>
          Nakon prijema imejla sa potvrdom plaćanja, naručivanje
          usluge se više ne može menjati.
        </p>
        <p>
          Ukoliko Kupac želi da promeni termin sastavljanja potrebno
          je da se obrati Homea podršci na adresu{' '}
          <a href="mailto:podrska@homea.rs">podrska@homea.rs</a>.
        </p>
        <p>
          Ukoliko Kupac želi da na postojeću narudžbinu za
          sastavljanje nameštaja naruči sastavljanje još nekog komada
          nameštaja koje nije predviđeno prvom narudžbinom, potrebno
          je da ponovo izvrši naručivanje i plati uslugu po novom
          Računu.
        </p>
        <p>
          Ukoliko je moguće, a nakon obraćanja Kupca Homei, termini
          sastavljanja biće objedinjeni, odnosno zakazani u isto
          vreme.
        </p>
        <p>
          Korisnik može oceniti Sastavljača na Platformi ocenom od 1
          do 5.
        </p>
        <p>
          Korisnik je saglasan da sa Sastavljačem može doći i lice
          koje je predstavnik Homee koje bi vršilo kontrolu rada
          Sastavljača u cilju poboljšanja korisničkog iskustva.
        </p>
        <p>
          Korisnik ima pravo na reklamaciju u skladu sa donjim
          odredbama.
        </p>
        <h4 className={classes.terms__header}>
          3. Politika reklamacija
        </h4>
        <p>
          Korisnik koji je nezadovoljan pruženom uslugom može podneti
          pisanu reklamaciju na adresu elektronske pošte{' '}
          <a href="mailto:reklamacije@homea.com">
            reklamacije@homea.com
          </a>
          , koju će Homea proslediti Sastavljaču.
        </p>
        <p>
          U slučaju da reklamacija bude uvažena Sastavljač će ponovo
          doći na mesto sastavljanja kako bi izvršio neophodne
          popravke.
        </p>
        <p>
          U slučaju da nije moguće izvršiti neophodne popravke
          Korisniku će biti vraćena naknada.
        </p>
        <p>
          Korisnik je dužan da uz reklamaciju dostavi kopiju Računa,
          odnosno potvrdu o plaćanju.
        </p>
        <p>
          Korisnik može uz reklamaciju dostaviti eventualne
          fotografije.
        </p>
        <p>
          Korisnik prihvata da se ne može izjaviti reklamacija koja se
          odnosi na proizvođača nameštaja, odnosno na kvalitet
          kupljenog nameštaja, već da se reklamacija može uložiti samo
          na kvalitet sastavljanja nameštaja koji je sastavio
          Sastavljač.
        </p>
        <h4 className={classes.terms__header}>
          4. Odredbe o plaćanju
        </h4>
        <p>
          Korisnik je u skladu sa gornjim odredbama dužan da plati
          naknadu za izvršenu uslugu.
        </p>
        <p>
          Nakon usprešno izvršenog plaćanja korisniku će biti
          dostavljena potvrda da je plaćanje uspešno izvršeno.
        </p>
        <p>
          U slučaju plaćanja platnom karticom korisnik je saglasan sa
          sledećim:
        </p>
        <h4 className={classes.terms__subheader}>
          4.1 Zaštita podataka o finansijskoj transakciji
        </h4>
        <p>
          Prilikom unošenja podataka o platnoj kartici, poverljive
          informacije se prenose putem javne mreže u zaštićenoj
          (kriptovanoj) formi. Sigurnost podataka prilikom kupovine,
          garantuje prihvatilac platnih kartica,{' '}
          <a
            href="https://www.otpsrbija.rs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            OTP banka
          </a>{' '}
          Beograd, pa se tako kompletni proces naplate obavlja na
          stranicama banke. Niti jednog trenutka podaci o platnoj
          kartici nisu dostupni našem sistemu odnosno Platformi.
        </p>
        <h4 className={classes.terms__subheader}>
          4.2 Povraćaj naplaćenih sredstava
        </h4>
        <p>
          U slučaju vraćanja robe i povraćaja sredstava kupcu koji je
          prethodno platio nekom od platnih kartica, delimično ili u
          celosti, a bez obzira na razlog vraćanja, [naziv IPM] je u
          obavezi da povraćaj vrši isključivo preko VISA, EC/MC i
          Maestro metoda plaćanja, što znači da će banka na zahtev
          prodavca obaviti povraćaj sredstava na račun korisnika
          platne kartice.
        </p>
        <h4 className={classes.terms__header}>
          5. Privatnost korisnika
        </h4>
        <p>
          Homea je dužna da štiti privatnost ličnih podataka Korisnika
          u skladu sa Zakonom o zaštiti podataka o ličnosti. S tim u
          vezi, Platforma prikuplja samo nužne i osnovne podatke o
          Korisnicima i obaveštavaju iste o načinu njihovog korišćenja
          i redovno daju korisnicima mogućnost izbora o upotrebi
          njihovih podataka.
        </p>
        <p>
          Korisnik je upoznat da Homea prikuplja i nužne podatke koji
          su potrebni za statističku obradu u smislu broja poseta i
          korisničku interakciju.
        </p>
        <h4 className={classes.terms__header}>
          6. Odricanje od odgovornosti
        </h4>
        <p>
          Homea ne može ni u kom slučaju biti odgovorna za rad
          Sastavljača u smislu kvaliteta pružene usluge, naknade štete
          ili korisničkog iskustva.
        </p>
        <p>
          Međutim, Homea će nastojati u svakom trenutku da korisničko
          iskustvo bude na najvišem mogućem nivou tako što će
          insistirati na kvalitetu pružanja usluge od strane
          Sastavljača kroz sistem ocenjivanja Sastavljača koji je
          dostupan Korisnicima, kao i terenskom kontrolom rada
          Sastavljača.
        </p>
        <p>Gornje odredbe se ne odnose na pravila o reklamaciji.</p>
        <p>
          Homea, niti ko od njenih zaposlenih, agenata, trećih
          pružalaca usluga ili Sastavljača, ne garantuje da korišćenje
          Platforme neće biti prekinuto, presretnuto ili da neće biti
          grešaka u radu.
        </p>
        <p>
          Homea ne može garantovati za eventualne posledice koje mogu
          nastati korišćenjem Platforme.
        </p>
        <p>
          Pod pojmom posledice pre svega se misli na nastalu štetu
          koja može nastati bilo kakvim nedostacima, greškom,
          izuzećem, prekidom, brisanjem, kvarom, kašnjenjem u radu ili
          prenosu, računarskim virusom, prekidom u komunikacijskoj
          vezi, krađom, uništenjem ili neovlašćenim pristupom,
          promenama ili zloupotrebom zapisa na računarskoj opremi
          odnosno imovini Korisnika.
        </p>
        <p>
          Korisnik izričito potvrđuje da Homea nije odgovorna za bilo
          kakvo moguće uvredljivo, neprikladno ili protivzakonito
          ponašanje drugih korisnika ili trećih lica (Sastavljača) te
          da rizik od štete koji može nastati kao posledica navedenog
          u potpunosti snosi Korisnik.
        </p>
        <p>
          Homea ili druga fizička i pravna lica povezana sa Homeom pri
          izradi Platforme ili sa poslovanjem, neće biti odgovorna ni
          u kojem slučaju za bilo kakvu štetu koja može nastati kao
          posledica korišćenja ili nemogućnosti korišćenja Platforme.
        </p>
        <p>
          Korisnik izričito potvrđuje da se Uslovi korišćenja odnose
          na celokupni sadržaj ovih internet stranica.
        </p>
        <p>
          <span className={classes.terms__bold}>
            Izjava o ograničenju bilo kakvih jemstava
          </span>
          : Platforma Homea i svi podaci izvedeni putem takve
          platforme pružaju se „kakvi jesu” bez ikakva jemstava,
          izričitih ili podrazumevanih, bilo koje vrste i Homea se
          izričito odriče bilo kakvih i svih jemstava i uslova,
          uključujući, između ostalog, bilo kojeg implicitnog jemstva
          mogućnosti za trgovanje, mogućnosti za određenu svrhu,
          dostupnosti, sigurnosti, prikladnosti za funkciju i
          nekršenja prava.Korisnik je isključivo odgovoran za bilo
          kakvu štetu koja proizlazi iz korišćenja platforme Homea i
          svih podataka izvedenih putem takve platforme, uključujući,
          između ostalog, bilo kakvu štetu vašem računarskom sastavu
          ili gubitak podataka.
        </p>
        <p>
          <span className={classes.terms__bold}>
            Ograničenje odgovornosti
          </span>
          : Homea ni u kom slučaju neće biti odgovorna za bilo kakvu
          indirektnu, slučajnu, posledičnu, ili posebnu štetu koja
          proizlazi iz korišćenja platforme ili u vezi s njom i bilo
          kojih podataka izvedenih putem takve platforme, bilo povodom
          povrede ugovora, kršenja jemstva, protivpravnog postupanja
          (uključujući nameru,nepažnju, odgovornost za proizvode ili
          na drugi način), ni bilo koji drugi materijalni gubitak,
          nezavisno od toga je li je Homea bila obaveštena o
          mogućnosti takve štete.
        </p>
        <p>
          <span className={classes.terms__bold}>
            Oslobađanje od odgovornosti
          </span>
          :U najvećoj meri dopuštenoj zakonom, ovim putem Korisnik
          oslobađa Homeu odgovornosti i odriče se svih potraživanja
          prema istoj, štete (stvarne i/ili posledične), troškova i
          izdatka (uključujući sudske troškove i advokatske naknade)
          svih vrsta i prirode, koji proizlaze iz upotrebe platforme
          Homea i podataka izvedenih putem takve platforme ili su na
          bilo koji način povezani s njima.
        </p>
        <p>
          Korisnicima internet platforme se zabranjuje da blokiraju,
          prekrivaju ili prepravljaju sadržaj koji je generisala Homea
          kao i da na bilo koji drugi način blokiraju ili
          onemogućavaju njegov nesmetani rad.
        </p>
        <p>
          Upotreba mehanizama, softvera ili drugih programskih alata
          koji mogu ometati normalnu aktivnost internet stranica
          Platforme kao i sa njom povezanim internet stranicama je
          zabranjena. Korisnici ne smeju da preduzimaju radnje koje
          mogu da dovedu do neodgovarajuće i preterane upotrebe
          infrastrukture ili preopterećenja platforme. Ovakve
          aktivnosti podležu i krivičnoj i građanskoj odgovornosti.
        </p>
        <p>
          Homea nije dužna da proverava niti da na bilo koji način
          kontroliše ponašanje Korisnika Platforme kada je u pitanju
          upotreba platforme. Homea ima pravo, ukoliko nađe za
          potrebno, da proverava da li su unosi, odnosno sadržaj
          objavljeni od strane Korisnika na platformi u skladu sa
          svrhom te platforme.
        </p>
        <h4 className={classes.terms__header}>7. Obaveze Homee</h4>
        <p>
          Homea se obavezuje da će Platformu činiti dostupnom svim
          Korisnicima.
        </p>

        <p>
          U slučaju da privremenog prekida rada Platforme, Homea će se
          truditi da u najkraćem roku ista bude dostupna Korisnicima i
          Sastavljačima.
        </p>

        <p>
          Platforma poštuje prava ličnosti, privatnosti, prava o
          zaštiti podataka, autorska i druga srodna prava, kao i dobre
          poslovne običaje, u svemu u skladu sa važećim propisima
          Republike Srbije.
        </p>

        <p>
          Na sva pitanja koja nisu regulisana posebnim uslovima
          korišćenja, primenjuju se ovi Uslovi korišćenja, te
          pozitivno zakonodavstvo Republike Srbije, kao i drugi
          relevantni međunarodni propisi.
        </p>

        <p>
          Homea ne garantuje neprekidnost korišćenja Platforme niti
          eventualnu nedostupnost, delimično ili potpuno
          nefunkcionisanje ili pogrešno funkcionisanje iste, kao ni za
          posledice koje bi mogle nastupiti iz upotrebe ove platforme.
          Ona nije odgovorna za tehničke probleme koji mogu da dovedu
          do kašnjenja i/ili pogrešne obrade elektronskih podataka,
          uključujući i sistemski rad.
        </p>

        <p>
          Homea ne garantuje za tačnost, pouzdanost, kao ni sam
          sadržaj postavljen od strane Korisnika, Homea ne inicira
          prenos elektronske poruke koju mu je predao korisnik usluga,
          ne vrši odabir podataka ili dokumenata koji se prenose, ne
          vrši izuzimanje ili izmenu podataka u sadržaju poruke ili
          dokumenta i ne vrši odabir primaoca prenosa.
        </p>

        <p>
          Ova izjava o odgovornosti odnosi se na svu štetu, ili
          povrede koje bi mogle nastati iz skrivenih nedostataka,
          grešaka, prekida, brisanja, kvara, kašnjenja u radu ili
          prenosu računarskih virusa, prekida u komunikacijama, krađe,
          uništenja ili neovlašćenog pristupa podacima, promene ili
          zloupotrebe podataka od strane trećih lica, ponašanja
          suprotnog Uslovima korišćenja, nemara i dr.
        </p>

        <p>
          Korisnik izričito prihvata da Homea ne može biti odgovorna
          za ponašanje drugih članova ili trećih lica, kao i da rizik
          od moguće štete u celosti snose ta lica, a u skladu sa
          važećim zakonodavstvom Republike Srbije.
        </p>

        <p>
          Platforma može biti privremeno nedostupna ili dostupna u
          ograničenom obimu, kao rezultat redovnog održavanja sistema
          ili u slučaju unapređenja sistema.
        </p>

        <p>
          Homea zadržava pravo izmene, ukidanja (privremeno ili
          trajno) bilo kog elementa platforme, kao i usluga koje
          pruža, i sadržaja odnosno unosa, bez prethodnog odobrenja
          ili obaveštenja, uz primenu dobrih poslovnih običaja, a
          naročito radi očuvanja integriteta platformi, bezbednosti
          podataka i redovnog servisiranja.
        </p>

        <p>
          Homea ima pravo da Korisniku ograniči pristup Platformi ako
          Korisnik prekrši bilo koju svoju obavezu . Pružalac usluga
          će odmah obavestiti Korisnika o takvom ograničenju.
        </p>
        <p>
          Homea ima pravo da ukine Korisniku pristup bez prethodne
          najave i da obriše sav Sadržaj treće strane postavljen od
          strane Korisnika ako Korisnik krši svoje obaveze namerno i
          više puta. Prethodna odredba se primenjuje i ako je povreda
          Korisnikovih obaveza značajna i/ili postoji rizik od
          značajne štete,
        </p>
        <p>
          Beneficijari, a posebno imaoci autorskih prava, prava na
          zaštitu žiga ili drugih imovinskih prava, mogu prijaviti i
          podneti zahtev za uklanjanje Sadržaja treće strane koji krši
          njihova prava. Korisnici mogu informisati Homea o ilegalnoj
          upotrebi Platforme u bilo kom trenutku (kontakt). Ovo
          uključuje upotrebu lažnih izjava u vezi sa identitetom
          Korisnika ili njihove kompanije (krađa identiteta).
        </p>
        <p>
          Homea ima pravo da odmah ukloni takav Sadržaj treće strane
          ako je očigledno nelegalan i/ili postoji rizik da se može
          pojaviti znatna šteta ukoliko Sadržaj treće strane ne bude
          uklonjen. Pogođeni Korisnik može dokazati zakonitost
          Sadržaja treće strane Pružaocu usluga kako bi ovaj sadržaj i
          dalje ili ponovo bio objavljen na Platformi.
        </p>
        <h4 className={classes.terms__header}>
          8. Korišćenje "kolačića" (cookies)
        </h4>
        <p>
          Kolačići, (HTTP cookie ili samo cookies) predstavlja
          jednostavnu tekstualnu datoteka koja se čuva u
          veb-pregledaču (npr. Chrome ili Firefox) dok korisnik
          pregleda neki veb-sajt. Kada korisnik u budućnosti pregleda
          taj isti sajt referiše se na podatke koji su sačuvani u
          kolačiću kako bi bio obavešten o prethodnoj korisničkoj
          aktivnosti. Kolačići mogu sadržati informacije o tome koje
          je stranice korisnik posetio, podatke o prijavi, pa i koju
          dugmad je korisnik kliknuo.
        </p>
        <p>
          Homea koristi kolačiće (cookies) za pružanje boljeg
          korisničkog iskustva. Kolačići ne sadrže vaše lične podatke,
          već su tu da omoguće bržu i korisniju upotrebu informacija i
          podataka koje ste već prethodno ostavili prilikom pristupa
          sajtu homea.rs. Ovim datotekama koje se nalaze na vašem
          uređaju ne može pristupiti neka druga internet stranica ili
          treće lice.
        </p>
        <p>
          U cilju boljeg razumevanja ponašanja Korisnika, kao i zbog
          statistike poseta, a pre svega zbog poboljšanja korisničkog
          iskustva, Platforma koristi eksterne skripte koje
          analiziraju na koji način se Korisnik ponaša na sajtu i
          koliko je koja stranica posećena. S tim u vezi, homea.rs
          koristi Google Analytics kompanije Google.
        </p>
        <p>
          Prikupljeni podaci nisu ni na koji način povezani sa vašim
          ličnim podacima koje ste dostavili prilikom registracije na
          Platformi.
        </p>
        <p>
          Ukoliko ne želite da budete predmet analiziranja i da
          primate kolačiće možete ih ograničiti ili zabraniti. U tom
          slučaju se kolačići neće odnositi na vas, ali Platforma i
          homea.rs mogu postati nefunkcionalni, odnosno nemati svoj
          pun kapacitet.
        </p>
        <p>
          U vezi sa gore navedenim, ukoliko ne želite da primate
          kolačiće i da ih skladištite u vašem veb-pretraživaču
          (browser) možete otići u opcije u alatima izabrati da ne
          želite da primate kolačiće sa našeg sajta i Platforme.
        </p>
        <p>
          Korišćenjem Homea Platforme pristajete na obradu svojih
          podataka preko pretraživača kao što je gore navedeno u
          skladu sa gore navedenom svrhom.
        </p>
        <p>
          Homea se obavezuje da neće ni u kom slučaju bilo kome
          prosleđivati podatke o korisnicima osim u slučajevima kada
          je to obavezno pozitivnim propisima Republike Srbije.
        </p>
        <p>Kolačići koje homea.rs koristi su:</p>
        <div className={classes.terms__tableWrapper}>
          <table>
            <tbody>
              <tr>
                <td>Naziv kolačića</td>
                <td>Svrha</td>
                <td>Pružalac</td>
                <td>Trajanje</td>
              </tr>
              <tr>
                <td>_ga</td>
                <td>
                  Ovaj kolačić beleži jedinstvenu posetu i služi kako
                  bi preneo statističke podatke o tome kako Korisnik
                  kao posetilac koristi Platformu
                </td>
                <td>Homea.rs / Google Analytics</td>
                <td>2 godine</td>
              </tr>
              <tr>
                <td>_gat</td>
                <td>
                  Ovaj kolačić koristi Google Analytics kako bi na
                  osnovu zahteva odnosno njegovog aktiviranja znao
                  koji je broj poseta Platformi
                </td>
                <td>Homea.rs / Google Analytics</td>
                <td>12 sati</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>
                  Ovaj kolačić beleži jedinstvenu posetu i služi kako
                  bi preneo statističke podatke o tome kako Korisnik
                  kao posetilac koristi Platformu
                </td>
                <td>Homea.rs / Google Analytics</td>
                <td>1 dan</td>
              </tr>
              <tr>
                <td>homea</td>
                <td>
                  Ovaj kolačić se koristi u svrhu čuvanja tokena
                  trenutno ulogovan usera.
                </td>
                <td>Homea.rs</td>
                <td>1 dan</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4 className={classes.terms__header}>
          9. Autorsko pravo i prava industrijske svojine
          (Intelektualna svojina)
        </h4>
        <p>
          Sva prava iz oblasti intelektualne svojine su u vlasništvu
          kompanije Homea koja zadržava sva prava od kojih je
          sačinjena Platforma kao i svih prava u vezi sa Platformom,
          što znači da je zabranjena svaka upotreba programerskog
          koda, dizajna, ilustracija, fotografija, zvukova, muzičkih
          numera, tekstova, prava žiga, baza podataka i drugih srodnih
          prava iz ovih oblasti bez izričite saglasnosti Homea.
        </p>
        <p>
          Korisnik je saglasan da neće ni na koji način povrediti bilo
          koje gore navedeno pravo.
        </p>
        <p>
          Homea ima isključiva autorska prava na platformu i na
          pojedine elemente koji je čine, kao što su tekst, vizuelni i
          audio elementi, vizuelni identitet, logotip-znak, podaci i
          baze podataka, programski kod, know-how i drugi elementi
          servisa. Homea se deklariše kao jedini i isključivi vlasnik
          internet domena: <a href="https://homea.rs">www.homea.rs</a>
        </p>
        <p>
          Homea ima isključivu odgovornost za sadržaj na kojem ima
          autorska prava.
        </p>
        <p>
          Neovlašćeno korišćenje bilo kog dela ili platforme u celini,
          od strane trećih lica, bez izričite prethodne dozvole u
          pisanoj formi izdate od Homea kao nosioca isključivih
          autorskih prava, smatraće se povredom autorskih prava i
          podložno je pokretanju relevantnih sudskih i drugih
          postupaka.
        </p>
        <p>
          Platforma sadrži i elemente na kojima isključiva autorska i
          druga prava iz oblasti intelektualne svojine imaju druga
          lica, kao što su sadržaji korisnika usluga, poslovnih
          partnera, oglašivača i besplatnog sadržaja. Druga lica imaju
          isključivu odgovornost za sadržaj na kojem su nosioci tih
          prava, bez obzira na to što se takav sadržaj nalazi na
          Platformi.
        </p>
        <p>
          Postavljanjem unosa na platformu, član je saglasan da isti
          postane vidljiv svakom korisniku platforme. Dalje prenošenje
          sadržaja ili dela sadržaja sa bilo kog dela platforme je
          dozvoljeno uz napomenu da je sadržaj preuzet sa homea
          internet stranice.
        </p>
        <p>
          Svaka strana samostalno snosi odgovornost za sadržaj koji je
          njeno autorsko delo.
        </p>
        <p>
          Visa, MasterCard i Maestro su zaštićene robne marke i Homea
          ne polaže nikakva prava na njih, a koristi ih uz dozvolu.
        </p>
        <h4 className={classes.terms__header}>
          10. Prelazne i završne odredbe
        </h4>
        <p>
          Homea ima pravo da u svako doba izmeni ili dopuni ove Uslove
          korišćenja, tako što će izmene i dopune u prečišćenom tekstu
          objaviti na internet prezentaciji, najmanje osam dana pre
          dana početka njihove primene.
        </p>
        <p>
          Ukoliko Korisnik nije saglasan sa Uslovima korišćenja,
          slobodan je da obustavi svoje članstvo.
        </p>
        <p>
          Na sve sporove do kojih može doći između Homea i Korisnika u
          vezi sa korišćenjem Homea platforme kao i Korisnika
          međusobno, primenjuju se važeći propisi Republike Srbije.
          Strane se obavezuju da spor pokušaju da reše mirnim putem, a
          ukoliko u tome ne uspeju, nadležan za rešavanje spora je
          Privredni sud u Beogradu za sporove između pravnih lica,
          odnosno Prvi osnovni sud u Beogradu za sporove između
          fizičkih lica, ili fizičkih i pravnih lica.
        </p>
        <p>
          Ovi Uslovi korišćenja počinju da se primenjuju osam dana od
          dana njihovog objavljivanja.
        </p>
        <p>
          Prava i obaveze iz ovih Opštih uslova nisu prenosiva na
          druga fizička ili pravna lica.
        </p>
      </div>
    </Fragment>
  )
}

export default TermsAndConditions
