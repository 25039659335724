import React, { Component } from 'react'
import {
  Form,
  Input,
  Button,
  Select,
  Checkbox,
  message,
  Modal,
} from 'antd'
import { apiPost } from '../../api'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'

import PreviewUploadImage from '../../components/PreviewUploadImage'
import setDocumentTitle from '../../helpers/setDocumentTitle'
import errorHelper from '../../helpers/errorHelper'

import classes from './JobApplication.module.scss'

const { Option } = Select

const options = {
  transport: [
    {
      label: 'Automobil',
      value: 'car',
    },
    {
      label: 'Kombi/Van/Pikap',
      value: 'truck',
    },
    {
      label: 'Bicikl',
      value: 'bicycle',
    },
    {
      label: 'Motor/skuter',
      value: 'bike',
    },
    {
      label: 'Gradski prevoz',
      value: 'city_transport',
    },
  ],
  education: [
    {
      label: 'Osnovna škola',
      value: 'elementary_school',
    },
    {
      label: 'Srednja škola',
      value: 'high_school',
    },
    {
      label: 'Fakultet',
      value: 'faculty',
    },
    {
      label: 'Master studije',
      value: 'master_degree',
    },
  ],
  workStatus: [
    { label: 'Student', value: 'student' },
    { label: 'Zaposlen', value: 'employed' },
    { label: 'Vlasnik firme/zanatske radnje', value: 'owner' },
    { label: 'Nezaposlen', value: 'unemployed' },
    { label: 'Povremeno zaposlen', value: 'partial_employed' },
    { label: 'Penzioner', value: 'pension' },
  ],
  workingExperience: [
    {
      label: '0 - 6 meseci',
      value: '0-6m',
    },
    {
      label: 'od 6 meseci do godinu dana',
      value: '6m-1y',
    },
    {
      label: '1 do 3 godine',
      value: '1y-3y',
    },
    {
      label: '3 do 5 godina',
      value: '3y-5y',
    },
    {
      label: '5 do 10 godina',
      value: '5y-10y',
    },
    {
      label: 'više od 10 godina',
      value: '10y',
    },
  ],
  workingDays: [
    {
      label: '1 do 2 dana',
      value: '1d-2d',
    },
    {
      label: '2 do 3 dana',
      value: '2d-3d',
    },
    {
      label: 'više od 3 dana',
      value: '3d',
    },
    {
      label: 'samo vikendom',
      value: 'weekend',
    },
  ],
}

class JobForm extends Component {
  state = {
    selectedImage: null,
    isSendingApplication: false,
  }

  componentDidMount() {
    setDocumentTitle(this.props.t('page.title.jobApplication'))
  }

  handleSubmitApplication = async formValues => {
    let formData = new FormData()
    formData.append('avatar', this.state.selectedImage)

    Object.keys(formValues).forEach(value => {
      if (value !== 'avatar') {
        formData.append(value, formValues[value])
      }
    })

    this.setState({ isSendingApplication: true })

    try {
      const res = await apiPost.createJobApplication(formData)
      let data = _get(res, 'data', null)
      if (!data) {
        this.setState({ isSendingApplication: false })
        throw new Error(
          this.props.t('jobApplication.failedToSubmitApplication')
        )
      }
      this.setState({ isSendingApplication: false })
      Modal.success({
        title: (
          <h4 className={classes.jobApplication__modalTitle}>
            Hvala na prosleđenoj prijavi!
          </h4>
        ),
        content: (
          <div className={classes.jobApplication__modalContent}>
            <p>
              Na Vaš email smo poslali informacije u vezi daljih
              koraka selekcije!
            </p>
            <p>Srećno!</p>
          </div>
        ),
        okText: 'U redu',
      })

      this.props.history.push('/')
    } catch (error) {
      this.setState({ isSendingApplication: false })
      let status = error.response.status || null
      if (status === 422) {
        const res = error.response.data.errors
        Object.keys(res).map(key => message.error(res[key]))
      } else {
        errorHelper(
          error,
          this.props.t('jobApplication.failedToSubmitApplication')
        )
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) this.handleSubmitApplication(values)
    })
  }

  getImage = image => this.setState({ selectedImage: image })

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <div className="form__wrapper">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('avatar', {
              rules: [
                {
                  required: true,
                  message: 'Molimo ubacite Vašu fotografiju!',
                },
              ],
            })(
              <div className={classes.jobApplication__photo}>
                <PreviewUploadImage handleImage={this.getImage} />
              </div>
            )}
          </Form.Item>
          <Form.Item label="Ime">
            {getFieldDecorator('first_name', {
              rules: [
                {
                  required: true,
                  message: 'Molimo unesite Vaše ime!',
                },
              ],
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item label="Prezime">
            {getFieldDecorator('last_name', {
              rules: [
                {
                  required: true,
                  message: 'Molimo unesite Vaše prezime!',
                },
              ],
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item label="Godina rođenja">
            {getFieldDecorator('birthday', {
              rules: [
                {
                  required: true,
                  message: 'Molimo unesite Vašu godinu rođenja!',
                  length: 4,
                },
              ],
            })(<Input type="number" />)}
          </Form.Item>
          <Form.Item label="Grad">
            {getFieldDecorator('city', {
              initialValue: 'beograd',
              rules: [
                {
                  message:
                    'Molimo unesite grad u kome želite da radite!',
                },
              ],
            })(
              <Select disabled>
                <Option value="beograd">Beograd</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Adresa">
            {getFieldDecorator('address', {
              rules: [
                {
                  required: true,
                  message: 'Molimo unesite Vašu adresu!',
                },
              ],
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item label="Broj telefona">
            {getFieldDecorator('phone', {
              rules: [
                {},
                {
                  required: true,
                  message: 'Molimo unesite svoj broj telefona!',
                },
              ],
            })(<Input type="tel" />)}
          </Form.Item>
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'Unešen email nije validan!',
                },
                {
                  required: true,
                  message: 'Molimo unesite svoj email!',
                },
              ],
            })(<Input type="email" />)}
          </Form.Item>
          <Form.Item label="Stručna sprema">
            {getFieldDecorator('education', {
              rules: [
                {
                  required: true,
                  message: 'Molimo unesite završenu stručnu spremu!',
                },
              ],
            })(
              <Select placeholder="Izaberi opciju">
                {options.education.map(edu => {
                  return <Option key={edu.value}>{edu.label}</Option>
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Radni status">
            {getFieldDecorator('work_status', {
              rules: [
                {
                  required: true,
                  message: 'Molimo unesite radni status!',
                },
              ],
            })(
              <Select placeholder="Izaberi opciju">
                {options.workStatus.map(status => (
                  <Option key={status.value}>{status.label}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Koji model pametnog telefona poseduješ">
            {getFieldDecorator('phone_model', {
              rules: [
                {
                  required: true,
                  message:
                    'Molimo unesite model vašeg pametnog telefona!',
                },
              ],
            })(<Input type="text" placeholder="npr. Iphone 6" />)}
          </Form.Item>
          <Form.Item label="Izaberi prevozno sredstvo koje možeš da koristiš za posao">
            {getFieldDecorator('transport', {
              rules: [
                {
                  required: true,
                  message: 'Molimo izaberite prevozno sredstvo!',
                },
              ],
            })(
              <Checkbox.Group
                options={options.transport}
                className={classes.jobApplication__checkboxList}
              />
            )}
          </Form.Item>
          <Form.Item label="Iskustvo u sastavljanju nameštaja">
            {getFieldDecorator('experience', {
              rules: [
                {
                  required: true,
                  message:
                    'Molimo unesite iskustvo u sastavljanju nameštaja!',
                },
              ],
            })(
              <Select placeholder="Izaberi opciju">
                {options.workingExperience.map(experience => (
                  <Option key={experience.value}>
                    {experience.label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Spremam se da radim tokom nedelje">
            {getFieldDecorator('work_time', {
              rules: [
                {
                  required: true,
                  message: 'Molimo unesite radno vreme!',
                },
              ],
            })(
              <Select placeholder="Izaberi opciju">
                {options.workingDays.map(day => (
                  <Option key={day.value}>{day.label}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Uslovi korišćenja">
            <div className={classes.jobApplication__termsContainer}>
              <p className={classes.jobApplication__termsText}>
                Podatke koje ostavite Homea će koristiti isključivo u
                svrhu odabira sastavljača nameštaja i zaključenja
                ugovora sa njima. Izuzev pomenutih slučajeva Homea ne
                prosleđuje, ne prodaje niti iznajmljuje ove podatke.
                Ukoliko želite da ispravite, obrišete ili preuzmete
                svoje podatke možete nam se obratiti putem e-mejla:
                <a href="mailto:info@homea.rs"> info@homea.rs</a>
              </p>
            </div>
            {getFieldDecorator('terms', {
              valuePropName: 'checked',
              rules: [
                {
                  required: true,
                  message:
                    'Morate se složiti sa uslovima korišćenja!',
                },
              ],
            })(<Checkbox>Slažem se sa uslovima korišćenja</Checkbox>)}
          </Form.Item>
          <Form.Item>
            <div className="btn__container">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={this.state.isSendingApplication}
              >
                Pošalji
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

const JobFormWrapped = Form.create({
  name: 'job_application',
})(JobForm)

export default withTranslation()(JobFormWrapped)
