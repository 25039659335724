import scroll from 'react-scroll'
import scrollerConfig from '../configs/scrollerConfig'
import PropTypes from 'prop-types'

const { scroller } = scroll

let scrollToElement = (elementName, offset) => {
  scroller.scrollTo(elementName, {
    ...scrollerConfig,
    offset: offset,
  })
}

scrollToElement.defaultProps = {
  offset: 150,
}

scrollToElement.propTypes = {
  elementName: PropTypes.string.isRequired,
  offset: PropTypes.number,
}

export default scrollToElement
