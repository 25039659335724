import React from 'react'
import PropTypes from 'prop-types'

import classes from './Burger.module.scss'

const Burger = ({ handleSideNav }) => {
  return (
    <div
      className={classes.burger__container}
      onClick={handleSideNav}
    >
      <div className={classes.burger__line} />
      <div className={classes.burger__line} />
      <div className={classes.burger__line} />
    </div>
  )
}

Burger.propTypes = {
  handleSideNav: PropTypes.func,
}

export default Burger
