import React from 'react'
import PropTypes from 'prop-types'

import PosTerminalIcon from '../../../assets/Icons/PosTerminal'

import classes from '../JobOverview.module.scss'

const OfflinePayment = ({ handlePayment, dataToSend }) => {
  return (
    <div
      className={classes.payment__button}
      onClick={() => handlePayment(2, dataToSend)}
    >
      <PosTerminalIcon />
      <div className={classes.payment__name}>POS / GOTOVINA</div>
    </div>
  )
}

OfflinePayment.propTypes = {
  handlePayment: PropTypes.func.isRequired,
  dataToSend: PropTypes.object.isRequired,
}

export default OfflinePayment
