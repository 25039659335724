import React from 'react'
import PropTypes from 'prop-types'

const InputSkeleton = ({ height, width, margin }) => {
  let skeletonCss = {
    height: height,
    width: width,
    backgroundColor: '#f2f2f2',
  }

  return (
    <div style={{ margin: margin ? '40px 0 18px 0' : null }}>
      <div style={skeletonCss} />
    </div>
  )
}

InputSkeleton.defaultProps = {
  margin: true,
}

InputSkeleton.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  margin: PropTypes.bool,
}

export default InputSkeleton
