import React from 'react'
import { Pagination } from 'antd'
import PropTypes from 'prop-types'

const PaginationCustom = ({
  ordersLength,
  metaTotal,
  metaPerPage,
  handlePageChange,
  currentPage,
}) => {
  let scrollOnChange = () => {
    window.scrollTo(0, 0)
  }

  return (
    <React.Fragment>
      {ordersLength < metaTotal &&
        (!currentPage ? (
          <Pagination
            style={{ marginTop: 16, textAlign: 'center' }}
            defaultCurrent={1}
            total={metaTotal}
            pageSize={metaPerPage}
            onChange={e => {
              handlePageChange(e)
              scrollOnChange()
            }}
          />
        ) : (
          <Pagination
            style={{ marginTop: 16, textAlign: 'center' }}
            current={currentPage}
            total={metaTotal}
            pageSize={metaPerPage}
            onChange={e => {
              handlePageChange(e)
              scrollOnChange()
            }}
          />
        ))}
    </React.Fragment>
  )
}

PaginationCustom.propTypes = {
  ordersLength: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  metaPerPage: PropTypes.number.isRequired,
  metaTotal: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
}

export default PaginationCustom
