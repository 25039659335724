import React from 'react'
import { Drawer } from 'antd'
import PropTypes from 'prop-types'

import SideNavItems from './SideNavItems'
import Logo from '../Logo/Logo.js'

import classes from './Navigation.module.scss'

const SideNav = ({
  onClose,
  visible,
  userLoggedIn,
  logOut,
  user,
}) => {
  return (
    <Drawer
      placement="left"
      closable={true}
      onClose={onClose}
      visible={visible}
    >
      <div className={classes.navigation__logo} onClick={onClose}>
        <Logo width={200} color="orange" withLink />
      </div>
      <SideNavItems
        responsive
        closeDrawer={onClose}
        userLoggedIn={userLoggedIn}
        logOut={logOut}
        user={user}
      />
    </Drawer>
  )
}

SideNav.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
  user: PropTypes.object,
}

export default SideNav
