import React, { Component } from 'react'
import { Form, Input, Button, message } from 'antd'
import queryString from 'query-string'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'

import { apiPost } from '../../../api'
import Loader from '../../../components/Loader'

import classes from '../Auth.module.scss'

class ChangePasswordResetWrapper extends Component {
  state = {
    confirmDirty: false,
    email: '',
    loader: false,
  }

  componentDidMount() {
    let { email, token } = queryString.parse(
      this.props.location.search
    )
    this.setState({ email: email, token: token }, () => {
      return this.setInitialValues(email)
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { email, password } = values
        this.handleResetPassword(email, password, this.state.token)
      }
    })
  }

  setInitialValues = email => {
    const { form } = this.props
    form.setFieldsValue({
      email: email,
    })
  }

  handleResetPassword = async (email, password, token) => {
    this.setState({ loader: true })

    try {
      const res = await apiPost.resetPassword(
        email,
        password,
        password,
        token
      )
      let msg = _get(res, 'data.message', null)
      if (!msg)
        throw new Error(this.props.t('auth.failResetPassword'))
      this.setState({ loader: false })
      message.success(msg)
      this.props.history.push('/user/login')
    } catch (err) {
      this.setState({ loader: false })
      const res = err.response.data.errors
      Object.keys(res).map(key => message.error(res[key]))
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form
    if (value && value !== form.getFieldValue('password')) {
      callback(this.props.t('auth.comparePasswordFail'))
    } else {
      callback()
    }
  }

  handleConfirmBlur = e => {
    const value = e.target.value
    this.setState({
      confirmDirty: this.state.confirmDirty || !!value,
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <div className="form__wrapper">
        <h2 className={classes.auth__heading}>Promeni lozinku</h2>
        <Form onSubmit={this.handleSubmit}>
          <div className="form__wrapper">
            <Form.Item label="E-mail">
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'Molimo unesite validnu email adresu!',
                  },
                  {
                    required: true,
                    message: 'Molimo unesite email!',
                  },
                ],
              })(<Input type="email" />)}
            </Form.Item>
            <Form.Item label="Lozinka" hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Molimo unesite novu lozinku!',
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item label="Potvrda lozinke" hasFeedback>
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: 'Molimo ponovite novu lozinku!',
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(<Input.Password onBlur={this.handleConfirmBlur} />)}
            </Form.Item>
          </div>
          <div className="btn__container">
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className={classes.auth__button}
            >
              Resetuj lozinku
            </Button>
          </div>
        </Form>
        {this.state.loader && <Loader />}
      </div>
    )
  }
}

const ChangePasswordReset = Form.create({ name: 'change password' })(
  ChangePasswordResetWrapper
)

export default withTranslation()(ChangePasswordReset)
