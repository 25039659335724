import React from 'react'
import PropTypes from 'prop-types'

import ProfileAvatar from '../ProfileAvatar'
import classes from './JobCard.module.scss'

let ContractorInfo = ({ image, first_name, last_name }) => {
  return (
    <div className={classes.card__contractor}>
      <div className={classes.card__contractorContainer}>
        <div className={classes.card__avatar}>
          <ProfileAvatar src={image} />
        </div>
        <h4 className={classes.card__contractorName}>
          {first_name} {last_name}
        </h4>
      </div>
    </div>
  )
}

ContractorInfo.propTypes = {
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default ContractorInfo
