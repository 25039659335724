import _get from 'lodash/get'

import setAuthorizationToken from './setAuthorizationToken'
import { message } from 'antd'
import { apiPost } from '../api'
import errorHelper from '../helpers/errorHelper'

let logOutRequest = async () => {
  try {
    let res = await apiPost.logOut()
    let userRes = _get(res, 'data', null)
    if (!userRes) throw new Error('Logout error')
  } catch (error) {
    errorHelper(error, 'Logout error')
  }
}

let logOutGlobally = async (cookies, user, translation) => {
  logOutRequest()

  cookies.remove('homea', { path: '/' })
  setAuthorizationToken(false)
  user.onDeleteUser()
  message.success(translation('auth.successLogout'))
}

export default logOutGlobally
