import React, { Fragment, Component } from 'react'
import { List, Icon, Button, Skeleton, Rate, Popconfirm } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import formatMoney from '../../../../helpers/moneyFormatter'
import HorizontalLine from '../../../../components/HorizontalLine'
// import ProductImage from '../../../../components/ProductImage'
import ProfileAvatar from '../../../../components/ProfileAvatar'
import OrderFeedback from '../../../../components/OrderFeedback'

import classes from '../../Dashboard.module.scss'

class OrderItem extends Component {
  state = {
    isFeedbackModalVisible: false,
    feedbackRate: null,
    feedbackComment: '',
  }

  // componentDidUpdate(prevProps, prevState) {
  //   Object.entries(this.props).forEach(
  //     ([key, val]) =>
  //       prevProps[key] !== val && console.log(`Prop '${key}' changed`)
  //   )
  //   if (this.state) {
  //     Object.entries(this.state).forEach(
  //       ([key, val]) =>
  //         prevState[key] !== val &&
  //         console.log(`State '${key}' changed`)
  //     )
  //   }
  // }

  render() {
    let {
      order,
      cancelOrder,
      loading,
      orderType,
      t,
      canSendFeedback,
      orderId,
    } = this.props

    let activeActions = order.can_cancel
      ? [
          <Popconfirm
            title={t('dashboard.cancelOrderPrompt')}
            okText={t('global.yes')}
            cancelText={t('global.no')}
            onConfirm={() => cancelOrder(order.id)}
            key="cancel-order"
          >
            <div className={classes.dashboard__actions}>
              <Button type="primary">{t('global.cancel')}</Button>
            </div>
          </Popconfirm>,
        ]
      : []

    return (
      <Fragment>
        <List.Item
          className={['section', classes.dashboard__clientItem].join(
            ' '
          )}
        >
          <Skeleton avatar title={false} loading={loading} active>
            <div className={classes.dashboard__description}>
              <p>
                Zakazano za:{' '}
                {moment(order.scheduled_at).format(
                  'DD.MM.YYYY. u HH:mm'
                )}
                h{' '}
              </p>
              <p>Očekivano trajanje posla: {order.duration}h</p>
            </div>
            <HorizontalLine margin={16} />
            <div className={classes.dashboard__clientHeader}>
              <div
                className={[
                  classes.dashboard__contractorInfo,
                  classes['dashboard__contractorInfo--client'],
                ].join(' ')}
              >
                <div className={classes.dashboard__avatar}>
                  <ProfileAvatar src={order.contractor.image} />
                </div>
                <div className={classes.dashboard__contractorBasics}>
                  <h4
                    className={classes.dashboard__clientName}
                    style={{ marginBottom: 0 }}
                  >
                    {order.contractor.first_name}{' '}
                    {order.contractor.last_name}
                  </h4>
                  <Rate
                    value={parseInt(order.contractor.rating)}
                    allowHalf
                    disabled
                    className={classes.dashboard__rate}
                  />
                  <div className={classes.dashboard__contact}>
                    {order.contractor.email && (
                      <span
                        className={classes.dashboard__contactInfo}
                      >
                        <Icon
                          type="mail"
                          className={classes.dashboard__icon}
                        />
                        <a href={`mailto:${order.contractor.email}`}>
                          {order.contractor.email}
                        </a>
                      </span>
                    )}
                    {order.contractor.phone && (
                      <span
                        className={classes.dashboard__contactInfo}
                      >
                        <Icon
                          type="phone"
                          className={classes.dashboard__icon}
                        />
                        <a href={`tel:${order.contractor.phone}`}>
                          {order.contractor.phone}
                        </a>
                      </span>
                    )}
                    {order.contractor.phone_additional && (
                      <span
                        className={classes.dashboard__contactInfo}
                      >
                        <Icon
                          type="phone"
                          className={classes.dashboard__icon}
                        />
                        <a
                          href={`tel:${order.contractor.phone_additional}`}
                        >
                          {order.contractor.phone_additional}
                        </a>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={[classes.dashboard__clientOrder].join(' ')}
              >
                <ul className={classes.dashboard__itemList}>
                  {order.items.map(item => (
                    <li
                      key={item.id}
                      className={classes.dashboard__item}
                    >
                      {/* <ProductImage src={item.product.image} border /> */}
                      <p className={classes.dashboard__productName}>
                        {item.category.name} ({item.quantity} kom.)
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <HorizontalLine margin={16} />
            <div className={classes.dashboard__itemFooter}>
              <p
                className={classes.dashboard__price}
                style={{ marginBottom: 0 }}
              >
                {formatMoney(order.price)}
              </p>
              {orderType === 'active' && activeActions}
              <OrderFeedback
                id={orderId}
                canSendFeedback={canSendFeedback}
              />
            </div>
          </Skeleton>
        </List.Item>
      </Fragment>
    )
  }
}

OrderItem.propTypes = {
  order: PropTypes.object,
  cancelOrder: PropTypes.func,
  loading: PropTypes.bool,
  orderType: PropTypes.string,
  orderId: PropTypes.number,
}

export default withTranslation()(OrderItem)
