import React, { Component, Fragment } from 'react'
import { Button, message } from 'antd'
import { withRouter } from 'react-router'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { withTranslation } from 'react-i18next'

import ReportSingleItem from '../../components/ReportSingleItem'
import SignaturePad from '../../components/SignaturePad'
import ClientInfo from '../../components/ClientInfo'
import { apiGet, apiPost } from '../../api'
import setDocumentTitle from '../../helpers/setDocumentTitle'
import errorHelper from '../../helpers/errorHelper'
import Loader from '../../components/Loader'
import isPageForbidden from '../../helpers/isPageForbidden'

import classes from './ContractorReport.module.scss'

class ContractorReport extends Component {
  state = {
    signature: '',
    id: null,
    orderItems: [],
    numberOfItems: null,
    client: {},
    parking: false,
    finishedItems: [],
    loading: false,
  }

  componentDidMount() {
    let { t, match } = this.props

    setDocumentTitle(t('page.title.contractorReport'))

    let id = match.params.id
    if (id) {
      this.setState({ id })
      this.getOrderDetails(id, true)
    }
  }

  getOrderDetails = async (id, populateFinished) => {
    this.setState({ loading: true })
    try {
      const res = await apiGet.getOrderById(id)
      let order = _get(res, 'data', null)
      if (!order)
        throw new Error(
          this.props.t('contractorReport.failedToGetOrderDetails')
        )
      this.setState({
        loading: false,
        orderItems: order.items,
        client: order.contact,
        numberOfItems: order.items.length,
        parking: order.address.has_parking,
      })
      if (populateFinished) {
        let finishedItems = order.items.filter(
          o => o.finished === true
        )

        this.setState({ finishedItems })
      }

      let canFinish = order.can_finish

      if (!canFinish) this.props.history.push('/')
    } catch (error) {
      this.setState({ loading: false })
      errorHelper(
        error,
        this.props.t('contractorReport.failedToGetOrderDetails')
      )

      isPageForbidden(error) && this.props.history.push('/')
    }
  }

  finishOrderItem = async (id, duration, note) => {
    try {
      const res = await apiPost.finishOrderItem(id, duration, note)
      let orderItem = _get(res, 'data', null)
      if (!orderItem)
        throw new Error(
          this.props.t('contractorReport.failedToFinishOrderItem')
        )
      this.setState({
        finishedItems: [...this.state.finishedItems, id],
      })
    } catch (error) {
      errorHelper(
        error,
        this.props.t('contractorReport.failedToFinishOrderItem')
      )
    }
  }

  finishOrder = async () => {
    let { id, signature } = this.state
    let { t, history } = this.props

    try {
      const res = await apiPost.finishOrder(id, signature)
      let order = _get(res, 'data', null)
      if (!order)
        throw new Error(t('contractorReport.failedToFinishOrder'))
      message.success(order.message)
      history.push('/dashboard')
    } catch (error) {
      errorHelper(error, t('contractorReport.failedToFinishOrder'))
    }
  }

  handleSingleItem = (id, time, note) => {
    let hours = time.hours ? parseInt(time.hours) * 60 : 0
    let minutes = time.minutes ? parseInt(time.minutes) : 0

    let timeSumm = hours + minutes

    this.finishOrderItem(id, timeSumm, note)
  }

  handleSignature = signature => this.setState({ signature })

  render() {
    let {
      signature,
      id,
      orderItems,
      numberOfItems,
      finishedItems,
      client,
      parking,
      loading,
    } = this.state

    let disabledButton = !!signature

    let finishedItem = id =>
      !!finishedItems.find(itemId => itemId === id)

    return (
      <Fragment>
        {!loading ? (
          <Fragment>
            <h3 className={classes.ContractorReport__heading}>
              Narudžbina broj: {id}
            </h3>
            {!_isEmpty(client) && (
              <ClientInfo
                firstName={client.first_name}
                lastName={client.last_name}
                email={client.email}
                phone={client.phone}
                phoneAdditional={client.phone_additional}
                note={client.note}
                hasParking={parking}
                size="large"
                style={{ marginBottom: 16 }}
              />
            )}
            <div className={classes.ContractorReport__itemContainer}>
              {orderItems.map(i => {
                return (
                  <ReportSingleItem
                    // productImage={i.product.image}
                    key={i.id}
                    id={i.id}
                    services={i.category}
                    quantity={i.quantity}
                    handleSingleItem={this.handleSingleItem}
                    finished={finishedItem(i.id) || i.finished}
                    images={i.images}
                  />
                )
              })}
            </div>
            {numberOfItems === finishedItems.length && (
              <SignaturePad handleSignature={this.handleSignature} />
            )}
            <Button
              size="large"
              onClick={this.finishOrder}
              disabled={!disabledButton}
              className={classes.ContractorReport__finishBtn}
            >
              Završi posao
            </Button>
          </Fragment>
        ) : (
          <Loader />
        )}
      </Fragment>
    )
  }
}

export default withRouter(withTranslation()(ContractorReport))
