import React, { Component } from 'react'

import FeedbackItems from '../../../components/FeedbackItems'
import Pagination from '../../../components/Pagination'

import classes from '../Dashboard.module.scss'

class Feedback extends Component {
  render() {
    let { feedback, handleFeedbackPageChange } = this.props

    return (
      <div>
        <h3 className={classes.dashboard__name}>
          Ostavljeni utisci ({feedback.meta.total})
        </h3>
        <FeedbackItems feedbackData={feedback} />
        <Pagination
          ordersLength={feedback.data.length}
          metaTotal={feedback.meta.total}
          metaPerPage={feedback.meta.per_page}
          handlePageChange={handleFeedbackPageChange}
        />
      </div>
    )
  }
}

export default Feedback
