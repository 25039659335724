import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { Tabs, message } from 'antd'
import { withTranslation } from 'react-i18next'

import Scheduler from '../../components/Scheduler'
import MunicipalityPicker from '../../components/MunicipalityPicker'
import { apiGet, apiPost } from '../../api'
import UserInfoForm from '../../components/UserInfoForm'
import UserAvatar from '../../components/UserAvatar'
import Loader from '../../components/Loader'
import errorHelper from '../../helpers/errorHelper'

const { TabPane } = Tabs

class ProfileWithId extends Component {
  state = {
    loadedUser: {},
    edit: false,
    userId: null,
  }

  componentDidMount() {
    let id = this.props.match.params.id
    if (id) {
      this.getClientProfile(id)
      this.setState({ userId: id })
    }
  }

  getClientProfile = async id => {
    try {
      let res = await apiGet.getContractorById(id)
      let contractor = _get(res, 'data', null)
      if (!contractor)
        throw new Error(this.props.t('profile.failedToGetUserInfo'))
      this.setState({
        loadedUser: contractor,
      })
    } catch (error) {
      errorHelper(error, this.props.t('profile.failedToGetUserInfo'))
    }
  }

  editTime = async workingDays => {
    try {
      const res = await apiPost.handleContractorWorkingHoursWithId(
        workingDays,
        this.state.userId
      )
      let workingHours = _get(res, 'data', null)
      if (!workingHours)
        throw new Error(this.props.t('profile.editWorkingTimeFailed'))
      message.success(workingHours.message)
    } catch (error) {
      errorHelper(
        error,
        this.props.t('profile.editWorkingTimeFailed')
      )
    }
  }

  enableEditMode = () => {
    this.setState({ edit: !this.state.edit })
  }

  handleInputChange = (field, value) => {
    let newObj = Object.assign({}, this.state.loadedUser)
    newObj[field] = value
    this.setState({ loadedUser: newObj })
  }

  editPersonalInfo = async () => {
    const { loadedUser } = this.state

    let userObject = {
      first_name: loadedUser.first_name,
      last_name: loadedUser.last_name,
      address: loadedUser.address,
      phone: loadedUser.phone,
      phone_additional: loadedUser.phone_additional,
    }

    try {
      let res = await apiPost.handleContractorProfileEditWithId(
        userObject,
        this.state.userId
      )
      let userChanged = _get(res, 'data', null)
      if (!userChanged)
        throw new Error(this.props.t('profile.editUserInfoFailed'))
      message.success(userChanged.message)
      this.setState({ edit: false })
    } catch (error) {
      errorHelper(error, this.props.t('profile.editUserInfoFailed'))
    }
  }

  render() {
    const { user } = this.props
    const { loadedUser, edit } = this.state

    // console.log(loadedUser)

    return (
      <Fragment>
        <UserAvatar
          userType={user.user_type}
          editableImage={loadedUser.image && loadedUser.image}
          userId={this.state.userId}
          imageUrl={this.state.loadedUser.image}
        />
        {!_isEmpty(this.state.loadedUser) ? (
          <Tabs>
            <TabPane tab="Radno vreme" key="0">
              <Scheduler
                workStatus={this.state.loadedUser.active}
                workingDays={
                  loadedUser.working_hours && loadedUser.working_hours
                }
                editTime={this.editTime}
                userId={this.state.userId}
              />
            </TabPane>
            <TabPane tab="Opštine" key="1">
              <MunicipalityPicker
                selectedMunicipality={loadedUser.municipalities}
                withId={this.state.userId}
              />
            </TabPane>
            <TabPane tab="Podaci" key="3">
              <UserInfoForm
                user={loadedUser}
                handleUserInputs={this.handleInputChange}
                enableEditMode={this.enableEditMode}
                editEnabled={edit}
                handleSave={this.editPersonalInfo}
              />
            </TabPane>
          </Tabs>
        ) : (
          <Loader />
        )}
      </Fragment>
    )
  }
}

export default withRouter(withTranslation()(ProfileWithId))
