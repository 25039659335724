import React from 'react'

const OnlinePayment = () => {
  return (
    <React.Fragment>
      <svg
        width="34"
        height="25"
        viewBox="0 0 34 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.971 0.501404H2.84413C1.57595 0.501404 0.543945 1.5334 0.543945 2.80159V22.2012C0.543945 23.4694 1.57595 24.5014 2.84413 24.5014H30.971C32.2392 24.5014 33.2712 23.4694 33.2712 22.2012V2.80159C33.2712 1.5334 32.2392 0.501404 30.971 0.501404ZM32.1803 22.2012C32.1803 22.8678 31.6381 23.4105 30.971 23.4105H2.84413C2.17704 23.4105 1.63485 22.8678 1.63485 22.2012V2.80159C1.63485 2.13504 2.17704 1.59231 2.84413 1.59231H30.971C31.6381 1.59231 32.1803 2.13504 32.1803 2.80159V22.2012Z"
          fill="none"
        />
        <path
          d="M9.27126 16.3196H4.36217C4.06053 16.3196 3.81671 16.5639 3.81671 16.865C3.81671 17.1661 4.06053 17.4105 4.36217 17.4105H9.27126C9.57289 17.4105 9.81671 17.1661 9.81671 16.865C9.81671 16.5639 9.57289 16.3196 9.27126 16.3196Z"
          fill="none"
        />
        <path
          d="M16.3621 16.3196H11.453C11.1514 16.3196 10.9076 16.5639 10.9076 16.865C10.9076 17.1661 11.1514 17.4105 11.453 17.4105H16.3621C16.6638 17.4105 16.9076 17.1661 16.9076 16.865C16.9076 16.5639 16.6638 16.3196 16.3621 16.3196Z"
          fill="none"
        />
        <path
          d="M4.90762 19.0469H4.36217C4.06053 19.0469 3.81671 19.2912 3.81671 19.5923C3.81671 19.8934 4.06053 20.1378 4.36217 20.1378H4.90762C5.20926 20.1378 5.45308 19.8934 5.45308 19.5923C5.45308 19.2912 5.20926 19.0469 4.90762 19.0469Z"
          fill="none"
        />
        <path
          d="M8.18031 19.0469H7.0894C6.78776 19.0469 6.54395 19.2912 6.54395 19.5923C6.54395 19.8934 6.78776 20.1378 7.0894 20.1378H8.18031C8.48195 20.1378 8.72576 19.8934 8.72576 19.5923C8.72576 19.2912 8.48195 19.0469 8.18031 19.0469Z"
          fill="none"
        />
        <path
          d="M10.9076 19.0469H10.3621C10.0605 19.0469 9.81665 19.2912 9.81665 19.5923C9.81665 19.8934 10.0605 20.1378 10.3621 20.1378H10.9076C11.2092 20.1378 11.453 19.8934 11.453 19.5923C11.453 19.2912 11.2092 19.0469 10.9076 19.0469Z"
          fill="none"
        />
        <path
          d="M14.1803 19.0469H13.0894C12.7878 19.0469 12.5439 19.2912 12.5439 19.5923C12.5439 19.8934 12.7878 20.1378 13.0894 20.1378H14.1803C14.4819 20.1378 14.7258 19.8934 14.7258 19.5923C14.7258 19.2912 14.4819 19.0469 14.1803 19.0469Z"
          fill="none"
        />
        <path
          d="M15.9748 19.205C15.8761 19.3087 15.8167 19.4505 15.8167 19.5923C15.8167 19.7341 15.8761 19.8759 15.9748 19.9796C16.0779 20.0778 16.2203 20.1378 16.3621 20.1378C16.5039 20.1378 16.6457 20.0778 16.7494 19.9796C16.8476 19.8759 16.9076 19.7341 16.9076 19.5923C16.9076 19.4505 16.8476 19.3087 16.7494 19.205C16.5421 19.0032 16.1707 19.0032 15.9748 19.205Z"
          fill="none"
        />
        <path
          d="M26.7258 14.1378C26.0298 14.1378 25.3791 14.3281 24.8167 14.6543C24.2543 14.3281 23.6036 14.1378 22.9076 14.1378C20.8027 14.1378 19.0894 15.8505 19.0894 17.9559C19.0894 20.0614 20.8027 21.7741 22.9076 21.7741C23.6036 21.7741 24.2543 21.5838 24.8167 21.2576C25.3791 21.5838 26.0298 21.7741 26.7258 21.7741C28.8307 21.7741 30.544 20.0614 30.544 17.9559C30.544 15.8505 28.8307 14.1378 26.7258 14.1378ZM25.6349 17.9559C25.6349 18.7163 25.3207 19.4036 24.8167 19.8988C24.3127 19.4036 23.9985 18.7163 23.9985 17.9559C23.9985 17.1956 24.3127 16.5083 24.8167 16.013C25.3207 16.5083 25.6349 17.1956 25.6349 17.9559ZM20.1803 17.9559C20.1803 16.4521 21.4038 15.2287 22.9076 15.2287C23.2518 15.2287 23.5785 15.2996 23.8823 15.4168C23.2785 16.0921 22.9076 16.9807 22.9076 17.9559C22.9076 18.9312 23.2785 19.8198 23.8823 20.495C23.5785 20.6123 23.2518 20.6832 22.9076 20.6832C21.4038 20.6832 20.1803 19.4598 20.1803 17.9559ZM26.7258 20.6832C26.3816 20.6832 26.0549 20.6123 25.7511 20.495C26.3549 19.8198 26.7258 18.9312 26.7258 17.9559C26.7258 16.9807 26.3549 16.0921 25.7511 15.4168C26.0549 15.2996 26.3816 15.2287 26.7258 15.2287C28.2296 15.2287 29.4531 16.4521 29.4531 17.9559C29.4531 19.4598 28.2296 20.6832 26.7258 20.6832Z"
          fill="none"
        />
        <path
          d="M5.04671 12.5014H8.31944H9.13217H12.4049C13.0829 12.5014 13.6349 11.9494 13.6349 11.2714V6.90778V6.09505C13.6349 5.41705 13.0829 4.86505 12.4049 4.86505H8.31944H5.04671C4.36871 4.86505 3.81671 5.41705 3.81671 6.09505V11.2714C3.81671 11.9494 4.36871 12.5014 5.04671 12.5014ZM7.08944 9.22869H4.90762V8.13778H7.08944V9.22869ZM4.90762 11.2714V10.3196H7.08944V11.2714C7.08944 11.3189 7.09817 11.3641 7.10362 11.4105H5.04671C4.9698 11.4105 4.90762 11.3483 4.90762 11.2714ZM12.544 9.22869H10.3622V8.27687C10.3622 8.19996 10.4243 8.13778 10.5013 8.13778H12.4049C12.4523 8.13778 12.4976 8.12905 12.544 8.1236V9.22869ZM12.4049 11.4105H10.348C10.3534 11.3641 10.3622 11.3189 10.3622 11.2714V10.3196H12.544V11.2714C12.544 11.3483 12.4818 11.4105 12.4049 11.4105ZM12.4049 5.95596C12.4818 5.95596 12.544 6.01814 12.544 6.09505V6.90778C12.544 6.98469 12.4818 7.04687 12.4049 7.04687H10.5013C9.82326 7.04687 9.27126 7.59887 9.27126 8.27687V11.2714C9.27126 11.3483 9.20907 11.4105 9.13217 11.4105H8.31944C8.24253 11.4105 8.18035 11.3483 8.18035 11.2714V6.09505C8.18035 6.01814 8.24253 5.95596 8.31944 5.95596H12.4049ZM5.04671 5.95596H7.10362C7.09817 6.00232 7.08944 6.0476 7.08944 6.09505V7.04687H4.90762V6.09505C4.90762 6.01814 4.9698 5.95596 5.04671 5.95596Z"
          fill="none"
        />
      </svg>
    </React.Fragment>
  )
}

export default OnlinePayment
