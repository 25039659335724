import React, { Fragment, useState } from 'react'
import { Button, Modal } from 'antd'
import PropTypes from 'prop-types'

import sentencePreview from '../../helpers/sentencePreview'

import classes from './ClientNote.module.scss'

let ClientNote = ({ text, style }) => {
  let [isModalVisible, setModalVisible] = useState(false)

  let lengthCut = 120
  let textLength = text && text.length

  return (
    <Fragment>
      {text && (
        <div className={classes.note__wrapper} style={style}>
          <p className={classes.note__text}>
            {sentencePreview(text, lengthCut)}
          </p>
          {textLength > lengthCut && (
            <Button onClick={() => setModalVisible(true)}>
              Više
            </Button>
          )}
        </div>
      )}

      <Modal
        title="Napomena"
        footer={null}
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
      >
        <p>{text}</p>
      </Modal>
    </Fragment>
  )
}

ClientNote.propTypes = {
  text: PropTypes.string,
  style: PropTypes.object,
}

export default ClientNote
