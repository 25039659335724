import React from 'react'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'

const PageNotFound = () => (
  <Result
    style={{ paddingTop: 68 }}
    status="404"
    title="404"
    subTitle="Stranica koju ste posetili ne postoji."
    extra={
      <Button type="primary" size="large">
        <Link to="/">Vratite se nazad</Link>
      </Button>
    }
  />
)

export default PageNotFound
