import React, { Fragment } from 'react'
import { Modal } from 'antd'
import moment from 'moment'
import shortid from 'shortid'
import PropTypes from 'prop-types'

import moneyFormat from '../../helpers/moneyFormatter'
import HorizontalLine from '../HorizontalLine'

import classes from './OrderMoreInfo.module.scss'

const OrderMoreInfo = ({ modalInfo, closeInfoModal }) => {
  let { order, visible } = modalInfo

  let contractor = order.contractor
  let client = order.contact
  let address = order.address
  let duration = order.duration
  let scheduled_at = order.scheduled_at
  let price = order.price
  let items = order.items

  return (
    <Modal
      title="Informacije o porudžbini"
      visible={visible}
      onOk={() => closeInfoModal()}
      onCancel={() => closeInfoModal()}
      footer={null}
      centered
    >
      <h4 className={classes.infoModal__heading}>Porudžbina</h4>
      <p>
        Trajanje posla: {duration}h <br />
        Cena: {moneyFormat(price)} <br />
        Zakazano za:{' '}
        {moment(scheduled_at).format('DD.MM.YYYY. u HH:mm')}h <br />
      </p>
      <HorizontalLine margin={8} />
      <h4 className={classes.infoModal__heading}>Izvođač</h4>

      <div>
        Ime i prezime: {contractor.first_name} {contractor.last_name}
        {contractor.email && (
          <div className={classes.infoModal__contact}>
            Email:
            <a href={`mailto:${contractor.email}`}>
              {contractor.email}
            </a>
          </div>
        )}
        {contractor.phone && (
          <div className={classes.infoModal__contact}>
            Telefon:
            <a href={`tel:${contractor.phone}`}>{contractor.phone}</a>
          </div>
        )}
      </div>
      <HorizontalLine margin={8} />
      <h4 className={classes.infoModal__heading}>Klijent</h4>
      <div>
        <p className={classes.infoModal__clientInfo}>
          Ime i prezime: {client.first_name} {client.last_name}
          <br />
          {client.email && (
            <Fragment>
              Email: {client.email}
              <br />
            </Fragment>
          )}
          {client.phone && (
            <Fragment>
              Telefon: {client.phone}
              <br />
            </Fragment>
          )}
          {client.phone_additional && (
            <Fragment>
              Dodatni telefon: {client.phone_additional} <br />
            </Fragment>
          )}
          Grad i opština: {address.city}, {address.municipality}{' '}
          <br />
          Ulica: {address.street}{' '}
          {address.street_number && (
            <Fragment>
              {address.street_number}
              <br />
            </Fragment>
          )}
          {address.floor && (
            <Fragment>
              Sprat broj: {address.floor}
              <br />
            </Fragment>
          )}
          {address.apartment && (
            <Fragment>
              Stan: {address.apartment}
              <br />
            </Fragment>
          )}
          Parking: {address.has_parking ? 'Ima' : 'Nema'}
        </p>
      </div>
      <HorizontalLine margin={8} />
      <h4 className={classes.infoModal__heading}>
        Izabrani nameštaj
      </h4>
      {items.map(i => {
        let generatedKey = shortid.generate()
        return (
          <ul
            className={classes.infoModal__orderList}
            key={generatedKey}
          >
            <li>
              {i.category.name} ({i.quantity} kom.)
            </li>
          </ul>
        )
      })}
    </Modal>
  )
}

OrderMoreInfo.propTypes = {
  modalInfo: PropTypes.object.isRequired,
  closeInfoModal: PropTypes.func.isRequired,
}

export default OrderMoreInfo
