import React, { Fragment } from 'react'
import moment from 'moment'
import { Alert } from 'antd'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import 'moment/locale/sr'

import Section from '../OrderSections'
import SectionName from '../OrderSections/SectionName'
import HorizontalLine from '../HorizontalLine'
import moneyFormat from '../../helpers/moneyFormatter'
import timeFormat from '../../helpers/timeFormat'
import uppercase from '../../helpers/upppercase'
import TransactionInfo from '../TransactionInformation'

import classes from './OrderOverview.module.scss'

moment.locale('sr')

const OrderOverview = ({
  user,
  service,
  alertType = null,
  transactionInfo,
  edit,
  isAlertVisible,
  t,
}) => {
  let priceSum = 0
  let formatDate = moment(user.orderDate, 'DD.MM.YYYY')

  service.map(s => {
    return (priceSum += s.priceSum)
  })

  return (
    <Fragment>
      {isAlertVisible && (
        <Alert
          message={
            alertType === 'success'
              ? t('paymentLanding.paymentSuccess')
              : t('paymentLanding.paymentFailed')
          }
          description={
            <TransactionInfo
              transactionInformation={transactionInfo}
            />
          }
          type={alertType}
          style={{ marginBottom: 16 }}
        />
      )}

      <Section>
        <div>
          <SectionName text="Pregled usluge" margin={15} />
          <ul className={classes.orderOverview__itemList}>
            {service.map((s, index) => (
              <li className={classes.orderOverview__item} key={index}>
                <p className={classes.orderOverview__productName}>
                  {s.name} ({s.quantity} kom.) -{' '}
                  {!edit ? timeFormat(s.time) : s.time} -{' '}
                  {moneyFormat(s.priceSum)}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <HorizontalLine margin={16} />
        <SectionName text="Osnovni podaci kupca" margin={15} />
        <p className={classes.orderOverview__name}>
          {user.first_name} {user.last_name}
          <br />
          Beograd, {user.municipality} <br />
          {user.address} {user.street_num}
        </p>
        <HorizontalLine margin={16} />
        <SectionName text="Termin sastavljanja" margin={15} />
        <span className={classes.orderOverview__name}>
          {uppercase(moment(formatDate).format('dddd'))},{' '}
          {moment(formatDate).format('D')}{' '}
          {moment(formatDate).format('MMMM')}, {user.time}h
        </span>
        <HorizontalLine margin={16} />
        <SectionName text="Cena" margin={15} />
        {
          <span className={classes.orderOverview__price}>
            {moneyFormat(priceSum)}
          </span>
        }
      </Section>
    </Fragment>
  )
}

OrderOverview.propTypes = {
  user: PropTypes.object.isRequired,
  service: PropTypes.array.isRequired,
  edit: PropTypes.bool.isRequired,
  isAlertVisible: PropTypes.bool.isRequired,
  alertType: PropTypes.string,
  transactionInfo: PropTypes.object,
}

export default withTranslation()(OrderOverview)
