import React, { Component } from 'react'
import { Switch, Icon, Select, Button, message } from 'antd'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'

import WorkingStatusSwitch from '../WorkingStatusSwitch'
import { apiPost } from '../../api'
import errorHelper from '../../helpers/errorHelper'

import classes from './Scheduler.module.scss'
// import { DayPickerInput } from 'react-day-picker/types/DayPickerInput'

const { Option } = Select

const time = [
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
]

const week = [
  {
    name: 'Ponedeljak',
    id: 1,
  },
  {
    name: 'Utorak',
    id: 2,
  },
  {
    name: 'Sreda',
    id: 3,
  },
  {
    name: 'Četvrtak',
    id: 4,
  },
  {
    name: 'Petak',
    id: 5,
  },
  {
    name: 'Subota',
    id: 6,
  },
  {
    name: 'Nedelja',
    id: 0,
  },
]

class Scheduler extends Component {
  state = {
    workingDays: [],
    loaded: false,
    workStatus: true,
    loadingWorkStatus: false,
  }

  componentDidMount() {
    this.setState({
      workingDays: this.props.workingDays,
      loaded: true,
      workStatus: this.props.workStatus,
    })
  }

  handleStatusChange = async status => {
    this.setState({ loadingWorkStatus: true })
    try {
      let res
      if (this.props.userId) {
        res = await apiPost.handleContractorWorkingStatusWithId(
          this.props.userId
        )
      } else {
        res = await apiPost.handleContractorWorkingStatus(status)
      }
      let statusChange = _get(res, 'data', null)

      if (!statusChange)
        throw new Error(
          this.props.t(
            'component.Scheduler.failedToChangeWorkingStatus'
          )
        )
      this.setState({
        workStatus: statusChange.contractor.active,
        loadingWorkStatus: false,
      })
      message.success(statusChange.message)
    } catch (error) {
      this.setState({ loadingWorkStatus: false })

      errorHelper(
        error,
        this.props.t(
          'component.Scheduler.failedToChangeWorkingStatus'
        )
      )
    }
  }

  handleDay = (checked, id) => {
    if (checked) {
      this.setState({
        workingDays: [
          ...this.state.workingDays,
          {
            weekday: id,
          },
        ],
      })
    } else {
      let newWorkingDays = [...this.state.workingDays].filter(v => {
        return v['weekday'] !== id
      })
      this.setState({
        workingDays: newWorkingDays,
      })
    }
  }

  handleTimeFrom = (value, id) => {
    let newObject = _find(this.state.workingDays, { weekday: id })
    newObject.from = value
    let removeOld = this.state.workingDays.filter(v => v !== id)

    this.setState({ workingDays: [...removeOld] })
  }

  handleTimeTo = (value, id) => {
    let newObject = _find(this.state.workingDays, { weekday: id })
    newObject.to = value
    let removeOld = this.state.workingDays.filter(v => v !== id)

    this.setState({ workingDays: [...removeOld] })
  }

  postWorkinghours = () => {
    let dayWithoutFromAndTo = this.state.workingDays
      .filter(day => !day.from || !day.to)
      .map(d => d.weekday)
      .map(w => _find(week, { id: w }))

    if (_isEmpty(dayWithoutFromAndTo)) {
      this.props.editTime(this.state.workingDays)
    } else {
      message.info(
        `${
          dayWithoutFromAndTo.length > 0 ? 'Dan' : 'Dani'
        }: ${dayWithoutFromAndTo.map(
          t => ' ' + t.name
        )} nije ispunjen!`
      )
    }
  }

  findHours = (id, direction) => {
    if (this.state.loaded) {
      let hours = _find(this.state.workingDays, {
        weekday: id,
      })

      if (direction === 'from') {
        return hours['from']
      } else {
        return hours['to']
      }
    }
  }

  getFirstTime = id => {
    let getDay = _find(this.state.workingDays, { weekday: id })
    if (getDay) {
      return getDay.from
    }
  }

  getSecondTime = id => {
    let getDay = _find(this.state.workingDays, { weekday: id })
    if (getDay) {
      return getDay.to
    }
  }

  disableTime = givenTime => {
    if (givenTime) {
      let selected = time.indexOf(givenTime) + 1
      let testko = [...time]
      let disabled = testko.splice(0, selected)
      if (!_isEmpty(disabled)) {
        return disabled
      }
    }
  }

  render() {
    let { workStatus, workingDays, loadingWorkStatus } = this.state

    return (
      <div>
        <WorkingStatusSwitch
          status={workStatus}
          toggleFunction={this.handleStatusChange}
          loading={loadingWorkStatus}
        />
        <div className="form__section">
          <div className={classes.scheduler__container}>
            {week.map(w => {
              return (
                <div key={w.id} className={classes.scheduler__day}>
                  <div className={classes.scheduler__row}>
                    <span className={classes.scheduler__dayName}>
                      {w.name}
                    </span>
                    <Switch
                      className={classes.scheduler__switch}
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                      onChange={e => this.handleDay(e, w.id)}
                      checked={
                        workingDays.find(x => x.weekday === w.id)
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className={classes.scheduler__hours}>
                    <Select
                      className={classes.scheduler__select}
                      placeholder="Start"
                      disabled={
                        workingDays.find(x => x.weekday === w.id)
                          ? false
                          : true
                      }
                      onChange={e => this.handleTimeFrom(e, w.id)}
                      value={
                        _find(workingDays, {
                          weekday: w.id,
                        }) && this.findHours(w.id, 'from')
                      }
                    >
                      {time.map((t, i) => {
                        return (
                          <Option
                            key={t}
                            disabled={
                              this.disableTime(
                                this.getSecondTime(w.id)
                              ) &&
                              t !==
                                this.disableTime(
                                  this.getSecondTime(w.id)
                                )[i]
                            }
                          >
                            {t}
                          </Option>
                        )
                      })}
                    </Select>

                    <span> - </span>

                    <Select
                      className={classes.scheduler__select}
                      placeholder="Stop"
                      disabled={
                        workingDays.find(x => x.weekday === w.id)
                          ? false
                          : true
                      }
                      onChange={e => this.handleTimeTo(e, w.id)}
                      value={
                        _find(workingDays, {
                          weekday: w.id,
                        }) && this.findHours(w.id, 'to')
                      }
                    >
                      {time.map((t, i) => {
                        return (
                          <Option
                            key={t}
                            disabled={
                              this.disableTime(
                                this.getFirstTime(w.id)
                              ) &&
                              t ===
                                this.disableTime(
                                  this.getFirstTime(w.id)
                                )[i]
                            }
                          >
                            {t}
                          </Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="btn__container">
          <Button
            type="primary"
            onClick={this.postWorkinghours}
            size="large"
          >
            Snimi
          </Button>
        </div>
      </div>
    )
  }
}

Scheduler.propTypes = {
  workingDays: PropTypes.array,
  editTime: PropTypes.func,
}

export default Scheduler
