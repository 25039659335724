import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
// hoc
import GuestRoute from './utils/GuestRoute'
import UserRoute from './utils/UserRoute'
// import SpecificProtectedRoutes from './utils/SpecificProtectedRoutes'
// Pages
import Dashboard from './pages/Dashboard'
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import OrderService from './pages/OrderService'
import Profile from './pages/Profile'
import VerifyEmail from './pages/auth/VerifyEmail'
import ResetPassword from './pages/auth/ResetPassword'
import ChangePasswordReset from './pages/auth/ChangePasswordReset'
import FacebookLogin from './pages/auth/FacebookAuth'
import GoogleLogin from './pages/auth/GoogleAuth'
import CodeLogin from './pages/auth/CodeLogin'
import Landing from './pages/Landing'
import Payments from './pages/PaymentLanding'
import PageNotFound from './pages/PageNotFound'
import ProfileWithId from './pages/ProfileWithId'
import ContractorPanelWithId from './pages/Dashboard/ContractorPanelWithId'
import Feedback from './pages/Feedback'
import TermsAndConditions from './pages/Terms/TermsAndConditions'
import BoothmanPanel from './pages/Dashboard/BoothmanPanel'
import ContractorReport from './pages/ContractorReport'
import JobApplication from './pages/JobApplication'

const Routing = ({ isAuthenticated, getUser, user, history }) => {
  let currentPath = history.location.pathname
  // unguard routes for some user types
  let unGuardRoutes =
    currentPath !== '/terms-and-conditions' &&
    currentPath !== '/order'

  const userTypes = {
    contractor: user.user_type === 'contractor',
    cashier: user.user_type === 'cashier',
    partner: user.user_type === 'partner',
    boothman: user.user_type === 'boothman',
  }

  return (
    <React.Fragment>
      <Switch>
        {/* if user is not logged in, he can see those routes else redirect */}
        <GuestRoute
          isAuthenticated={isAuthenticated}
          Component={Login}
          path="/user/login"
          wrapper
        />
        <GuestRoute
          isAuthenticated={isAuthenticated}
          Component={Register}
          path="/user/register"
          wrapper
        />
        <GuestRoute
          isAuthenticated={isAuthenticated}
          Component={ResetPassword}
          path="/user/reset"
          wrapper
        />
        <GuestRoute
          isAuthenticated={isAuthenticated}
          Component={VerifyEmail}
          path="/email/verify"
          wrapper
        />
        <GuestRoute
          isAuthenticated={isAuthenticated}
          Component={ChangePasswordReset}
          path="/password/reset"
          wrapper
        />
        <GuestRoute
          isAuthenticated={isAuthenticated}
          Component={GoogleLogin}
          path="/oauth/google"
          getUser={getUser}
          wrapper
        />
        <GuestRoute
          isAuthenticated={isAuthenticated}
          Component={FacebookLogin}
          path="/oauth/facebook"
          getUser={getUser}
          wrapper
        />
        <GuestRoute
          isAuthenticated={isAuthenticated}
          Component={CodeLogin}
          path="/code-login"
          getUser={getUser}
          wrapper
        />
        {/* If user is logged in */}
        <UserRoute
          isAuthenticated={isAuthenticated}
          Component={Profile}
          path="/profile"
          user={user}
          userLoaded={user.user_type}
          user_type={user.user_type}
          roles={[
            'client',
            'contractor',
            'boothman',
            'cashier',
            'partner',
          ]}
          wrapper
        />
        <UserRoute
          isAuthenticated={isAuthenticated}
          Component={ProfileWithId}
          path="/contractor/:id"
          user={user}
          userLoaded={user.user_type}
          user_type={user.user_type}
          roles={['partner']}
          wrapper
        />
        <UserRoute
          isAuthenticated={isAuthenticated}
          Component={ContractorReport}
          path="/order/:id"
          user={user}
          userLoaded={user.user_type}
          user_type={user.user_type}
          roles={['contractor', 'partner']}
          wrapper
        />
        <UserRoute
          isAuthenticated={isAuthenticated}
          Component={BoothmanPanel}
          path="/boothman"
          user={user}
          userLoaded={user.user_type}
          user_type={user.user_type}
          roles={['boothman']}
          wrapper
        />
        <UserRoute
          isAuthenticated={isAuthenticated}
          Component={Dashboard}
          path="/dashboard"
          user={user}
          user_type={user.user_type}
          userLoaded={user.user_type}
          roles={['contractor', 'cashier', 'client', 'partner']}
          wrapper
        />
        <UserRoute
          isAuthenticated={isAuthenticated}
          Component={ContractorPanelWithId}
          path="/contractor-jobs/:id"
          user={user}
          user_type={user.user_type}
          userLoaded={user.user_type}
          roles={['partner']}
          wrapper
        />
        {/* Guard ordering only for offline ordering */}
        {/* <UserRoute
          isAuthenticated={isAuthenticated}
          Component={OrderService}
          path="/order-service"
          user={user}
          user_type={user.user_type}
          userLoaded={user.user_type}
          roles={['boothman', 'client', ]}
          wrapper
        />
        <UserRoute
          isAuthenticated={isAuthenticated}
          Component={OrderService}
          path="/job-overview"
          user={user}
          user_type={user.user_type}
          userLoaded={user.user_type}
          roles={['boothman', 'client']}
          wrapper
        /> */}
        {/* Everyone can see this */}

        {/* This function below is refactored in "SpecificProtectedRoutes"
        only userTypes are moved into that functions  */}

        {userTypes.contractor ||
        userTypes.cashier ||
        userTypes.partner ? (
          unGuardRoutes && <Redirect to="/dashboard" />
        ) : (
          <Route path="/" exact component={Landing} />
        )}

        <Route
          path="/job-application"
          exact
          component={JobApplication}
        />

        {/* {unGuardRoutes && (
          <SpecificProtectedRoutes
            redirectPath="/dashboard"
            roles={['contractor', 'cashier', 'partner']}
            currentUser={user}
          />
        )} */}

        {/* Guard ordering only for offline */}
        <Route path="/job-overview" component={OrderService} />
        <Route path="/order-service" component={OrderService} />

        <Route path="/payments" component={Payments} />
        <Route path="/feedback" component={Feedback} />
        <Route
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        {/* 404 */}
        <Route render={() => <PageNotFound />} />
      </Switch>
    </React.Fragment>
  )
}

Routing.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  getUser: PropTypes.func.isRequired,
  user: PropTypes.object,
}

export default Routing
