import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Button } from 'antd'
import moment from 'moment'
import _isEmpty from 'lodash/isEmpty'

import SectionName from '../OrderSections/SectionName'
import QuantityInput from '../QuantityInput'
import moneyFormat from '../../helpers/moneyFormatter'
import ServiceOrderSummary from '../../pages/OrderService/SelectService/ServiceOrderSummary'

import classes from './TabName.module.scss'

const TabName = ({
  text,
  handleOpenSection,
  checked,
  summary,
  displaySummary,
  options,
  deleteService,
  serviceId,
  availableTime,
  changeQuant,
  calculateAvailableTime,
  serviceQuantity,
  section,
  timeSumm,
  activeTabs,
  selectServiceActive,
  priceSumm,
  services,
}) => {
  let summaryItem = null

  let selectCategorySection = () => {
    summaryItem = services.map(service => {
      let { time, price, quantity, id, name } = service

      let isOrderServiceActive = activeTabs.SelectService
        ? classes['order__block--active']
        : ''

      return (
        <div
          className={[
            classes.order__block,
            isOrderServiceActive,
          ].join(' ')}
          key={id}
        >
          <ul
            className={classes.order__info}
            style={{
              marginBottom: activeTabs.SelectService && 24,
              paddingLeft: 0,
            }}
          >
            <li
              className={classes.order__name}
              style={{ display: 'flex' }}
            >
              <div>
                <p className={classes.order__productName}>
                  {name} ({quantity}kom.)
                </p>
                <div className={classes.order__duration}>
                  {time &&
                    moment
                      .utc()
                      .startOf('day')
                      .add({ minutes: time * quantity })
                      .format('H:mm')}
                  h <br />
                  {price && moneyFormat(price * quantity)}
                </div>
              </div>
            </li>
          </ul>

          {options && displaySummary && selectServiceActive && (
            <div className={classes.selectService__controls}>
              <QuantityInput
                changeQuant={changeQuant}
                calculateAvailableTime={calculateAvailableTime}
                selectedId={id}
                availableTime={availableTime}
                serviceQuantity={quantity}
              />
              <Button
                size="large"
                icon="delete"
                onClick={() => {
                  deleteService(id)
                  calculateAvailableTime(id)
                }}
              />
            </div>
          )}
        </div>
      )
    })
  }

  // let selectProductSection = () => {
  //   summaryItem = services.map(service => {
  //     let time = service.time
  //     let price = service.price
  //     let quantity = service.quantity
  //     let id = service.id
  //     let image = service.image
  //     let name = service.name
  //     let sku = service.sku

  //     let isOrderServiceActive = activeTabs.SelectService
  //       ? classes['order__block--active']
  //       : ''

  //     return (
  //       <div
  //         className={[
  //           classes.order__block,
  //           isOrderServiceActive,
  //         ].join(' ')}
  //         key={id}
  //       >
  //         <ul
  //           className={classes.order__info}
  //           style={{
  //             marginBottom: activeTabs.SelectService && 24,
  //             paddingLeft: 0,
  //           }}
  //         >
  //           <li
  //             className={classes.order__name}
  //             style={{ display: 'flex' }}
  //           >
  //             {/* <ProductImage src={image} border /> */}
  //             <div>
  //               <p className={classes.order__productName}>
  //                 {name} ({quantity}kom.)
  //               </p>
  //               <div className={classes.order__duration}>
  //                 {time &&
  //                   moment
  //                     .utc()
  //                     .startOf('day')
  //                     .add({ minutes: time * quantity })
  //                     .format('H:mm')}
  //                 h <br />
  //                 {price && moneyFormat(price * quantity)}
  //               </div>
  //             </div>
  //           </li>
  //         </ul>

  //         {options && displaySummary && selectServiceActive && (
  //           <div className={classes.selectService__controls}>
  //             <QuantityInput
  //               changeQuant={changeQuant}
  //               calculateAvailableTime={calculateAvailableTime}
  //               selectedId={id}
  //               availableTime={availableTime}
  //               serviceQuantity={quantity}
  //             />
  //             <Button
  //               size="large"
  //               icon="delete"
  //               onClick={() => {
  //                 deleteService(id)
  //                 calculateAvailableTime(id)
  //               }}
  //             />
  //           </div>
  //         )}
  //       </div>
  //     )
  //   })
  // }

  // let selectServiceSection = () => {
  //   summaryItem = summary.map((summ, index) => {
  //     let time
  //     let price
  //     if (Array.isArray(summ)) {
  //       time = summ
  //         .filter(s => s.value)
  //         .map(m => m.value)
  //         .reduce((acc, curr) => acc + curr)
  //       price = summ
  //         .filter(p => p.price)
  //         .map(p => p.price)
  //         .reduce((acc, curr) => acc + curr)
  //     }

  //     let quantity = serviceQuantity[serviceId[index]].quantity
  //     let isOrderServiceActive = activeTabs.SelectService
  //       ? classes['order__block--active']
  //       : ''

  //     return (
  //       <div
  //         className={[
  //           classes.order__block,
  //           isOrderServiceActive,
  //         ].join(' ')}
  //         key={summ + index}
  //       >
  //         <ul
  //           className={classes.order__info}
  //           style={{
  //             marginBottom: activeTabs.SelectService && 24,
  //           }}
  //         >
  //           <li className={classes.order__name}>
  //             {summ.length
  //               ? summ.map(
  //                   (property, index) =>
  //                     property.display_name +
  //                     (index === summ.length - 1 ? ' ' : ', ')
  //                 )
  //               : summ.row_name + ': ' + summ.display_name}
  //             ({quantity}kom.)
  //             <div className={classes.order__duration}>
  //               {time &&
  //                 moment
  //                   .utc()
  //                   .startOf('day')
  //                   .add({ minutes: time * quantity })
  //                   .format('H:mm')}
  //               h <br />
  //               {price && moneyFormat(price * quantity)}
  //             </div>
  //           </li>
  //         </ul>

  //         {options && displaySummary && selectServiceActive && (
  //           <div className={classes.selectService__controls}>
  //             <QuantityInput
  //               changeQuant={changeQuant}
  //               calculateAvailableTime={calculateAvailableTime}
  //               selectedId={serviceId[index]}
  //               availableTime={availableTime}
  //               serviceQuantity={serviceQuantity}
  //             />
  //             <Button
  //               size="large"
  //               icon="delete"
  //               onClick={() => {
  //                 deleteService(serviceId[index])
  //                 calculateAvailableTime(serviceId[index])
  //               }}
  //             />
  //           </div>
  //         )}
  //       </div>
  //     )
  //   })
  // }

  let addressAndContactSection = () => {
    if (Array.isArray(summary)) {
      summaryItem = summary.map((summ, index) => {
        return (
          <div
            key={summ + index}
            className={[
              classes.order__block,
              classes['order__block--noLine'],
            ].join(' ')}
          >
            <div
              className={[
                classes.order__info,
                classes['order__info--noPadding'],
              ].join(' ')}
            >
              <div className={classes.order__name}>
                {summ.length
                  ? summ.map(
                      (property, index) =>
                        property.display_name +
                        (index === summ.length - 1 ? ' ' : ', ')
                    )
                  : summ.row_name + ': ' + summ.display_name}
              </div>
            </div>
          </div>
        )
      })
    }
  }

  let calendarAndTimeSection = () => {
    summaryItem = (
      <div
        className={[
          classes.order__block,
          classes['order__block--noLine'],
        ].join(' ')}
      >
        {!_isEmpty(summary.date) && (
          <div className={classes.order__name}>
            <span>
              {summary.date} u {summary.time}h
            </span>
          </div>
        )}
      </div>
    )
  }

  let selectContractorSection = () => {
    summaryItem = (
      <div
        className={[
          classes.order__block,
          classes['order__block--noLine'],
        ].join(' ')}
      >
        <div className={classes.order__name}>{summary}</div>
      </div>
    )
  }

  if (section === 'select-category') {
    selectCategorySection()
  } else if (section === 'address-and-contact') {
    addressAndContactSection()
  } else if (section === 'calendar-and-time') {
    calendarAndTimeSection()
  } else if (section === 'select-contractor') {
    selectContractorSection()
  }

  let isSectionNameVisible = text.length > 1

  return (
    <div
      className={classes.section__container}
      onClick={handleOpenSection}
    >
      <div className={classes.summary__container}>
        {isSectionNameVisible && <SectionName text={text} />}
        <div className={classes.order__container}>
          {displaySummary && summaryItem}
        </div>
        {/* ONLY IF  SELECT SERVICE SECTION IS ACTIVE */}
        {!selectServiceActive && section === 'select-service' && (
          <ServiceOrderSummary
            time={timeSumm}
            price={priceSumm}
            closed
          />
        )}
      </div>
      {checked && (
        <div className={classes.section__icon}>
          <Icon type="edit" className={classes.order__edit} />
        </div>
      )}
    </div>
  )
}

TabName.propTypes = {
  text: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  handleOpenSection: PropTypes.func.isRequired,
  summary: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  displaySummary: PropTypes.bool.isRequired,
  options: PropTypes.bool,
  checked: PropTypes.bool,
  availableTime: PropTypes.bool,
  deleteService: PropTypes.func,
  changeQuant: PropTypes.func,
  calculateAvailableTime: PropTypes.func,
  serviceQuantity: PropTypes.object,
  timeSumm: PropTypes.number,
  activeTabs: PropTypes.object,
  selectServiceActive: PropTypes.bool,
  priceSumm: PropTypes.number,
  services: PropTypes.array,
  // ServiceId is being used when searchByService is active
  // serviceId: PropTypes.array,
}

export default TabName
