import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'

import classes from './Loader.module.scss'

const Loader = ({ msg }) => {
  return (
    <div className={classes.loader}>
      <Spin />
      <span className={classes.loader__msg}>{msg}</span>
    </div>
  )
}

Loader.propTypes = {
  msg: PropTypes.string,
}

export default Loader
