import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Button, Icon, message, notification } from 'antd'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { withTranslation } from 'react-i18next'

import HorizontalLine from '../HorizontalLine'
import UploadImageMultiple from '../UploadImageMultiple'
import SectionAnimated from '../SectionAnimated'
import getBase64 from '../../helpers/getBase64'
import { apiPost } from '../../api'
import errorHelper from '../../helpers/errorHelper'
// import ProductImage from '../ProductImage'
import Gallery from '../Gallery'

import classes from './ReportSingleItem.module.scss'

const { TextArea } = Input

class ReportSingleItem extends Component {
  state = {
    previewVisible: false,
    previewImage: {},
    fileList: [],
    time: {
      hours: null,
      minutes: null,
    },
    note: '',
    loadingImage: false,
    images: null,
    imageLoadingCompare: {
      in: 0,
      out: 0,
    },
    minimumImageNumber: 3,
  }

  componentDidMount() {
    let { images } = this.props

    let imagesForDisplay = images.map(i => {
      return {
        uid: i.id,
        name: i.url,
        status: 'success',
        url: i.url,
      }
    })

    this.setState({ fileList: imagesForDisplay })
  }

  handlePostItemImage = async image => {
    this.setState({ loadingImage: true })
    var formData = new FormData()
    formData.append('image', image)
    formData.append('order_item', this.props.id)

    try {
      this.setState(state => {
        return {
          imageLoadingCompare: {
            ...state.imageLoadingCompare,
            in: state.imageLoadingCompare.in + 1,
          },
        }
      })
      let res = await apiPost.handleContractorOrderItemUploadImage(
        formData
      )
      let item = _get(res, 'data', null)
      if (!item)
        throw new Error(
          this.props.t(
            'component.ReportSingleItem.failedToUploadImage'
          )
        )

      this.setState(
        state => {
          return {
            fileList: [
              ...state.fileList,
              {
                uid: item.image.id,
                name: item.image.url,
                status: item.status,
                url: item.image.url,
              },
            ],
            imageLoadingCompare: {
              ...state.imageLoadingCompare,
              out: state.imageLoadingCompare.out + 1,
            },
          }
        },
        () =>
          this.setState({
            loadingImage:
              this.state.imageLoadingCompare.in !==
              this.state.imageLoadingCompare.out,
          })
      )
    } catch (error) {
      this.setState({ loadingImage: false })
      errorHelper(
        error,
        this.props.t('component.ReportSingleItem.failedToUploadImage')
      )
    }
  }

  handleImageDelete = async id => {
    try {
      let res = await apiPost.handleContractorOrderItemDeleteImage(id)
      let image = _get(res, 'data', null)
      if (!image)
        throw new Error(
          this.props.t(
            'component.ReportSingleItem.failedToDeleteImage'
          )
        )
      message.success(image.message)
      let removeImage = this.state.fileList.filter(file => {
        return id !== file.uid
      })

      this.setState({ fileList: removeImage, previewVisible: false })
    } catch (error) {
      errorHelper(
        error,
        this.props.t('component.ReportSingleItem.failedToDeleteImage')
      )
    }
  }

  closeModal = () => this.setState({ previewVisible: false })

  handlePreview = async file => {
    this.setState({
      previewImage: { url: file.url, id: file.uid },
      previewVisible: true,
    })
  }

  handleUploadChange = info => {
    let { originFileObj } = info.file

    getBase64(originFileObj, imageUrl =>
      this.handlePostItemImage(originFileObj)
    )
  }

  handleFinishTime = (e, type) => {
    let value = e.target.value

    switch (type) {
      case 'hours':
        if (value.length < 2) {
          this.setState({
            time: { ...this.state.time, [type]: value },
          })
        } else {
          message.error('Vreme nije validno')
          this.setState({
            time: { ...this.state.time, [type]: null },
          })
        }
        break
      case 'minutes':
        if (value <= 59) {
          this.setState({
            time: { ...this.state.time, [type]: value },
          })
        } else {
          message.error('Vreme nije validno')
          this.setState({
            time: { ...this.state.time, [type]: null },
          })
        }
        break
      default:
        return value
    }
  }

  handleNote = e => this.setState({ note: e.target.value })

  handleImageNotification = () => {
    notification.info({
      message: `Minimalan broj slika je ${this.state.minimumImageNumber}`,
      description: `Da bi ste završili sastavljanje, morate imati minimum 
        ${this.state.minimumImageNumber}
         slike`,
    })
  }

  render() {
    let {
      time,
      fileList,
      previewVisible,
      previewImage,
      note,
      loadingImage,
      minimumImageNumber,
    } = this.state
    let {
      quantity,
      services,
      finished,
      id,
      handleSingleItem,
      // productImage,
    } = this.props

    let disabledButton =
      (!!time.minutes || !!time.hours) && !!fileList.length

    return (
      <div className="section">
        <div className={classes.reportItem}>
          <div className={classes.reportItem__header}>
            {/* <ProductImage src={productImage} border /> */}
            <p className={classes.reportItem__name}>
              {services.name} ({quantity}.kom)
            </p>
          </div>

          {!finished && (
            <div className={classes.reportItem__controls}>
              <div className={classes.reportItem__time}>
                <Input
                  type="number"
                  className={classes['reportItem__time--input']}
                  placeholder="HH"
                  onChange={e => this.handleFinishTime(e, 'hours')}
                  value={time.hours}
                  size="large"
                />
                <span>:</span>
                <Input
                  type="number"
                  className={classes['reportItem__time--input']}
                  placeholder="MM"
                  onChange={e => this.handleFinishTime(e, 'minutes')}
                  value={time.minutes}
                  size="large"
                />
              </div>
            </div>
          )}

          {finished && (
            <div className={classes.reportItem__actions}>
              <Icon
                type="check"
                className={classes.reportItem__checkMark}
              />

              {!_isEmpty(fileList) && (
                <Gallery
                  arrayOfImages={fileList}
                  withButton
                  btnText="Pregledaj slike"
                  customBtnStyle={{ position: 'relative' }}
                />
              )}
            </div>
          )}
        </div>

        <SectionAnimated active={!finished}>
          <HorizontalLine margin={16} />
          <UploadImageMultiple
            fileList={fileList}
            handlePreview={this.handlePreview}
            handleChange={this.handleUploadChange}
            closeModal={this.closeModal}
            previewVisible={previewVisible}
            previewImage={previewImage}
            loadingImage={loadingImage}
            deleteImage={this.handleImageDelete}
          />
          <TextArea
            className={classes.reportItem__note}
            placeholder="Dodatne napomene"
            onChange={this.handleNote}
          />
          <div className={classes.reportItem__controls}>
            <Button
              disabled={!disabledButton}
              size="large"
              onClick={() => {
                if (fileList.length >= minimumImageNumber) {
                  handleSingleItem(id, time, note)
                } else {
                  this.handleImageNotification()
                }
              }}
            >
              Završi
            </Button>
          </div>
        </SectionAnimated>
      </div>
    )
  }
}

ReportSingleItem.propTypes = {
  services: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  finished: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  handleSingleItem: PropTypes.func.isRequired,
}

export default withTranslation()(ReportSingleItem)
