import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Section from '../../../components/OrderSections'
import OnlinePayment from '../../../assets/Icons/OnlinePayment'

import classes from '../JobOverview.module.scss'

const PayAgain = ({ handlePayment, orderId }) => {
  const [isFunctionFired, setIsFunctionFired] = useState(false)

  const handlePayAgain = () => {
    if (!isFunctionFired) {
      setIsFunctionFired(true)
      handlePayment(orderId)
    }
  }

  return (
    <Section>
      <div className={classes.payment__container}>
        <div
          className={classes.payment__button}
          onClick={handlePayAgain}
        >
          <OnlinePayment />
          <span className={classes.payment__name}>Plati ponovo</span>
        </div>
        <Link to="/" className={classes.payment__button}>
          <span className={classes.payment__name}>Odustani</span>
        </Link>
      </div>
    </Section>
  )
}

PayAgain.propTypes = {
  handlePayment: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
}

export default PayAgain
