import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { message } from 'antd'
import _get from 'lodash/get'
import { withCookies } from 'react-cookie'
import queryString from 'query-string'

import Loader from '../../../components/Loader'
import { apiPost } from '../../../api'
import { WithUserContext } from '../../../contexts/UserContext'
import setAuthorizationToken from '../../../utils/setAuthorizationToken'
import errorHelper from '../../../helpers/errorHelper'

class CodeLogin extends Component {
  componentDidMount() {
    this.handleCodeLogin(
      queryString.parse(this.props.location.search).code
    )
  }

  handleCodeLogin = async code => {
    let { t } = this.props

    try {
      const res = await apiPost.handleCodeLogin(code)
      let token = _get(res, 'data.token', null)
      let tokenExpires = _get(res, 'data.expires_in', null)
      if (token && tokenExpires) {
        this.props.cookies.set('homea', token, {
          maxAge: tokenExpires,
          path: '/',
        })
        setAuthorizationToken(token)
        this.props.getUser()
        message.success(t('auth.successLogin'))
        this.props.history.push('/dashboard')
      } else {
        message.error(t('auth.authorizationCodeExpired'))
      }
    } catch (error) {
      errorHelper(error, t('auth.failLogin'))
    }
  }
  render() {
    return <Loader />
  }
}

export default withCookies(
  WithUserContext(withTranslation()(CodeLogin))
)
