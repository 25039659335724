import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import Lightbox from 'react-image-lightbox'
import { Button } from 'antd'

import 'react-image-lightbox/style.css'
import classes from './Gallery.module.scss'

let Gallery = ({
  arrayOfImages,
  withButton,
  btnText,
  customBtnStyle,
}) => {
  let [photoIndex, setPhotoIndex] = useState(0)
  let [isLightboxVisible, setLightboxVisibility] = useState(false)
  let [images, setImages] = useState([])

  let handleGalleryVisibility = visible => {
    if (visible) {
      let onlyUrls = arrayOfImages.map(i => i.url)
      setImages(onlyUrls)
      setLightboxVisibility(true)
    } else {
      setLightboxVisibility(false)
      setImages([])
      setPhotoIndex(0)
    }
  }

  let imagesLength = images.length

  return (
    <Fragment>
      {withButton && (
        <Button
          icon={btnText ? null : 'picture'}
          className={customBtnStyle ? '' : classes.gallery__btn}
          onClick={() => handleGalleryVisibility(true)}
          style={customBtnStyle}
        >
          {btnText}
        </Button>
      )}
      {isLightboxVisible && (
        <Lightbox
          imageTitle={
            <span>
              {photoIndex + 1} / {imagesLength}
            </span>
          }
          mainSrc={images[photoIndex]}
          nextSrc={
            imagesLength > 1
              ? images[(photoIndex + 1) % imagesLength]
              : ''
          }
          prevSrc={
            imagesLength > 1
              ? images[(photoIndex + imagesLength - 1) % imagesLength]
              : ''
          }
          onCloseRequest={() => handleGalleryVisibility(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imagesLength - 1) % imagesLength
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imagesLength)
          }
        />
      )}
    </Fragment>
  )
}

Gallery.defaultProps = {
  withButton: false,
}

Gallery.propTypes = {
  arrayOfImages: PropTypes.array.isRequired,
  withButton: PropTypes.bool,
  btnText: PropTypes.string,
  customBtnStyle: PropTypes.object,
}

export default Gallery
