import React, { createContext, Component } from 'react'
import moment from 'moment'

const OrderContext = createContext()

class OrderStore extends Component {
  state = {
    front: {
      userInfo: {},
      serviceInfo: [],
    },
    back: {
      items: [],
      scheduled_at: '',
      duration: '',
      contractor: null,
      first_name: '',
      last_name: '',
      email: '',
      street: '',
      street_number: '',
      appartment: '',
      city: '',
      municipality: '',
      has_parking: '',
      phone: '',
      phone_additional: '',
    },
  }

  setOrder = (
    first_name,
    last_name,
    address,
    municipality,
    municipalityId,
    orderDate,
    time,
    service,
    items,
    dateTimeStamp,
    duration,
    contractor,
    email,
    street_num,
    floor,
    appartment,
    city,
    parking,
    phone,
    phone_additional,
    note
  ) => {
    let formatDate = moment(orderDate, 'DD.MM.YYYY').format(
      'YYYY-MM-DD'
    )
    let formatHours = moment(time, 'H:mm').format('H:mm:ss')

    this.setState({
      front: {
        userInfo: {
          first_name,
          last_name,
          municipality,
          municipalityId,
          address,
          street_num,
          appartment,
          floor,
          orderDate,
          time,
        },
        serviceInfo: service,
      },
      back: {
        items: items,
        scheduled_at: `${formatDate} ${formatHours}`,
        duration: duration,
        contractor: contractor,
        first_name: first_name,
        last_name: last_name,
        email: email,
        street: address,
        street_number: street_num,
        floor: floor,
        appartment: appartment,
        city: city,
        municipality: municipalityId,
        has_parking: parking,
        phone: phone,
        phone_additional: phone_additional,
        note: note,
      },
    })
  }

  cleanOrder = () => {
    this.setState({ userInfo: {}, serviceInfo: [] })
  }

  render() {
    return (
      <OrderContext.Provider
        value={{
          order: this.state,
          onSetOrder: this.setOrder,
          onCleanOrder: this.cleanOrder,
        }}
      >
        {this.props.children}
      </OrderContext.Provider>
    )
  }
}

const WithOrderContext = Component => {
  return props => (
    <OrderContext.Consumer>
      {order => <Component {...props} order={order} />}
    </OrderContext.Consumer>
  )
}

export { OrderContext, OrderStore, WithOrderContext }
