import React, { Component, Fragment } from 'react'
import queryString from 'query-string'
import _get from 'lodash/get'

import { apiPost } from '../../../api'
import Loader from '../../../components/Loader'
import errorHelper from '../../../helpers/errorHelper'
import { withTranslation } from 'react-i18next'

class VerifyEmail extends Component {
  state = {
    message: '',
    status: '',
    loader: false,
  }

  componentDidMount() {
    this.verifyEmail(queryString.parse(this.props.location.search))
  }

  verifyEmail = async data => {
    this.setState({ loader: true })
    try {
      const res = await apiPost.verifyRegistration(data)
      let verificationMsg = _get(res, 'data.message', null)
      let verificationStatus = _get(res, 'data.status', null)

      if (verificationMsg && verificationStatus) {
        this.setState({
          loader: false,
          message: verificationMsg,
          status: verificationStatus,
        })
        if (verificationStatus === 'success') {
          setTimeout(() => {
            this.props.history.push('/user/login')
          }, 4000)
        }
      }
    } catch (error) {
      this.setState({ loader: false })
      errorHelper(error, this.props.t('auth.emailVerificationFailed'))
    }
  }

  render() {
    return (
      <Fragment>
        <h1 style={{ textAlign: 'center' }}>
          {this.state.message && this.state.message}
        </h1>
        {this.state.loader && <Loader />}
      </Fragment>
    )
  }
}

export default withTranslation()(VerifyEmail)
