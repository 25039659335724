import React, { Component, Fragment } from 'react'
import { Rate, Input, Form, Button, message } from 'antd'
import _get from 'lodash/get'
import queryString from 'query-string'
import { withTranslation } from 'react-i18next'

import setDocumentTitle from '../../helpers/setDocumentTitle'
import { apiGet, apiPost } from '../../api'
import errorHelper from '../../helpers/errorHelper'
import Loader from '../../components/Loader'

class Feedback extends Component {
  state = {
    validLink: null,
    code: null,
    loaded: false,
  }

  componentDidMount() {
    setDocumentTitle(this.props.t('page.title.feedback'))

    let code = queryString.parse(this.props.location.search).code

    if (code) {
      this.validateUrl(
        queryString.parse(this.props.location.search).code
      )
    } else {
      this.props.history.push('/')
    }
  }

  validateUrl = async code => {
    try {
      const res = await apiGet.validateFeedback(code)
      let feedback = _get(res, 'data', null)
      if (!feedback)
        throw new Error(this.props.t('feedback.failedToValidateLink'))
      this.setState({ validLink: true, code, loaded: true })
    } catch (error) {
      this.setState({ validLink: false, loaded: true })
      errorHelper(
        error,
        this.props.t('feedback.failedToValidateLink')
      )
    }
  }

  sendFeedback = async info => {
    try {
      const res = await apiPost.sendFeedback(info)
      let feedback = _get(res, 'data', null)
      if (!feedback)
        throw new Error(this.props.t('feedback.failedToSendFeedback'))
      message.success(feedback.message)
      // this.props.history.push('/')
    } catch (error) {
      errorHelper(
        error,
        this.props.t('feedback.failedToSendFeedback')
      )
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let object = {
          ...values,
          code: this.state.code,
        }
        this.sendFeedback(object)
      }
    })
  }

  render() {
    let { validLink, loaded } = this.state
    const { getFieldDecorator } = this.props.form

    return (
      <Fragment>
        {loaded ? (
          validLink ? (
            <Fragment>
              <h3 style={{ marginBottom: 12 }}>
                Podeli utisak o izvršenoj usluzi
              </h3>
              <Form onSubmit={this.handleSubmit}>
                <div className="section">
                  <Form.Item label="Ocenite uslugu od 1 - 5">
                    {getFieldDecorator('rating', {
                      rules: [
                        {
                          required: true,
                          message: 'Molimo ocenite uslugu',
                        },
                      ],
                    })(<Rate style={{ fontSize: 25 }} />)}
                  </Form.Item>
                  <Form.Item label="Komentar na uslugu">
                    {getFieldDecorator('message', {
                      rules: [
                        {
                          required: false,
                          message: 'Molimo dajte komentar na uslugu',
                        },
                      ],
                    })(<Input.TextArea />)}
                  </Form.Item>
                </div>
                <div className="btn__container">
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    Pošaljite utisak
                  </Button>
                </div>
              </Form>
            </Fragment>
          ) : (
            <div>Utisak nije validan</div>
          )
        ) : (
          <Loader />
        )}
      </Fragment>
    )
  }
}

const WrappedFeedback = Form.create({ name: 'feedback' })(Feedback)

export default withTranslation()(WrappedFeedback)
