import React, { Component } from 'react'
import _get from 'lodash/get'
import { Form, Input, Button, message, Checkbox } from 'antd'
import { withTranslation } from 'react-i18next'

import { apiPost, apiGet } from '../../../api'
import Loader from '../../../components/Loader'
import errorHelper from '../../../helpers/errorHelper'
import setDocumentTitle from '../../../helpers/setDocumentTitle'
import HorizontalLine from '../../../components/HorizontalLine'

import classes from '../Auth.module.scss'

class UserRegistrationForm extends Component {
  state = {
    confirmDirty: false,
    loader: false,
    submited: false,
    email: '',
    isTermsAccepted: false,
  }

  componentDidMount() {
    setDocumentTitle(this.props.t('page.title.registration'))
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let { email, password, first_name, last_name } = values
        this.handleRegistration(
          email,
          password,
          first_name,
          last_name
        )
      }
    })
  }

  handleRegistration = async (
    email,
    password,
    first_name,
    last_name
  ) => {
    let { t } = this.props

    this.setState({ loader: true })
    try {
      const res = await apiPost.registration(
        email,
        password,
        first_name,
        last_name
      )
      let registrationInfo = _get(res, 'data.message', null)
      if (!registrationInfo)
        throw new Error(t('auth.failRegistration'))
      this.setState({ loader: false, submited: true })
      message.success(registrationInfo)
      this.props.history.push('/')
    } catch (err) {
      this.setState({ loader: false })
      const res = err.response.data.errors
      Object.keys(res).map(key => message.error(res[key]))
    }
  }

  handleResendEmail = async () => {
    let { t } = this.props

    try {
      const res = await apiPost.resendEmail(
        this.props.form.getFieldValue('email')
      )
      let resend = _get(res, 'data.message', null)
      if (!resend) throw new Error(t('auth.failResendMail'))
      message.success(resend)
    } catch (err) {
      let status = err.response.status
      if (status === 429) {
        message.error(err.response.data.message)
      }
      if (status === 422) {
        const res = err.response.data.errors
        Object.keys(res).map((key, index) => {
          return message.error(res[key])
        })
      }
    }
  }

  handleConfirmBlur = e => {
    const value = e.target.value
    this.setState({
      confirmDirty: this.state.confirmDirty || !!value,
    })
  }

  compareToFirstPassword = (rule, value, callback) => {
    let { t } = this.props

    const form = this.props.form
    if (value && value !== form.getFieldValue('password')) {
      callback(t('auth.comparePasswordFail'))
    } else {
      callback()
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  googleAuth = async () => {
    let { t } = this.props

    try {
      const res = await apiGet.getGoogle()
      let googleUrl = _get(res, 'data.url', null)
      if (!googleUrl)
        throw new Error(t('auth.socialNewtorkRegistrationFailed'))
      window.location = googleUrl
    } catch (error) {
      errorHelper(error, t('auth.socialNewtorkRegistrationFailed'))
    }
  }

  facebookAuth = async () => {
    let { t } = this.props

    try {
      const res = await apiGet.getFacebook()
      let facebookUrl = _get(res, 'data.url', null)
      if (!facebookUrl)
        throw new Error(t('auth.socialNewtorkRegistrationFailed'))
      window.location = facebookUrl
    } catch (error) {
      errorHelper(error, t('auth.socialNewtorkRegistrationFailed'))
    }
  }

  handleTerms = e =>
    this.setState({ isTermsAccepted: e.target.checked })

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <div className="form__wrapper">
        <h2 className={classes.auth__heading}>Registracija</h2>
        <p className={classes.auth__desc}>
          Registrujte se pomoću emaila ili preko društvenih mreža.
        </p>
        <div className={classes.auth__socials}>
          <Button
            size="large"
            icon="google"
            type="primary"
            onClick={this.googleAuth}
            className={[
              classes.auth__socialBtn,
              classes.auth__google,
            ].join(' ')}
          >
            Google
          </Button>
          <Button
            size="large"
            icon="facebook"
            type="primary"
            onClick={this.facebookAuth}
            className={[
              classes.auth__socialBtn,
              classes.auth__fb,
            ].join(' ')}
          >
            Facebook
          </Button>
        </div>
        <HorizontalLine margin={24} text="ili" />
        <Form onSubmit={this.handleSubmit}>
          <div className="form__section">
            <Form.Item label="Ime">
              {getFieldDecorator('first_name', {
                rules: [
                  {
                    type: 'string',
                    message: 'Unešeno ime nije validno!',
                  },
                  {
                    required: true,
                    message: 'Molimo unesite svoje ime!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Prezime">
              {getFieldDecorator('last_name', {
                rules: [
                  {
                    type: 'string',
                    message: 'Unešeno prezime nije validno!',
                  },
                  {
                    required: true,
                    message: 'Molimo unesite svoje prezime!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'Unešen email nije validan!',
                  },
                  {
                    required: true,
                    message: 'Molimo unesite svoj email!',
                  },
                ],
              })(<Input type="email" />)}
            </Form.Item>
            <Form.Item label="Lozinka" hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Molimo unesite lozinku!',
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input.Password autoComplete="off" />)}
            </Form.Item>
            <Form.Item label="Potvrdi lozinku" hasFeedback>
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: 'Molimo potvrdite unešenu lozinku!',
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(
                <Input.Password
                  onBlur={this.handleConfirmBlur}
                  autoComplete="off"
                />
              )}
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Uslovi korišćenja">
              {getFieldDecorator('terms', {
                rules: [
                  {
                    transform: value => value || undefined,
                    type: 'boolean',
                    required: true,
                    message:
                      'Prihvatanje uslova je obavezno pri registraciji na Homea platformu!',
                  },
                ],
              })(
                <Checkbox
                  onChange={this.handleTerms}
                  checked={this.state.isTermsAccepted}
                >
                  <p className={classes.auth__terms}>
                    Slažem se sa
                    <a href="/terms-and-conditions">
                      {' '}
                      uslovima korišćenja
                    </a>
                  </p>
                </Checkbox>
              )}
            </Form.Item>
          </div>

          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className={[
              classes.auth__button,
              classes.auth__registerBtn,
            ].join(' ')}
          >
            Registruj se
          </Button>
          {this.state.submited && (
            <Button
              onClick={this.handleResendEmail}
              style={{ margin: '8px auto' }}
              className={classes.auth__button}
            >
              Ponovo pošalji link
            </Button>
          )}
        </Form>

        {this.state.loader && <Loader />}
      </div>
    )
  }
}

const Register = Form.create({ name: 'register' })(
  UserRegistrationForm
)

export default withTranslation()(Register)
