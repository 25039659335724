import React from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'

import classes from './CalendarAndTime.module.scss'

const { Option } = Select

const SelectTime = ({ availableTime, handleTime, selectedTime }) => {
  let renderTimes = availableTime.map(time => (
    <Option
      value={time.from}
      key={time.from}
      disabled={time.available === false}
    >
      {time.from}h
    </Option>
  ))

  return (
    <Select
      disabled={!availableTime.length}
      onChange={handleTime}
      placeholder="Izaberi vreme"
      className={classes.time}
      value={selectedTime ? selectedTime : undefined}
    >
      {renderTimes}
    </Select>
  )
}

SelectTime.propTypes = {
  availableTime: PropTypes.array.isRequired,
  handleTime: PropTypes.func.isRequired,
  selectedTime: PropTypes.string,
}

export default SelectTime
