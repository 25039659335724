import React, { Component } from 'react'
import { List, Button, Skeleton, Rate } from 'antd'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

import { apiGet } from '../../../../../../api'
import Pagination from '../../../../../../components/Pagination'
import errorHelper from '../../../../../../helpers/errorHelper'
import FeedbackListWithModal from '../../../../../../components/FeedbackListWithModal'
import EmptyListImage from '../../../../../../components/EmptyListImage'
import ProfileAvatar from '../../../../../../components/ProfileAvatar'

import classes from '../../../../Dashboard.module.scss'

class ContractorActions extends Component {
  _isMounted = false
  state = {
    contractorList: [],
    loading: false,
    pagination: {
      meta: {},
      links: {},
    },
    feedbackModalVisible: false,
    feedback: null,
    activeContractorInModal: null,
  }

  componentDidMount() {
    this._isMounted = true
    this.getContractorList()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active && this.props.active) {
      this.getContractorList()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getContractorList = async page => {
    this.setState({ loading: true })
    try {
      let res
      if (page) {
        res = await apiGet.getContractorList(page)
      } else {
        res = await apiGet.getContractorList()
      }

      let contractorList = _get(res, 'data', null)
      if (!contractorList)
        throw new Error(this.props.t('dashboard.orderFetchFailed'))
      if (this._isMounted) {
        this.setState({
          contractorList: contractorList.data,
          pagination: {
            meta: contractorList.meta,
            links: contractorList.links,
          },
          loading: false,
        })
      }
    } catch (error) {
      this.setState({ loading: false })
      errorHelper(error, this.props.t('dashboard.orderFetchFailed'))
    }
  }

  getContractorFeedback = async (id, page) => {
    try {
      let res
      if (page) {
        res = await apiGet.getContractorFeedback(id, page)
      } else {
        res = await apiGet.getContractorFeedback(id)
      }
      let feedback = _get(res, 'data', null)
      if (!feedback)
        throw new Error(this.props.t('dashboard.feedbackFetchFailed'))
      this.setState({ feedback, activeContractorInModal: id })
    } catch (error) {
      errorHelper(
        error,
        this.props.t('dashboard.feedbackFetchFailed')
      )
    }
  }

  viewContractorProfile = id => {
    this.props.history.push(`/contractor/${id}`)
  }

  viewContractorJobs = id => {
    this.props.history.push(`/contractor-jobs/${id}`)
  }

  handleContractorPageChange = page => {
    this.getContractorList(page)
  }

  hideFeedbackModal = () => {
    this.setState({ feedbackModalVisible: false, feedback: null })
  }

  showFeedbackModal = () => {
    this.setState({ feedbackModalVisible: true })
  }

  handleFeedbackPageChange = page => {
    this.getContractorFeedback(
      this.state.activeContractorInModal,
      page
    )
  }

  render() {
    return (
      <div>
        <List
          locale={EmptyListImage}
          loading={this.state.loading}
          itemLayout="horizontal"
          dataSource={this.state.contractorList}
          renderItem={contractor => (
            <List.Item className={classes.dashboard__dashboardList}>
              <Skeleton
                avatar
                title={false}
                loading={this.state.loading}
                active
              >
                <div
                  className={[
                    classes.dashboard__contractorInfo,
                    classes['dashboard__contractorInfo--partner'],
                  ].join(' ')}
                >
                  <div className={classes.dashboard__avatar}>
                    <ProfileAvatar src={contractor.image} />
                  </div>
                  <div>
                    <p
                      className={[
                        classes.dashboard__contractorBasics,
                      ].join(' ')}
                      style={{
                        marginBottom: 0,
                        fontWeight: 500,
                        color: 'black',
                      }}
                    >
                      {contractor.first_name} {contractor.last_name}
                    </p>
                    <Rate
                      value={parseInt(contractor.rating)}
                      allowHalf
                      disabled
                      className={classes.dashboard__contractorBasics}
                      styles={{ lineHeight: 1 }}
                    />
                  </div>
                </div>

                <div
                  className={[
                    classes.dashboard__dashboardListActions,
                    classes[
                      'dashboard__dashboardListActions--partner'
                    ],
                  ].join(' ')}
                >
                  <Button
                    size="large"
                    type="primary"
                    onClick={() =>
                      this.viewContractorJobs(contractor.id)
                    }
                  >
                    Poslovi
                  </Button>
                  <Button
                    icon="user"
                    size="large"
                    type="primary"
                    onClick={() =>
                      this.viewContractorProfile(contractor.id)
                    }
                  />
                  <Button
                    disabled={!contractor.rating}
                    icon="message"
                    size="large"
                    onClick={() => {
                      this.showFeedbackModal()
                      this.getContractorFeedback(contractor.id)
                    }}
                  />
                </div>
              </Skeleton>
            </List.Item>
          )}
        />

        {!!this.state.contractorList.length && (
          <Pagination
            ordersLength={this.state.contractorList.length}
            metaTotal={this.state.pagination.meta.total}
            metaPerPage={this.state.pagination.meta.per_page}
            handlePageChange={this.handleContractorPageChange}
          />
        )}

        {this.state.feedback && (
          <FeedbackListWithModal
            feedbackModalVisible={this.state.feedbackModalVisible}
            feedbackData={this.state.feedback}
            onCancel={this.hideFeedbackModal}
            customTitle="Utisci"
            handlePagination={this.handleFeedbackPageChange}
          />
        )}
      </div>
    )
  }
}

ContractorActions.propTypes = {
  active: PropTypes.bool,
}

export default withRouter(withTranslation()(ContractorActions))
