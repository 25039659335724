import React from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

const SectionAnimated = ({ active, children, duration }) => {
  return (
    <motion.div
      initial={false}
      animate={{
        height: active ? 'auto' : 0,
        overflow: 'hidden',
      }}
      transition={{ duration: duration }}
    >
      {children}
    </motion.div>
  )
}

SectionAnimated.defaultProps = {
  active: 0,
  duration: 0.3,
}

SectionAnimated.propTypes = {
  active: PropTypes.bool.isRequired,
  duration: PropTypes.number,
}

export default SectionAnimated
