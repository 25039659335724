import React, { Component } from 'react'
import { Tabs } from 'antd'
import ContractorActions from './TabItems/ContractorActions'
import AllJobs from './TabItems/AllJobs'

const { TabPane } = Tabs

class OrderTabs extends Component {
  _isMounted = false
  state = {
    activeTab: '1',
  }

  componentDidMount() {
    this._isMounted = true
  }

  handleTabChange = key => {
    this.setState({ activeTab: key })
  }

  render() {
    let { activeTab } = this.state

    return (
      <Tabs onChange={this.handleTabChange}>
        <TabPane tab="Lista sastavljača" key="1">
          <ContractorActions active={activeTab === '1'} />
        </TabPane>
        <TabPane tab="Lista poslova" key="2">
          <AllJobs active={activeTab === '1'} />
        </TabPane>
      </Tabs>
    )
  }
}

export default OrderTabs
