import React from 'react'
import PropTypes from 'prop-types'

import timeFormat from '../../../helpers/timeFormat'
import moneyFormat from '../../../helpers/moneyFormatter'

import classes from './SelectService.module.scss'

const ServiceOrderSummary = ({ time, price, closed }) => {
  let summaryClass = closed
    ? [
        classes.service__summary,
        classes['service__summary--summaryClosed'],
      ].join(' ')
    : classes.service__summary

  return (
    <div className={summaryClass}>
      {time > 0 && price > 0 && (
        <p className={classes.service__summaryItem}>
          Potrebno vreme: {timeFormat(time)}
          <br />
          Ukupna cena: {moneyFormat(price)}
        </p>
      )}
    </div>
  )
}

ServiceOrderSummary.propTypes = {
  time: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  closed: PropTypes.bool,
}

export default ServiceOrderSummary
