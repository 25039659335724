import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { WithUserContext } from '../../contexts/UserContext'
import setDocumentTitle from '../../helpers/setDocumentTitle'
import Hero from './Hero.js'
import HowItWorks from './HowItWorks.js'

const Landing = ({ user, t }) => {
  setDocumentTitle(t('page.title.landing'))

  let userType = user.user.user_type || null

  return (
    <div className="content content--landing">
      <Hero userType={userType} />
      <HowItWorks />
    </div>
  )
}

Landing.propTypes = {
  user: PropTypes.object,
}

export default WithUserContext(withTranslation()(Landing))
