import React, { Fragment } from 'react'
import { AutoComplete } from 'antd'
import PropTypes from 'prop-types'

import HorizontalLine from '../HorizontalLine'

import classes from './ClientSearch.module.scss'

const { Option } = AutoComplete

let ClientSearch = ({
  handleSearch,
  clearFields,
  data,
  onSelect,
}) => {
  let children = data.map(d => (
    <Option key={d.id} text={d}>
      {`${d.first_name} ${d.last_name} - ${d.email}`}
    </Option>
  ))

  return (
    <Fragment>
      <AutoComplete
        onChange={e => {
          handleSearch(e)
          clearFields(e)
        }}
        dataSource={data}
        onSelect={onSelect}
        // onSearch={onSearch}
        // placeholder={placeholder}
        className={classes.clientSearch}
        allowClear
        notFoundContent="Ništa nije pronađeno"
        size="large"
      >
        {children}
      </AutoComplete>
      <HorizontalLine margin={24} />
    </Fragment>
  )
}

ClientSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default ClientSearch
