import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'

import OnlinePaymentIcon from '../../../assets/Icons/OnlinePayment'
import { Modal } from 'antd'

import classes from '../JobOverview.module.scss'

const OnlinePayment = ({
  isTermsAccepted,
  handlePayment,
  dataToSend,
  isPaymentLoading,
  isRecaptchaPassed,
  isUserGuest,
}) => {
  const [isFunctionFired, setIsFunctionFired] = useState(false)

  let isRecaptchaEnabled = isUserGuest && !isRecaptchaPassed

  let addDisabledClass =
    !isTermsAccepted ||
    isPaymentLoading ||
    isFunctionFired ||
    isRecaptchaEnabled
      ? classes['payment__button--disabled']
      : null

  const handlePaymentButton = () => {
    if (!isTermsAccepted) {
      Modal.info({
        title: 'Morate da prihvatite uslove korišćenja!',
        maskClosable: true,
      })
    } else if (!isFunctionFired && !isRecaptchaEnabled) {
      setIsFunctionFired(true)
      handlePayment(1, dataToSend)
    }
  }

  return (
    <Fragment>
      <div
        className={[classes.payment__button, addDisabledClass].join(
          ' '
        )}
        onClick={handlePaymentButton}
      >
        <OnlinePaymentIcon />
        <div className={classes.payment__name}>Online plaćanje</div>
      </div>
    </Fragment>
  )
}

OnlinePayment.propTypes = {
  isTermsAccepted: PropTypes.bool.isRequired,
  handlePayment: PropTypes.func.isRequired,
  dataToSend: PropTypes.object.isRequired,
  isPaymentLoading: PropTypes.bool,
  isRecaptchaPassed: PropTypes.string,
  isUserGuest: PropTypes.bool,
}

export default OnlinePayment
