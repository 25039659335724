import React, { Component, Fragment } from 'react'
import _get from 'lodash/get'
import { Redirect, withRouter } from 'react-router-dom'
import { message } from 'antd'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import OrderOverview from '../../components/OrderOverview'
import PaymentAndSaveOrder from './PaymentAndSaveOrder'
import { OrderContext } from '../../contexts/OrderContext'
import { apiPost } from '../../api'
import Loader from '../../components/Loader'
import PayAgain from './PayAgain'
import errorHelper from '../../helpers/errorHelper'
import PlatformInformation from '../../components/PlatformInformation'

class JobOverview extends Component {
  state = {
    isPaymentLoading: false,
    acceptedTerms: false,
    recaptchaResponse: null,
  }

  handlePayAndSaveOrder = async (paymentTypeId, back) => {
    const ONLINE_ID = 1
    const OFFLINE_ID = 2

    let order = {
      payment_type: paymentTypeId,
      ...back,
    }

    this.setState({ isPaymentLoading: true })

    try {
      let res = await apiPost.createOrder(order)
      let orderId = _get(res, 'data.order.id', null)
      if (!orderId)
        throw new Error(this.props.t('jobOverview.failedToSendOrder'))

      if (paymentTypeId === ONLINE_ID) {
        this.handleOnlinePayment(orderId)
      } else if (paymentTypeId === OFFLINE_ID) {
        message.success(this.props.t('jobOverview.orderSendSuccess'))
        this.props.history.push('/')
      }
    } catch (error) {
      this.setState({ isPaymentLoading: false })
      this.props.redirectIfSelectedContractorChangeStatus(error)
      errorHelper(
        error,
        this.props.t('jobOverview.failedToSendOrder')
      )
    }
  }

  handleOnlinePayment = async orderId => {
    this.setState({ isPaymentLoading: true })

    try {
      const res = await apiPost.payment(orderId)
      let redirectUrl = _get(res, 'data.redirect_url', null)

      if (!redirectUrl)
        throw new Error(this.props.t('jobOverview.paymentFailed'))

      // REKO DARKO DA NE UGASIM LOADER NA 200

      // this.setState({
      //   isPaymentLoading: false,
      // })

      window.location.href = redirectUrl
    } catch (error) {
      this.setState({ isPaymentLoading: false })
      this.props.redirectIfSelectedContractorChangeStatus(error)

      // errorHelper(error, this.props.t('jobOverview.paymentFailed'))
      message.error(this.props.t('jobOverview.paymentFailed'))
    }
  }

  handleTerms = () =>
    this.setState({ acceptedTerms: !this.state.acceptedTerms })

  handleRecaptcha = hash => {
    if (hash) {
      this.setState({ recaptchaResponse: hash })
    } else {
      this.setState({ recaptchaResponse: null })
    }
  }

  render() {
    let {
      isPaymentLoading,
      acceptedTerms,
      recaptchaResponse,
    } = this.state
    let {
      order,
      editOrder,
      allowJobOverview,
      transactionInfo,
    } = this.props

    return (
      <Fragment>
        <PlatformInformation
          orderNumber={order && order.details.orderNumber}
        />

        {/* Edit order is for when online payment fail */}
        {editOrder ? (
          <div>
            <OrderOverview
              user={order.user}
              service={order.service}
              alertType="error"
              transactionInfo={transactionInfo}
              edit
              isAlertVisible
              orderNumber={order.details.orderNumber}
            />
            <PayAgain
              handlePayment={this.handleOnlinePayment}
              orderId={order.details.id}
            />
          </div>
        ) : (
          <OrderContext.Consumer>
            {({ order }) => {
              let frontData = order.front
              let backData = order.back

              if (allowJobOverview) {
                return (
                  <div>
                    <OrderOverview
                      user={frontData.userInfo}
                      service={frontData.serviceInfo}
                      edit={false}
                      isAlertVisible={false}
                    />
                    <PaymentAndSaveOrder
                      handlePayment={this.handlePayAndSaveOrder}
                      backendData={backData}
                      acceptedTerms={acceptedTerms}
                      handleTerms={this.handleTerms}
                      isPaymentLoading={isPaymentLoading}
                      handleRecaptcha={this.handleRecaptcha}
                      isRecaptchaPassed={recaptchaResponse}
                    />
                  </div>
                )
              } else {
                return <Redirect to="/order-service" />
              }
            }}
          </OrderContext.Consumer>
        )}

        {isPaymentLoading ? <Loader msg="Obrada u toku..." /> : null}
      </Fragment>
    )
  }
}

JobOverview.propTypes = {
  allowJobOverview: PropTypes.bool.isRequired,
  editOrder: PropTypes.bool,
  order: PropTypes.object,
  errorMessage: PropTypes.object,
}

export default withRouter(withTranslation()(JobOverview))
