let screenOrientation = () => {
  let width = window.innerWidth
  let height = window.innerHeight

  if (width < 991) {
    return height > width ? 'portrait' : 'landscape'
  } else {
    return 'portrait'
  }
}

export default screenOrientation
