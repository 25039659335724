import React from 'react'
import PropTypes from 'prop-types'

import classes from './HorizontalLine.module.scss'

const HorizontalLine = ({ margin, color, text }) => (
  <div
    className={classes.HorizontalLine__container}
    style={{
      marginTop: margin,
      marginBottom: margin,
      color,
    }}
  >
    {text && (
      <span className={classes.HorizontalLine__text}>{text}</span>
    )}
  </div>
)

HorizontalLine.propTypes = {
  margin: PropTypes.number,
  color: PropTypes.string,
  text: PropTypes.string,
}

export default HorizontalLine
