import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withCookies } from 'react-cookie'
import { Menu, Dropdown } from 'antd'
import { withTranslation } from 'react-i18next'

import User from '../../../assets/Icons/User'
import { WithUserContext } from '../../../contexts/UserContext'
import logOutGlobally from '../../../utils/logOut'

import classes from '../Navigation.module.scss'

const Profile = ({
  loggedIn,
  userType,
  user,
  cookies,
  history,
  t,
}) => {
  const logOut = () => {
    logOutGlobally(cookies, user, t)
    history.push('/')
  }

  // contents by roles
  const userMenu = (
    <Menu>
      <Menu.Item>
        <Link to="/user/login" key="0">
          {t('component.Navigation.login')}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/user/register" key="1">
          {t('component.Navigation.register')}
        </Link>
      </Menu.Item>
    </Menu>
  )

  const partnerMenu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile" key="0">
          {t('component.Navigation.profile')}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/dashboard" key="1">
          {t('component.Navigation.dashboard')}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={logOut}>
        {t('component.Navigation.logOut')}
      </Menu.Item>
    </Menu>
  )

  const contractorMenu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile" key="0">
          {t('component.Navigation.profile')}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/dashboard" key="1">
          {t('component.Navigation.dashboard')}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={logOut}>
        {t('component.Navigation.logOut')}
      </Menu.Item>
    </Menu>
  )

  const cashierMenu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile" key="0">
          {t('component.Navigation.profile')}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/dashboard" key="1">
          {t('component.Navigation.dashboard')}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={logOut}>
        {t('component.Navigation.logOut')}
      </Menu.Item>
    </Menu>
  )

  const clientMenu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile" key="0">
          {t('component.Navigation.profile')}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/dashboard" key="1">
          {t('component.Navigation.dashboard')}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={logOut}>
        {t('component.Navigation.logOut')}
      </Menu.Item>
    </Menu>
  )

  const boothmanMenu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile" key="0">
          {t('component.Navigation.profile')}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={logOut}>
        {t('component.Navigation.logOut')}
      </Menu.Item>
    </Menu>
  )

  let renderUser = () => {
    switch (userType) {
      case 'contractor':
        return contractorMenu
      case 'cashier':
        return cashierMenu
      case 'client':
        return clientMenu
      case 'partner':
        return partnerMenu
      case 'boothman':
        return boothmanMenu
      default:
        return userMenu
    }
  }

  return (
    <Dropdown
      overlay={renderUser()}
      trigger={['click']}
      placement="bottomRight"
    >
      <span className={classes.navigation__user}>
        <User />
      </span>
    </Dropdown>
  )
}

Profile.propType = {
  loggedIn: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
}

export default withCookies(
  WithUserContext(withTranslation()(Profile))
)
