import { message } from 'antd'

const isLowerThan10MB = file => {
  const isJpgOrPng =
    file.type === 'image/jpeg' || file.type === 'image/png'
  const isLt2M = file.size / 1024 / 1024 < 10

  if (!isJpgOrPng) {
    message.error('Slika mora biti JPG/PNG format!')
  }
  if (!isLt2M) {
    message.error('Slika mora biti manja od 10MB!')
  }

  return isJpgOrPng && isLt2M
}

export default isLowerThan10MB
