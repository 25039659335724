import React, { Component } from 'react'
import { Form, Input, Button, message } from 'antd'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'

import { apiPost } from '../../../api'
import Loader from '../../../components/Loader'
import errorHelper from '../../../helpers/errorHelper'

import classes from '../Auth.module.scss'

class WrappedResetPassword extends Component {
  state = {
    loader: false,
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.handleResetLink(values.email)
      }
    })
  }

  handleResetLink = async email => {
    this.setState({ loader: true })
    try {
      const res = await apiPost.resetLink(email)
      let msg = _get(res, 'data.message', null)
      if (!msg)
        throw new Error(this.props.t('auth.failResetPassword'))
      this.setState({ loader: false })
      message.success(msg)
    } catch (error) {
      this.setState({ loader: false })
      errorHelper(error, this.props.t('auth.failResetPassword'))
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <div className="form__wrapper">
        <h2>Resetovanje lozinke</h2>
        <Form onSubmit={this.handleSubmit}>
          <div className="form__section">
            <Form.Item label="E-mail">
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message:
                      'Unešen email nije validna email adresa!',
                  },
                  {
                    required: true,
                    message: 'Molimo unesite email!',
                  },
                ],
              })(<Input type="email" />)}
            </Form.Item>
          </div>
          <div className="btn__container">
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className={classes.auth__button}
            >
              Resetuj lozinku
            </Button>
          </div>
        </Form>
        {this.state.loader && <Loader />}
      </div>
    )
  }
}

const ResetPassword = Form.create({ name: 'reset password' })(
  WrappedResetPassword
)

export default withTranslation()(ResetPassword)
