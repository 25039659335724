import React from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

import Pagination from '../Pagination'
import FeedbackItems from '../FeedbackItems'

const FeedbackListWithModal = ({
  feedbackModalVisible,
  feedbackData,
  onCancel,
  customTitle,
  handlePagination,
}) => {
  return (
    <Modal
      title={customTitle}
      visible={feedbackModalVisible}
      onCancel={onCancel}
      footer={null}
    >
      {!!feedbackData.data.length ? (
        <FeedbackItems feedbackData={feedbackData} />
      ) : (
        <div style={{ textAlign: 'center' }}>
          Trenutno nema ostavljenih utisaka.
        </div>
      )}
      <Pagination
        ordersLength={feedbackData.data.length}
        metaTotal={feedbackData.meta.total}
        metaPerPage={feedbackData.meta.per_page}
        handlePageChange={handlePagination}
      />
    </Modal>
  )
}

FeedbackListWithModal.propTypes = {
  feedbackModalVisible: PropTypes.bool.isRequired,
  feedbackData: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  handlePagination: PropTypes.func.isRequired,
  customTitle: PropTypes.string.isRequired,
}

export default FeedbackListWithModal
