import React from 'react'
import PropTypes from 'prop-types'

import Burger from './Burger'
import Logo from '../Logo/Logo'
import { UserContext } from '../../contexts/UserContext'
import Profile from './Profile'

import classes from './Navigation.module.scss'

const Navigation = ({ openSideNav, history }) => {
  return (
    <UserContext.Consumer>
      {({ user }) => {
        return (
          <header className={classes.navigation__wrapper}>
            <div
              className={[
                classes.navigation__container,
                'container',
              ].join(' ')}
            >
              <Burger handleSideNav={openSideNav} />
              <div className={classes.navigation__logoContainer}>
                <Logo withLink color="orange" />
              </div>
              <Profile
                loggedIn={user.loggedIn}
                userType={user.user_type}
                history={history}
              />
            </div>
          </header>
        )
      }}
    </UserContext.Consumer>
  )
}

Navigation.propTypes = {
  openSideNav: PropTypes.func.isRequired,
}

export default Navigation
