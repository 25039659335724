import React, { Component, Fragment } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button, message } from 'antd'
import PropTypes from 'prop-types'

import Logo from '../Logo/Logo'
import screenOrientation from '../../helpers/screenOrientation'

import classes from './SignaturePad.module.scss'

class SignaturePad extends Component {
  state = {
    padVisible: false,
    signature: '',
    orientation: null,
    canvasRaw: null,
  }

  sigPad = {}

  componentDidMount() {
    window.addEventListener('resize', this.onOrientationChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onOrientationChange)
  }

  saveSig = () => {
    this.setState(
      {
        signature: this.sigPad
          .getTrimmedCanvas()
          .toDataURL('image/png'),
        padVisible: false,
        canvasRaw: this.sigPad.toData(),
      },
      () => {
        this.props.handleSignature(this.state.signature)
      }
    )
  }

  onOrientationChange = () => {
    if (
      this.state.padVisible &&
      screenOrientation() === 'landscape'
    ) {
      let rawData = this.sigPad.toData()

      if (rawData.length) {
        this.saveSig()
      } else {
        this.setState({ padVisible: false })
      }
    }
  }

  setPadVisibility = (visible, deletePrevious) => {
    if (screenOrientation() === 'portrait') {
      this.setState({ padVisible: visible })
      if (deletePrevious) {
        this.setState({ sginature: '' })
        this.props.handleSignature('')
      }
    } else {
      message.info(
        'Molimo da okrenete telefon u vertikalnu poziciju.',
        2
      )
    }
  }

  clearPad = () => this.sigPad.clear()

  deletePreviewSignature = () => {
    this.setState({ signature: '' })
    this.props.handleSignature('')
  }

  render() {
    let { padVisible, signature } = this.state

    return (
      <Fragment>
        <div className={classes.SignaturePad__section}>
          {!signature && (
            <Button
              icon="form"
              onClick={() => this.setPadVisibility(true)}
              className={classes.SignaturePad__leaveSignatureBtn}
              size="large"
            >
              Ostavite potpis
            </Button>
          )}
          {signature && (
            <div
              className={[
                classes.SignaturePad__signaturePreviewContainer,
                'section',
              ].join(' ')}
            >
              <img
                src={signature}
                className={classes.SignaturePad__signatureImg}
                alt="Signature"
              />
              <div
                className={
                  classes.SignaturePad__previewSignatureControls
                }
              >
                <Button
                  icon="delete"
                  onClick={this.deletePreviewSignature}
                  className={
                    classes.SignaturePad__previewSignatureBtn
                  }
                />
                <Button
                  icon="form"
                  onClick={() => this.setPadVisibility(true, true)}
                  className={
                    classes.SignaturePad__previewSignatureBtn
                  }
                />
              </div>
            </div>
          )}
        </div>
        {padVisible && (
          <div className={classes.SignaturePad__wrapper}>
            <div className={classes.SignaturePad__logo}>
              <Logo width={150} />
            </div>
            <Button
              className={classes.SignaturePad__closeBtn}
              icon="close"
              onClick={() => this.setPadVisibility(false, true)}
            />
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                className: classes.SignaturePad__canvas,
              }}
              ref={ref => {
                this.sigPad = ref
              }}
              clearOnResize={false}
            />
            <Button
              className={classes.SignaturePad__clearBtn}
              size="large"
              icon="delete"
              onClick={this.clearPad}
            />
            <Button
              className={classes.SignaturePad__saveBtn}
              size="large"
              icon="save"
              onClick={this.saveSig}
            />
          </div>
        )}
      </Fragment>
    )
  }
}

SignaturePad.propTypes = {
  handleSignature: PropTypes.func.isRequired,
}

export default SignaturePad
