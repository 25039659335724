import React, { Component, Fragment } from 'react'

import JobList from './JobList.js'
import Feedback from './Feedback.js'

class ContractorPanel extends Component {
  _isMounted = false
  state = {
    feedback: null,
  }

  // THIS IS WHEN FEEDBACK IS ACTIVE

  // componentDidMount() {
  //   this._isMounted = true

  //   this.getContractorFeedback(this.props.user.id)
  // }

  // componentWillUnmount() {
  //   this._isMounted = false
  // }

  // getContractorFeedback = async (id, page) => {
  //   try {
  //     let res
  //     if (page) {
  //       res = await apiGet.getContractorFeedback(id, page)
  //     } else {
  //       res = await apiGet.getContractorFeedback(id)
  //     }
  //     let feedback = _.get(res, 'data', null)
  //     if (!feedback) throw new Error('Could not get feedback')
  //     console.log(feedback)

  //     if (this._isMounted) {
  //       this.setState({ feedback })
  //     }
  //   } catch (error) {
  //     errorHelper(error, 'Could not get feedback')
  //   }
  // }

  // handleFeedbackPageChange = page => {
  //   this.getContractorFeedback(this.props.user.id, page)
  // }

  render() {
    let { feedback } = this.state

    return (
      <Fragment>
        <JobList />
        {feedback && (
          <Feedback
            feedback={feedback}
            handleFeedbackPageChange={this.handleFeedbackPageChange}
          />
        )}
      </Fragment>
    )
  }
}

export default ContractorPanel
