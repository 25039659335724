import React, { Component } from 'react'
import {
  Form,
  Input,
  Button,
  AutoComplete,
  message,
  Select,
  Checkbox,
} from 'antd'
import scroll from 'react-scroll'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _debounce from 'lodash/debounce'

import { withTranslation } from 'react-i18next'

import Section from '../../../components/OrderSections'
import TabName from '../../../components/TabName'
import scrollerConfig from '../../../configs/scrollerConfig'
import { apiGet } from '../../../api'
import HorizontalLine from '../../../components/HorizontalLine'
import SectionAnimated from '../../../components/SectionAnimated'
import errorHelper from '../../../helpers/errorHelper'
import scrollToElement from '../../../helpers/scrollToElement'
import { WithUserContext } from '../../../contexts/UserContext'
import ClientSearch from '../../../components/ClientSearch'
import checkIfExist from '../../../helpers/checkIfExist'

import classes from './AddressAndContact.module.scss'

const { Element, scroller } = scroll
const { Option } = AutoComplete
const { TextArea } = Input

class AddressAndContact extends Component {
  state = {
    valid: false,
    active: false,
    cityList: [],
    selectedCity: null,
    email: '',
    name: '',
    last_name: '',
    street: '',
    street_num: '',
    floor: '',
    appartment: '',
    phone_num: '',
    phone_num_additional: '',
    note: '',
    municipality: {
      id: '',
      name: undefined,
    },
    parking: true,
    clientSearchData: [],
  }

  componentDidMount() {
    this.getCity(1)

    if (this.props.user.user.id) {
      this.populateFields('loggedInUser', this.props.user.user)
    }
  }

  componentDidUpdate(props) {
    let { activeTabs, user } = this.props

    if (props.activeTabs.SelectService !== activeTabs.SelectService)
      this.AddressAndContactVisible()

    if (user.user !== props.user.user) {
      if (user.user.id) {
        this.populateFields('loggedInUser', user.user)
      }
    }
  }

  handleClientSearch = async query => {
    try {
      let res = await apiGet.clientSearch(query)
      let client = _get(res, 'data', null)
      if (!client)
        throw new Error(
          this.props.t('orderService.failedToFetchClients')
        )
      this.setState({ clientSearchData: client.data })
    } catch (error) {
      errorHelper(
        error,
        this.props.t('orderService.failedToFetchClients')
      )
    }
  }

  emptyField = query => {
    if (!query) {
      this.setState({
        name: '',
        last_name: '',
        email: '',
        street: '',
        street_num: '',
        appartment: '',
        floor: '',
        parking: false,
        phone_num: null,
        phone_num_additional: null,
        municipality: {
          id: '',

          name: '',
        },
      })

      this.props.form.setFieldsValue({
        name: '',
        last_name: '',
        email: '',
        street: '',
        street_num: '',
        appartment: '',
        floor: '',
        parking: false,
        phone_num: null,
        phone_num_additional: null,
        municipality: '',
      })
    }
  }

  handleClientSelect = (value, option) => {
    this.populateFields('autocomplete', option.props.text)
  }

  setTabActive = status => {
    this.props.changeTabStatus('AddressAndContact', status)
    this.setState({ active: status })
  }

  populateFields = (sourceType, user) => {
    if (sourceType === 'loggedInUser') {
      let userDetails = async userId => {
        try {
          let res = await apiGet.getClient(userId)
          let fullUser = _get(res, 'data', null)
          if (!fullUser)
            throw new Error(
              this.props.t('orderService.populatingFieldsFailed')
            )
          this.setState({
            name: checkIfExist(fullUser.first_name),
            last_name: checkIfExist(fullUser.last_name),
            email: checkIfExist(fullUser.email),
            street: checkIfExist(fullUser.street),
            street_num: checkIfExist(fullUser.street_number),
            appartment: checkIfExist(fullUser.apartment),
            floor: checkIfExist(fullUser.floor),
            parking: fullUser.has_parking
              ? fullUser.has_parking
              : false,
            phone_num: checkIfExist(parseInt(fullUser.phone)),
            phone_num_additional: checkIfExist(
              parseInt(fullUser.phone_additional)
            ),
            municipality: {
              id: !_isEmpty(fullUser.municipality)
                ? fullUser.municipality['id']
                : undefined,
              name: !_isEmpty(fullUser.municipality)
                ? fullUser.municipality.name
                : undefined,
            },
          })
        } catch (error) {
          console.log(error)
          // errorHelper(
          //   error,
          //   this.props.t('orderService.populatingFieldsFailed')
          // )
        }
      }
      if (user.loggedIn && user.user_type === 'client') {
        userDetails(user.id)
      }
    } else if (sourceType === 'autocomplete') {
      this.props.form.setFieldsValue({
        name: checkIfExist(user.first_name),
        last_name: checkIfExist(user.last_name),
        email: checkIfExist(user.email),
        street: checkIfExist(user.street),
        street_num: checkIfExist(user.street_number),
        appartment: checkIfExist(user.apartment),
        floor: checkIfExist(user.floor),
        parking: user.has_parking ? user.has_parking : false,
        phone_num: checkIfExist(parseInt(user.phone)),
        phone_num_additional: checkIfExist(
          parseInt(user.phone_additional)
        ),
        municipality: !_isEmpty(user.municipality)
          ? user.municipality.name
          : '',
      })

      this.setState({
        name: checkIfExist(user.first_name),
        last_name: checkIfExist(user.last_name),
        email: checkIfExist(user.email),
        street: checkIfExist(user.street),
        street_num: checkIfExist(user.street_number),
        appartment: checkIfExist(user.apartment),
        floor: checkIfExist(user.floor),
        parking: user.has_parking ? user.has_parking : false,
        phone_num: checkIfExist(parseInt(user.phone)),
        phone_num_additional: checkIfExist(
          parseInt(user.phone_additional)
        ),
        municipality: {
          id: !_isEmpty(user.municipality)
            ? user.municipality['id']
            : undefined,

          name: !_isEmpty(user.municipality)
            ? user.municipality.name
            : undefined,
        },
      })
    }
  }

  getCity = async id => {
    try {
      const res = await apiGet.getCityById(id)
      let city = _get(res, 'data', null)
      if (!city)
        message.error(
          this.props.t('orderService.failedToFetchCities')
        )
      this.setState({ selectedCity: city })
    } catch (error) {
      errorHelper(
        error,
        this.props.t('orderService.failedToFetchCities')
      )
    }
  }

  handleInputs = (e, type) => {
    if (type === 'checkbox') {
      this.setState({
        [e.target.id]: e.target.checked,
      })
    } else {
      this.setState({
        [e.target.id]: e.target.value,
      })
    }
  }

  handleOpenSection = () => {
    if (this.props.secondStepComplete) {
      this.setTabActive(true)
      this.handleScroll()
    }
  }

  selectMunicipality = (value, name) => {
    this.setState({
      municipality: {
        id: value,
        name,
      },
    })
  }

  handleKeyUp = e => {
    let {
      name,
      last_name,
      email,
      municipality,
      street,
      street_num,
      floor,
      appartment,
      phone_num,
      phone_num_additional,
      parking,
      note,
    } = this.state

    let { form, handleAddressAndContact, validate } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          valid: true,
        })

        this.setTabActive(false)
        this.props.changeTabStatus('CalendarAndTime', true)

        let parkingReversed = !parking

        handleAddressAndContact({
          name,
          last_name,
          email,
          municipality,
          street,
          street_num,
          floor,
          appartment,
          phone_num,
          phone_num_additional,
          parkingReversed,
          note,
        })
        validate(true)
      } else {
        let errorField = Object.keys(err).map(e => e)

        scroller.scrollTo(errorField[0], {
          ...scrollerConfig,
          offset: -250,
        })

        this.setState({
          valid: false,
        })
      }
    })
  }

  handleHideAndShow = () => {
    let result = false
    if (this.props.secondStepComplete) {
      result = false
    } else if (this.props.firstStepCompleted) {
      result = true
    } else if (this.state.active) {
      result = true
    } else if (!this.state.active) {
      result = false
    }
    return result
  }

  handleScroll = () => {
    scrollToElement('scrollLocationAddressAndContact', -250)
  }

  AddressAndContactVisible = () => {
    if (
      this.props.activeTabs.SelectService === false &&
      this.props.secondStepComplete === false
    ) {
      this.setTabActive(true)
      this.handleScroll()
    }
  }

  render() {
    let {
      name,
      last_name,
      street,
      street_num,
      selectedCity,
      email,
      municipality,
      floor,
      appartment,
      parking,
      phone_num,
      phone_num_additional,
    } = this.state
    let { secondStepComplete, activeTabs, user } = this.props
    let { getFieldDecorator } = this.props.form

    let showContact = activeTabs.AddressAndContact

    let infoForSummary = [
      {
        row_name: 'Ime',
        display_name: name,
      },
      {
        row_name: 'Prezime',
        display_name: last_name,
      },
      {
        row_name: 'Ulica',
        display_name: street,
      },
      {
        row_name: 'Broj',
        display_name: street_num,
      },
    ]

    let userRole = user.user.user_type

    return (
      <Section>
        <Element name="scrollLocationAddressAndContact" />
        <TabName
          text="osnovni podaci"
          handleOpenSection={this.handleOpenSection}
          checked={secondStepComplete}
          summary={secondStepComplete && infoForSummary}
          // displaySummary={!this.state.active && secondStepComplete}
          displaySummary={
            !activeTabs.AddressAndContact && secondStepComplete
          }
          section="address-and-contact"
        />
        {selectedCity && (
          <SectionAnimated active={showContact}>
            <Form className="login-form">
              {userRole === 'boothman' && (
                <Form.Item
                  label="Pretraga klijenata"
                  style={{ marginBottom: 0 }}
                >
                  <ClientSearch
                    handleSearch={_debounce(
                      this.handleClientSearch,
                      300
                    )}
                    clearFields={this.emptyField}
                    data={this.state.clientSearchData}
                    onSelect={this.handleClientSelect}
                  />
                </Form.Item>
              )}
              <Form.Item label="Ime">
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Unesite ime!',
                    },
                  ],
                  initialValue: name,
                })(
                  <Input
                    id="name"
                    name="name"
                    onChange={e => this.handleInputs(e)}
                    // value={name}
                  />
                )}
              </Form.Item>
              <Form.Item label="Prezime">
                {getFieldDecorator('last_name', {
                  rules: [
                    {
                      required: true,
                      message: 'Unesite prezime!',
                    },
                  ],
                  initialValue: last_name,
                })(
                  <Input
                    id="last_name"
                    name="last_name"
                    onChange={e => this.handleInputs(e)}
                  />
                )}
              </Form.Item>

              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'Email nije validan!',
                    },
                    {
                      required: true,
                      message: 'Unesite email!',
                    },
                  ],
                  initialValue: email,
                })(
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    onChange={e => this.handleInputs(e)}
                  />
                )}
              </Form.Item>
              <HorizontalLine margin={24} />
              <Form.Item label="Grad">
                {getFieldDecorator('city', {
                  initialValue: 'Beograd',
                  rules: [
                    {
                      required: true,
                      message: 'Unesite ime grada!',
                    },
                  ],
                })(
                  <Select id="city">
                    <Option value="1">Beograd</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Opština">
                {getFieldDecorator('municipality', {
                  rules: [
                    {
                      required: true,
                      message: 'Unesite ime opštine!',
                    },
                  ],
                  initialValue: municipality.name,
                })(
                  <Select
                    onChange={(value, e) =>
                      this.selectMunicipality(value, e.props.name)
                    }
                    id="municipality"
                  >
                    {selectedCity.municipalities.map(c => {
                      return (
                        <Option key={c.id} value={c.id} name={c.name}>
                          {c.name}
                        </Option>
                      )
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Ulica">
                {getFieldDecorator('street', {
                  rules: [
                    { required: true, message: 'Unesite ime ulice!' },
                  ],
                  initialValue: street,
                })(
                  <Input
                    type="text"
                    onChange={e => this.handleInputs(e)}
                    id="street"
                    name="street"
                  />
                )}
              </Form.Item>
              <div className={classes.AddressAndContact__container}>
                <Form.Item className="grid__30" label="Broj">
                  {getFieldDecorator('street_num', {
                    rules: [
                      {
                        required: true,
                        message: 'Upišite broj stana!',
                      },
                    ],
                    initialValue: street_num,
                  })(
                    <Input
                      style={{ width: '100%' }}
                      id="street_num"
                      name="street_num"
                      // onBlur={this.handleKeyUp}
                      onChange={e => this.handleInputs(e)}
                    />
                  )}
                </Form.Item>
                <Form.Item className="grid__30" label="Sprat">
                  {getFieldDecorator('floor', {
                    rules: [
                      {
                        required: false,
                        message: 'Upišite sprat!',
                      },
                    ],
                    initialValue: floor,
                  })(
                    <Input
                      style={{ width: '100%' }}
                      min={0}
                      onChange={e => this.handleInputs(e)}
                      id="floor"
                      name="floor"
                      type="number"
                    />
                  )}
                </Form.Item>
                <Form.Item className="grid__30" label="Stan">
                  {getFieldDecorator('appartment', {
                    rules: [
                      {
                        required: false,
                        message: 'Upišite broj stana!',
                      },
                    ],
                    initialValue: appartment,
                  })(
                    <Input
                      // className="grid__100"
                      id="appartment"
                      onChange={e => this.handleInputs(e)}
                      name="appartment"
                    />
                  )}
                </Form.Item>
              </div>
              <Form.Item>
                {getFieldDecorator('parking', {
                  initialValue: parking,
                })(
                  <Checkbox
                    id="parking"
                    checked={parking}
                    onChange={e => this.handleInputs(e, 'checkbox')}
                    name="parking"
                  />
                )}
                <label className="label" htmlFor="parking">
                  Parking mesto
                </label>
              </Form.Item>
              <Form.Item label="Kontakt telefon">
                {getFieldDecorator('phone_num', {
                  rules: [
                    {
                      required: true,
                      message: 'Upišite svoj broj telefona!',
                    },
                  ],
                  initialValue: phone_num,
                })(
                  <Input
                    onChange={e => this.handleInputs(e)}
                    id="phone_num"
                    name="phone_num"
                    type="tel"
                  />
                )}
              </Form.Item>
              <Form.Item label="Dodatni kontakt telefon">
                {getFieldDecorator('phone_num_additional', {
                  // rules: [
                  //   {
                  //     message: 'Broj telefona nije validan!',
                  //   },
                  // ],
                  initialValue: phone_num_additional,
                })(
                  <Input
                    onChange={e => this.handleInputs(e)}
                    id="phone_num_additional"
                    name="phone_num_additional"
                    type="tel"
                  />
                )}
              </Form.Item>
              <Form.Item label="Dodatne napomene">
                {getFieldDecorator(
                  'note',
                  {}
                )(
                  <TextArea
                    onChange={e => this.handleInputs(e)}
                    id="note"
                    name="note"
                  />
                )}
              </Form.Item>
            </Form>

            <div className="btn__container">
              <Button
                type="primary"
                size="large"
                onClick={() => this.handleKeyUp(this.state)}
              >
                Dalje
              </Button>
            </div>
          </SectionAnimated>
        )}
      </Section>
    )
  }
}

AddressAndContact.propTypes = {
  handleAddressAndContact: PropTypes.func.isRequired,
  firstStepCompleted: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  secondStepComplete: PropTypes.bool.isRequired,
  changeTabStatus: PropTypes.func.isRequired,
  activeTabs: PropTypes.object,
}

const WrappedAddressAndContact = Form.create()(AddressAndContact)

export default WithUserContext(
  withTranslation()(WrappedAddressAndContact)
)
