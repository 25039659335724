import React, { useState } from 'react'
import _get from 'lodash/get'
import { Input, Button, Form, message } from 'antd'
import { withTranslation } from 'react-i18next'

import Section from '../OrderSections'
import { apiPost } from '../../api'
import errorHelper from '../../helpers/errorHelper'

const UserChangePassword = props => {
  let [confirmPass, setConfirmPass] = useState(false)

  let updatePassword = async values => {
    let { password, password_confirmation } = values

    try {
      let res = await apiPost.updatePassword(
        password,
        password_confirmation
      )
      let result = _get(res, 'data', null)
      if (!result)
        throw new Error(
          props.t(
            'component.UserChangePassword.failedToChangePassword'
          )
        )
      message.success(result.message)
      props.form.resetFields()
    } catch (error) {
      errorHelper(
        error,
        props.t('component.UserChangePassword.failedToChangePassword')
      )
    }
  }

  let hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  let handleConfirmBlur = e => {
    const { value } = e.target
    setConfirmPass(confirmPass || !!value)
  }

  let compareToFirstPassword = (rule, value, callback) => {
    const { form } = props
    if (value && value !== form.getFieldValue('password')) {
      callback(
        props.t('component.UserChangePassword.comparePasswordFail')
      )
    } else {
      callback()
    }
  }

  let validateToNextPassword = (rule, value, callback) => {
    const { form } = props
    if (value && confirmPass) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  let savePasswordChange = e => {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        updatePassword(values)
      }
    })
  }

  const { getFieldDecorator, getFieldsError } = props.form

  return (
    <Section>
      <Form onSubmit={savePasswordChange}>
        <Form.Item label="Nova lozinka" hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Molimo unesite lozinku!',
              },
              {
                validator: validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item label="Ponovite novu lozinku" hasFeedback>
          {getFieldDecorator('password_confirmation', {
            rules: [
              {
                required: true,
                message: 'Molimo potvrdite unešenu lozinku!',
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={handleConfirmBlur} />)}
        </Form.Item>
        <Form.Item>
          <div className="btn__container">
            <Button
              size="large"
              type="primary"
              disabled={hasErrors(getFieldsError())}
              htmlType="submit"
            >
              Promeni lozinku
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Section>
  )
}

const WrappedUserChangePassword = Form.create({
  name: 'change password',
})(UserChangePassword)

export default withTranslation()(WrappedUserChangePassword)
