import React from 'react'
import { Switch, Icon } from 'antd'
import PropTypes from 'prop-types'

const WorkingStatusSwitch = ({ status, toggleFunction, loading }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        border: `2px solid ${status ? 'green' : 'red'}`,
        padding: 8,
        borderRadius: 4,
        marginBottom: 8,
      }}
    >
      <span style={{ display: 'block' }}>
        Status: {status ? 'Aktivan' : 'Neaktivan'}
      </span>
      <Switch
        onChange={toggleFunction}
        checked={status}
        checkedChildren={<Icon type="check" />}
        unCheckedChildren={<Icon type="close" />}
        loading={loading}
      />
    </div>
  )
}

WorkingStatusSwitch.propType = {
  status: PropTypes.string.isRequired,
  toggleFunction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default WorkingStatusSwitch
