import React, { PureComponent, Fragment } from 'react'
import scroll from 'react-scroll'
import { Button } from 'antd'
import moment from 'moment'
import _get from 'lodash/get'
import DayPicker from 'react-day-picker'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { apiPost } from '../../../api'
import Section from '../../../components/OrderSections'
import TabName from '../../../components/TabName'
import SelectTime from './time'
import {
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
} from '../../../helpers/calendarLocalization'
import SectionAnimated from '../../../components/SectionAnimated'
import errorHelper from '../../../helpers/errorHelper'
import scrollToElement from '../../../helpers/scrollToElement'
import InputSkeleton from '../../../components/InputSkeleton'

import classes from './CalendarAndTime.module.scss'

const { Element } = scroll

class CalendarAndTime extends PureComponent {
  state = {
    date: null,
    time: null,
    active: false,
    availableDays: [],
    disabledDays: [],
    availableTime: [],
    loading: false,
    arrowDisabled: false,
  }

  componentDidUpdate(props) {
    let { activeTabs, servicesSelected } = this.props

    if (
      props.activeTabs.CalendarAndTime !== activeTabs.CalendarAndTime
    ) {
      this.getNewTimeTwo()
    }

    if (props.servicesSelected !== servicesSelected) {
      this.CalendarAndTimeVisible()
    }

    // if (
    //   this.props.timeSummary !== props.timeSummary ||
    //   this.props.selectedMunicipality !== props.selectedMunicipality
    // ) {
    //   this.getNewTime()
    // }
  }

  getAvailableDates = async () => {
    let { selectedMunicipality, timeSummary, t } = this.props

    this.setState({ loading: true })

    let fromDate = moment(this.showNextMonthIfIsLastDay()).format(
      'YYYY-MM-DD'
    )
    let endOfMonth = moment(this.showNextMonthIfIsLastDay())
      .add(1, 'months')
      .date(0)
      .format('YYYY-MM-DD')

    this.disableArrowAccordingToMonth()

    try {
      const res = await apiPost.getAvailableDates(
        selectedMunicipality,
        fromDate,
        endOfMonth,
        timeSummary
      )
      let dates = _get(res, 'data', null)
      if (!dates)
        throw new Error(t('orderService.failedToFetchDates'))
      let disabledDays = dates
        .filter(a => {
          return a.available === false
        })
        .map(d => new Date(moment(d.date).format('YYYY-MM-DD')))
      this.setState({
        availableDays: dates,
        disabledDays,
        loading: false,
      })
    } catch (error) {
      this.setState({ loading: false })

      errorHelper(error, t('orderService.failedToFetchDates'))
    }
  }

  getAvailableDatesForNextMonth = async month => {
    this.setState({ loading: true })

    let startOfMonth = moment(month)
      .startOf('month')
      .format('YYYY-MM-DD')
    let endOfMonth = moment(month)
      .endOf('month')
      .format('YYYY-MM-DD')

    this.disableArrowAccordingToMonth(month)

    try {
      const res = await apiPost.getAvailableDates(
        this.props.selectedMunicipality,
        startOfMonth,
        endOfMonth,
        this.props.timeSummary
      )
      let dates = _get(res, 'data', null)
      if (!dates)
        throw new Error(
          this.props.t('orderService.failedToFetchDatesForNextMonth')
        )

      let disabledDays = dates
        .filter(a => {
          return a.available === false
        })
        .map(d => new Date(moment(d.date).format('YYYY-MM-DD')))
      this.setState({
        availableDays: dates,
        disabledDays,
        loading: false,
      })
      // this.setState({ availableDays: dates })
    } catch (error) {
      this.setState({ loading: false })
      errorHelper(
        error,
        this.props.t('orderService.failedToFetchDatesForNextMonth')
      )
    }
  }

  getAvailableTimeSlots = async () => {
    let formatDay = moment(this.state.date).format('YYYY-MM-DD')

    try {
      const res = await apiPost.getAvailableTime(
        this.props.selectedMunicipality,
        formatDay,
        this.props.timeSummary
      )
      let time = _get(res, 'data', null)
      if (!time)
        throw new Error(
          this.props.t('orderService.failedToFetchTimeslot')
        )
      this.setState({ availableTime: time.slots })

      // if (!this.props.contractorList.length) {
      //   console.log('getAvailableTimeSlots')

      //   scrollToElement('SelectTimeElement', 0)
      // }
    } catch (error) {
      errorHelper(
        error,
        this.props.t('orderService.failedToFetchTimeslot')
      )
    }
  }

  showNextMonthIfIsLastDay = () => {
    let currentDate = new Date()

    if (
      moment()
        .endOf('month')
        .format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
    ) {
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return currentDate
  }

  setTabActive = status => {
    this.props.changeTabStatus('CalendarAndTime', status)
    this.setState({ active: status })
  }

  handleCalendar = day => {
    let formatDate = moment(day).format('D.M.YYYY')
    this.setState({ date: formatDate })
  }

  handleTime = time => {
    this.setState({ time })
  }

  handleOpenSection = () => {
    if (this.props.dateTimeValid) {
      this.setTabActive(!this.state.active)
    }
  }

  handleDateAndTime = () => {
    let { date, time } = this.state
    let {
      handleDateAndTime,
      getContractorList,
      selectedMunicipality,
      timeSummary,
    } = this.props

    let isValid = !!date && !!time

    handleDateAndTime(date, time, isValid)

    this.setTabActive(false)
    this.props.changeTabStatus('SelectContractor', true)

    getContractorList(
      selectedMunicipality,
      moment(date).format('YYYY-MM-DD'),
      time,
      timeSummary
    )
  }

  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return
    }

    this.setState(
      {
        date: day,
        availableTime: [],
        time: null,
      },
      () => this.getAvailableTimeSlots()
    )
  }

  handleScroll = () => {
    let { loading, availableDays, date } = this.state
    let { activeTabs } = this.props

    if (
      activeTabs.CalendarAndTime &&
      !loading &&
      !!availableDays.length &&
      !activeTabs.SelectService
    ) {
      if (!date) {
        scrollToElement('calendarAndTime', -150)
      }
    }
  }

  CalendarAndTimeVisible = () => {
    let { activeTabs, secondStepComplete } = this.props

    if (
      activeTabs.SelectService === false &&
      activeTabs.AddressAndContact === false &&
      secondStepComplete
    ) {
      this.setTabActive(true)
    }
  }

  getNewTime = () => {
    if (this.props.activeTabs.SelectService === false) {
      this.setTabActive(true)

      this.setState(
        {
          date: null,
          time: null,
          availableTime: [],
          availableDays: [],
        },
        () => this.getAvailableDates()
      )
    }
  }

  getNewTimeTwo = () => {
    if (this.props.activeTabs.CalendarAndTime) {
      this.setTabActive(true)

      this.setState(
        {
          date: null,
          time: null,
          availableTime: [],
          availableDays: [],
        },
        () => this.getAvailableDates()
      )
    }
  }

  disableArrowAccordingToMonth = month => {
    let lastAvailableMonth = moment().format('M.YYYY')
    let currentMonth = month
      ? moment(month).format('M.YYYY')
      : moment().format('M.YYYY')

    let disableArrow = currentMonth === lastAvailableMonth

    this.setState({ arrowDisabled: disableArrow })
  }

  render() {
    const {
      date,
      time,
      loading,
      disabledDays,
      availableTime,
      arrowDisabled,
      active,
    } = this.state
    const { activeTabs, servicesSelected } = this.props

    let buttonStatus = !!date && !!time

    this.handleScroll()

    let disableCalendarArrow = arrowDisabled ? 'disable-arrow' : null

    return (
      <Section>
        <Element name="calendarAndTime" />
        <TabName
          text="Termin sastavljanja"
          handleOpenSection={this.handleOpenSection}
          checked={!!date && !!time && servicesSelected}
          summary={
            !active && {
              date: moment(date).format('DD.MM.YYYY.'),
              time,
            }
          }
          displaySummary={!!date && !!time && servicesSelected}
          section="calendar-and-time"
        />
        <SectionAnimated active={activeTabs.CalendarAndTime}>
          <Fragment>
            <div className={classes.container}>
              {activeTabs.CalendarAndTime && (
                <DayPicker
                  initialMonth={this.showNextMonthIfIsLastDay()}
                  // month={!loading ? new Date() : null}
                  onMonthChange={month =>
                    this.getAvailableDatesForNextMonth(month)
                  }
                  months={MONTHS}
                  weekdaysLong={WEEKDAYS_LONG}
                  weekdaysShort={WEEKDAYS_SHORT}
                  className={[
                    classes.calendar,
                    disableCalendarArrow,
                  ].join(' ')}
                  firstDayOfWeek={1}
                  selectedDays={date}
                  onDayClick={this.handleDayClick}
                  disabledDays={
                    !loading
                      ? [
                          new Date(),
                          { before: new Date() },
                          ...disabledDays,
                        ]
                      : [
                          new Date(),
                          { before: new Date() },
                          { after: new Date() },
                        ]
                  }
                />
              )}

              {!!availableTime.length && (
                <Fragment>
                  <Element name="SelectTimeElement" />
                  <SelectTime
                    handleTime={this.handleTime}
                    availableTime={availableTime}
                    selectedTime={time}
                  />
                </Fragment>
              )}

              {!!!availableTime.length && date && (
                <div style={{ textAlign: 'center' }}>
                  <InputSkeleton
                    height="32px"
                    width="310px"
                    margin={false}
                  />
                </div>
              )}
            </div>
            <div className="btn__container">
              <Button
                size="large"
                type="primary"
                disabled={!buttonStatus}
                onClick={this.handleDateAndTime}
              >
                Dalje
              </Button>
            </div>
          </Fragment>
        </SectionAnimated>
      </Section>
    )
  }
}

CalendarAndTime.propTypes = {
  activeTabs: PropTypes.object.isRequired,
  servicesSelected: PropTypes.bool.isRequired,
  timeSummary: PropTypes.number.isRequired,
  changeTabStatus: PropTypes.func.isRequired,
  dateTimeValid: PropTypes.bool.isRequired,
  secondStepComplete: PropTypes.bool.isRequired,
  handleDateAndTime: PropTypes.func.isRequired,
  getContractorList: PropTypes.func.isRequired,
  selectedMunicipality: PropTypes.number,
}

export default withTranslation()(CalendarAndTime)
