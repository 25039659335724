import React, { Component, Fragment } from 'react'
import _get from 'lodash/get'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'

import { apiGet } from '../../../api'
import JobList from './JobList.js'
import errorHelper from '../../../helpers/errorHelper'
import Loader from '../../../components/Loader'

class ContractorPanelWithId extends Component {
  state = {
    contractorId: null,
    loadedContractor: {},
    isUserLoading: false,
  }

  componentDidMount() {
    let id = this.props.match.params.id

    if (id) {
      this.setState({ contractorId: id })
    }

    this.getContractorProfile(id)
  }

  getContractorProfile = async id => {
    this.setState({ isUserLoading: true })
    try {
      let res = await apiGet.getContractorById(id)
      let contractor = _get(res, 'data', null)
      if (!contractor)
        throw new Error(
          this.props.t('dashboard.userInformationFailed')
        )
      this.setState({
        loadedContractor: contractor,
        isUserLoading: false,
      })
    } catch (error) {
      this.setState({ isUserLoading: false })
      errorHelper(
        error,
        this.props.t('dashboard.userInformationFailed')
      )
    }
  }

  render() {
    let { loadedContractor, contractorId, isUserLoading } = this.state

    return (
      <Fragment>
        {isUserLoading ? (
          <Loader />
        ) : (
          <JobList
            loadedContractor={loadedContractor}
            loadedContractorId={contractorId}
          />
        )}
      </Fragment>
    )
  }
}

export default withRouter(withTranslation()(ContractorPanelWithId))
