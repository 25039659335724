import React from 'react'

// import JobApplicationSection from './JobApplicationSection'
import PaymentBranding from '../PaymentBranding'

import classes from './Footer.module.scss'

let Footer = () => {
  // let isJobApplicationVisible = window.location.pathname === '/'

  return (
    <div className={classes.footer__wrapper}>
      <div className={classes['footer--upper']}>
        {/* {isJobApplicationVisible && <JobApplicationSection />} */}
        <div
          className={[classes.footer__container, 'container'].join(
            ' '
          )}
        >
          <div className={classes.footer__links}>
            <a href="/terms-and-conditions" target="_blank">
              Uslovi korišćenja
            </a>
          </div>
          <span className={classes.footer__copyrightItem}>
            Homea &copy; {new Date().getFullYear()}
          </span>
        </div>
      </div>
      <div
        className={[
          classes['footer--lower'],
          classes.footer__container,
          'container',
        ].join(' ')}
      >
        <div className={classes.footer__wrapper}>
          <PaymentBranding />
        </div>
      </div>
    </div>
  )
}

export default Footer
