import React from 'react'
import PropTypes from 'prop-types'

import classes from './Section.module.scss'

const SectionName = ({ text, margin = 0 }) => {
  return (
    <p
      className={classes.section__name}
      style={{ marginBottom: margin }}
    >
      {text}
    </p>
  )
}

SectionName.propTypes = {
  text: PropTypes.string.isRequired,
}

export default SectionName
