import React, { Component } from 'react'
import { Form, Input, Button, message } from 'antd'
import { Link } from 'react-router-dom'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'
import { withCookies } from 'react-cookie'

import { apiPost, apiGet } from '../../../api'
import { WithUserContext } from '../../../contexts/UserContext'
import Loader from '../../../components/Loader'
import setAuthorizationToken from '../../../utils/setAuthorizationToken'
import errorHelper from '../../../helpers/errorHelper'
import setDocumentTitle from '../../../helpers/setDocumentTitle'
import HorizontalLine from '../../../components/HorizontalLine'

import classes from '../Auth.module.scss'

class UserLoginForm extends Component {
  state = {
    loader: false,
  }

  componentDidMount() {
    setDocumentTitle(this.props.t('page.title.login'))
  }

  handleLogin = async (email, password) => {
    let { t, cookies, user } = this.props

    this.setState({ loader: true })
    try {
      const res = await apiPost.login(email, password)
      let userToken = _get(res, 'data.token', null)
      let tokenExpires = _get(res, 'data.expires_in', null)
      let userEmail = _get(res, 'data.email', null)
      let userFirstName = _get(res, 'data.first_name', null)
      let userLastName = _get(res, 'data.last_name', null)
      let userType = _get(res, 'data.user_type', null)
      let userTypeId = _get(res, 'data.user_type_id', null)

      if (!userToken) throw new Error(t('auth.failLogin'))

      this.setState({ loader: false })

      if (userType === 'admin') {
        const adminRoute = `${process.env.REACT_APP_BACKEND_URL}/admin`

        window.location.href = adminRoute
        return
      }

      setAuthorizationToken(userToken)

      cookies.set('homea', userToken, {
        maxAge: tokenExpires,
        path: '/',
      })

      user.onSetUser(
        userFirstName,
        userLastName,
        userEmail,
        true,
        userType,
        userTypeId
      )

      message.success(t('auth.successLogin'))

      if (userType === 'boothman') {
        this.props.history.push('/boothman')
      } else {
        this.props.history.push('/')
      }
    } catch (error) {
      this.setState({ loader: false })
      const res = error.response.data.errors
      Object.keys(res).map(key => message.error(res[key]))
    }
  }

  googleAuth = async () => {
    let { t } = this.props

    try {
      const res = await apiGet.getGoogle()
      let googleUrl = _get(res, 'data.url', null)
      if (!googleUrl)
        throw new Error(t('auth.socialNewtorkRegistrationFailed'))
      window.location = googleUrl
    } catch (error) {
      errorHelper(error, t('auth.socialNewtorkRegistrationFailed'))
    }
  }

  facebookAuth = async () => {
    let { t } = this.props

    try {
      const res = await apiGet.getFacebook()
      let facebookUrl = _get(res, 'data.url', null)
      if (!facebookUrl)
        throw new Error(t('auth.socialNewtorkRegistrationFailed'))
      window.location = facebookUrl
    } catch (error) {
      errorHelper(error, t('auth.socialNewtorkRegistrationFailed'))
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { email, password } = values
        this.handleLogin(email, password)
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <div className="form__wrapper">
        <h2 className={classes.auth__heading}>Prijavite se</h2>
        <p className={classes.auth__desc}>
          Prijavite se pomoću emaila ili preko društvenih mreža.
        </p>
        <div className={classes.auth__socials}>
          <Button
            size="large"
            icon="google"
            type="primary"
            onClick={this.googleAuth}
            className={[
              classes.auth__socialBtn,
              classes.auth__google,
            ].join(' ')}
          >
            Google
          </Button>
          <Button
            size="large"
            icon="facebook"
            type="primary"
            onClick={this.facebookAuth}
            className={[
              classes.auth__socialBtn,
              classes.auth__fb,
            ].join(' ')}
          >
            Facebook
          </Button>
        </div>

        <HorizontalLine margin={24} text="ili" />

        <Form onSubmit={this.handleSubmit}>
          <div className="form__section">
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'Molimo unesite email!',
                  },
                ],
              })(<Input type="email" />)}
            </Form.Item>
            <Form.Item label="Lozinka">
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Molimo unesite lozinku!',
                  },
                ],
              })(<Input type="password" autoComplete="off" />)}
            </Form.Item>
          </div>

          <Form.Item>
            <Link to="/user/reset" className={classes.auth__forgot}>
              Zaboravili ste lozinku?
            </Link>
            <div className={classes.auth__loginControls}>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className={[
                  classes.auth__button,
                  classes['auth__button--fixed'],
                ].join(' ')}
              >
                Prijavi se
              </Button>
              <span>
                ili se <Link to="/user/register">registrujte</Link>
              </span>
            </div>
          </Form.Item>
        </Form>
        {this.state.loader && <Loader />}
      </div>
    )
  }
}

const Login = Form.create({ name: 'normal_login' })(UserLoginForm)

export default withCookies(WithUserContext(withTranslation()(Login)))
