import React, { Fragment, useState, useEffect } from 'react'
import { Select, Button, notification } from 'antd'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import CategorySummary from './CategorySummary'
import { apiGet } from '../../../api'
import errorHelper from '../../../helpers/errorHelper'

import classes from './SelectProduct.module.scss'

const { Option } = Select

const Categories = ({
  t,
  timeSumm,
  handleSelectedService,
  selectedService,
  priceSumm,
  handleCleanStateFake,
}) => {
  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    const getCategories = async () => {
      try {
        const res = await apiGet.productCategories()
        let categories = _get(res, 'data', null)
        if (!categories)
          throw new Error(t('orderService.failedToFetchCategories'))
        setCategoryList(categories)
      } catch (error) {
        errorHelper(error, t('orderService.failedToFetchCategories'))
      }
    }

    getCategories()
  }, [t])

  const handleSelect = (value, option) => {
    let {
      name,
      id,
      assembly_time,
      assembly_price,
      sku,
    } = option.props.option

    if (timeSumm >= 360) {
      notification['info']({
        message: t('orderService.exceededHours'),
        description: t('orderService.exceededHoursDescription'),
      })
    } else {
      handleSelectedService(
        name,
        id,
        assembly_time,
        assembly_price,
        sku
      )
    }
  }

  let children = categoryList.map(c => {
    return (
      <Option
        key={c.id}
        value={c.id.toString()}
        // value={c.name}
        option={{
          id: c.id,
          sku: c.sku,
          assembly_time: c.assembly_time,
          assembly_price: c.assembly_price,
          name: c.name,
        }}
        disabled={selectedService.find(
          selected => selected.id === c.id
        )}
      >
        <span>{c.name}</span>
      </Option>
    )
  })

  return (
    <Fragment>
      <Select
        className={classes.service__select}
        size="large"
        placeholder="Izaberite tip nameštaja"
        optionFilterProp="children"
        onSelect={handleSelect}
        value={[]}
      >
        {children}
      </Select>
      {/* If there is some selected service */}
      {!_isEmpty(selectedService) && (
        <div
          className={[
            'btn__container',
            classes.service__btnContainer,
          ].join(' ')}
        >
          <CategorySummary
            time={timeSumm}
            price={priceSumm}
            closed={false}
          />
          {!_isEmpty(selectedService) && (
            <Button
              type="primary"
              onClick={handleCleanStateFake}
              size="large"
            >
              Dalje
            </Button>
          )}
        </div>
      )}
    </Fragment>
  )
}

Categories.propTypes = {
  handleSelectedService: PropTypes.func.isRequired,
  handleCleanStateFake: PropTypes.func.isRequired,
  selectedService: PropTypes.array.isRequired,
  timeSumm: PropTypes.number.isRequired,
  priceSumm: PropTypes.number.isRequired,
}

export default withTranslation()(Categories)
