import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const GuestRoute = ({
  isAuthenticated,
  Component,
  path,
  ...restProps
}) => {
  if (!isAuthenticated) {
    return (
      <Route
        path={path}
        render={props => (
          <Component
            isAuthenticated={isAuthenticated}
            {...restProps}
            {...props}
          />
        )}
      />
    )
  } else {
    return <Redirect to="/" />
  }
}

GuestRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  path: PropTypes.string,
}

export default GuestRoute
