import React from 'react'
import PropTypes from 'prop-types'

const TransactionInformation = ({ transactionInformation }) => (
  <ul style={{ marginBottom: 0 }}>
    {Object.keys(transactionInformation).map(
      info =>
        transactionInformation[info] && (
          <li key={transactionInformation[info]}>
            {info}: {transactionInformation[info]}
          </li>
        )
    )}
  </ul>
)

TransactionInformation.propTypes = {
  transactionInformation: PropTypes.object.isRequired,
}

export default TransactionInformation
