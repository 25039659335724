import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import scroll from 'react-scroll'
import { withTranslation } from 'react-i18next'
import _isEmpty from 'lodash/isEmpty'

import Categories from './Categories'
import TabName from '../../../components/TabName'
import Section from '../../../components/OrderSections'
import SectionAnimated from '../../../components/SectionAnimated'
import scrollToElement from '../../../helpers/scrollToElement'

const { Element } = scroll

const SelectCategory = ({
  changeTabStatus,
  activeTabs,
  handleServiceSelectComplete,
  secondStepComplete,
  selectedService,
  serviceExist,
  servicesSelected,
  deleteService,
  changeQuantity,
  calculateAvailableTime,
  availableTime,
  servicesSelectedTimeSum,
  servicesSelectedPriceSum,
  services,
  addCategory,
}) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setTabActive(true)
    // Disabling rule becouse of not setting depedency array (no need for it in this case)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setTabActive = status => {
    changeTabStatus('SelectService', status)
    setActive(status)
    handleScroll(status)
  }

  const handleOpenSection = () => {
    if (!activeTabs.SelectService) setTabActive(true)
  }

  const handleCleanState = () => {
    handleServiceSelectComplete()
    setTabActive(false)
    if (secondStepComplete) changeTabStatus('CalendarAndTime', true)
  }

  const handleScroll = isTabActive => {
    if (isTabActive) scrollToElement('select-service', -150)
  }

  let serviceSummary

  let activeSection =
    activeTabs.SelectService || _isEmpty(selectedService)

  return (
    <Section>
      <Element name="select-service" />
      <TabName
        section="select-category"
        text="Tip nameštaja"
        handleOpenSection={e => handleOpenSection(e)}
        checked={servicesSelected}
        summary={serviceSummary}
        displaySummary={serviceExist}
        deleteService={deleteService}
        changeQuant={changeQuantity}
        options={active}
        availableTime={availableTime}
        calculateAvailableTime={calculateAvailableTime}
        timeSumm={servicesSelectedTimeSum}
        selectServiceActive={activeSection}
        activeTabs={activeTabs}
        priceSumm={servicesSelectedPriceSum}
        services={services}
      />
      <SectionAnimated active={activeSection}>
        <Categories
          handleSelectedService={addCategory}
          handleCleanStateFake={handleCleanState}
          selectedService={selectedService}
          timeSumm={servicesSelectedTimeSum}
          priceSumm={servicesSelectedPriceSum}
          isTabActive={active}
        />
      </SectionAnimated>
    </Section>
  )
}

SelectCategory.propTypes = {
  addCategory: PropTypes.func.isRequired,
  serviceExist: PropTypes.bool.isRequired,
  servicesSelected: PropTypes.bool.isRequired,
  selectedService: PropTypes.array.isRequired,
  handleServiceSelectComplete: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  changeQuantity: PropTypes.func.isRequired,
  servicesSelectedTimeSum: PropTypes.number,
  calculateAvailableTime: PropTypes.func.isRequired,
  availableTime: PropTypes.bool.isRequired,
  changeTabStatus: PropTypes.func.isRequired,
  activeTabs: PropTypes.object.isRequired,
  secondStepComplete: PropTypes.bool.isRequired,
  servicesSelectedPriceSum: PropTypes.number.isRequired,
  services: PropTypes.array.isRequired,
}

export default withRouter(withTranslation()(SelectCategory))
