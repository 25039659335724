import React, { Component, Fragment } from 'react'
import { Button, Modal, Select } from 'antd'
import PropTypes from 'prop-types'

import classes from './PartnerChangeContractor.module.scss'

let { Option } = Select

class PartnerChangeContractor extends Component {
  state = {
    modalVisible: false,
  }

  handleModalVisible = visible => {
    this.setState({ modalVisible: visible })
  }

  render() {
    let { modalVisible } = this.state
    let {
      contractorList,
      saveChange,
      jobId,
      selectContractor,
    } = this.props

    return (
      <Fragment>
        {!!contractorList.length && (
          <Button
            onClick={() => this.handleModalVisible(true)}
            size="large"
            className={classes.change_contractor__btn}
          >
            Promeni sastavljača
          </Button>
        )}
        <Modal
          title="Promena sastavljača"
          visible={modalVisible}
          onOk={() => {
            saveChange(jobId)
            this.handleModalVisible(false)
          }}
          okText="Sačuvaj izmenu"
          onCancel={() => this.handleModalVisible(false)}
          cancelText="Odustani"
          closable={false}
        >
          {!!contractorList.length && (
            <Select
              className={classes.change_contractor__select}
              // defaultValue={contractorList[0].id}
              size="large"
              onSelect={value => selectContractor(value, jobId)}
              placeholder="Izaberite sastavljača"
            >
              {contractorList.map(contractor => {
                return (
                  <Option value={contractor.id} key={contractor.id}>
                    <img
                      alt="Sastavljač"
                      src={contractor.image}
                      className={classes.change_contractor__image}
                    />{' '}
                    {contractor.first_name} {contractor.last_name}
                  </Option>
                )
              })}
            </Select>
          )}
        </Modal>
      </Fragment>
    )
  }
}

PartnerChangeContractor.propTypes = {
  contractorList: PropTypes.array.isRequired,
  saveChange: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired,
  selectContractor: PropTypes.func.isRequired,
}

export default PartnerChangeContractor
