import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
// cookie
import { CookiesProvider } from 'react-cookie'
// global scss
// antd css
import 'antd/dist/antd.css'
import 'react-day-picker/lib/style.css'
// import './assets/scss/App.scss'
import './index.scss'
// components
import App from './App'
import ScrollToTop from './helpers/ScrollToTop'
// context
import { UserStore } from './contexts/UserContext'
// i18n
import './configs/i18n'

ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
      <ScrollToTop>
        <UserStore>
          <App />
        </UserStore>
      </ScrollToTop>
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister()
