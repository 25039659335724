import React from 'react'
import { Upload, Modal, Button, message } from 'antd'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import classes from './UploadImageMultiple.module.scss'

const UploadImageMultiple = ({
  fileList,
  handlePreview,
  handleChange,
  previewVisible,
  closeModal,
  previewImage,
  loadingImage,
  t,
  deleteImage,
}) => {
  let dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  let beforeUpload = file => {
    const isJpgOrPng =
      file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(t('component.UploadImage.imageFormatWarning'))
    }
    const isLt2M = file.size / 1024 / 1024 < 10
    if (!isLt2M) {
      message.error(t('component.UploadImage.imageSizeWarning'))
    }
    return isJpgOrPng && isLt2M
  }

  return (
    <div className={classes['Upload-multiple__container']}>
      <Upload
        listType="picture-card"
        prefixCls="UploadImageMultiple"
        showUploadList={{
          showRemoveIcon: false,
        }}
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        multiple
        accept=".jpg, .jpeg, .png"
      >
        <Button size="large" icon="camera" loading={loadingImage}>
          {loadingImage && (
            <span
              className={classes['Upload-multiple__uploadMessage']}
            >
              Postavljanje...
            </span>
          )}
        </Button>
      </Upload>
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={closeModal}
        // closeIcon={<Button icon="close" />}
        // closable={false}
        centered
      >
        <img
          alt="example"
          className={classes['Upload-multiple__previewImage']}
          src={previewImage.url}
        />
        <div className="btn__container">
          <Button
            icon="delete"
            onClick={() => deleteImage(previewImage.id)}
          >
            Izbriši
          </Button>
        </div>
      </Modal>
    </div>
  )
}

UploadImageMultiple.propTypes = {
  fileList: PropTypes.array.isRequired,
  handlePreview: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  previewVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  previewImage: PropTypes.object.isRequired,
  loadingImage: PropTypes.bool,
}

export default withTranslation()(UploadImageMultiple)
