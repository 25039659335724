import React, { Component } from 'react'
import {
  Select,
  message,
  Modal,
  Input,
  Radio,
  Button,
  List,
} from 'antd'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'

import Pagination from '../../../../components/Pagination'
import { apiGet, apiPost } from '../../../../api'
import errorHelper from '../../../../helpers/errorHelper'
import OrderMoreInfo from '../../../../components/OrderMoreInfo'
// import PureList from './PureList'
import EmptyListImage from '../../../../components/EmptyListImage'
import OrderItem from './OrderItem'

import classes from '../../Dashboard.module.scss'

const { Option } = Select

const RadioGroup = Radio.Group

const paymentOptions = [
  { label: 'Gotovina', value: 2 },
  { label: 'Kartica', value: 3 },
]

class OrderTabs extends Component {
  _isMounted = false
  _timer
  state = {
    orders: null,
    loading: false,
    modalPaymentVisible: false,
    modalInfo: {},
    selectedId: null,
    receipt: null,
    pagination: {
      links: {},
      meta: {},
    },
    paymentType: 2,
    orderType: 'unpaid',
    totalOrders: '',
    btnLoader: false,
  }

  componentDidMount() {
    this._isMounted = true
    this.getOrders(this.state.orderType)
    this.handleRefreshingListOnTime(10000)
  }

  componentWillUnmount() {
    this._isMounted = false
    clearTimeout(this._timer)
  }

  getOrders = async (type, page) => {
    this.setState({ loading: true })

    try {
      let res
      if (page) {
        res = await apiGet.getCashierOrders(type, page)
      } else {
        res = await apiGet.getCashierOrders(type)
      }
      let orders = _get(res, 'data', null)
      if (!orders)
        throw new Error(this.props.t('dasboard.orderFetchFailed'))
      if (this._isMounted) {
        this.setState({
          orders: orders.data,
          pagination: {
            meta: orders.meta,
            links: orders.links,
          },
          loading: false,
          totalOrders: orders.meta.total,
        })
      }
    } catch (error) {
      this.setState({ loading: false })
      errorHelper(error, this.props.t('dashboard.orderFetchFailed'))
    }
  }

  refreshOrders = async (type, page) => {
    let { totalOrders } = this.state
    let { t } = this.props

    try {
      let res
      if (page) {
        res = await apiGet.getCashierOrders(type, page)
      } else {
        res = await apiGet.getCashierOrders(type)
      }
      let orders = _get(res, 'data', null)
      if (!orders) throw new Error(t('dasboard.orderFetchFailed'))
      if (this._isMounted) {
        if (totalOrders !== orders.meta.total) {
          message.success('Lista je osvežena!')
          this.setState({
            orders: orders.data,
            pagination: {
              meta: orders.meta,
              links: orders.links,
            },
            loading: false,
            totalOrders: orders.meta.total,
          })
        }
      }
    } catch (error) {
      this.setState({ loading: false })
      errorHelper(error, t('dashboard.orderFetchFailed'))
    }
  }

  postPayment = async () => {
    let { selectedId, receipt, paymentType, orders } = this.state
    let { t } = this.props

    try {
      const res = await apiPost.handleCashierPayment(
        selectedId,
        receipt,
        paymentType
      )
      let order = _get(res, 'data', null)
      if (!order) throw new Error(t('dashboard.paymentFailed'))
      message.success(t('dashboard.paymentSuccess'))
      let newOrdersObject = orders.filter(
        order => order.id !== selectedId
      )
      this.setState(state => ({
        orders: newOrdersObject,
        receipt: null,
        selectedId: null,
        totalOrders: state.totalOrders - 1,
      }))
    } catch (error) {
      this.setState({
        receipt: null,
        selectedId: null,
      })
      errorHelper(error, t('dashboard.paymentFailed'))
    }
  }

  handlePayment = () => {
    this.postPayment()
    this.setState({
      modalPaymentVisible: false,
    })
  }

  handlePaymentModal = id =>
    this.setState({ selectedId: id, modalPaymentVisible: true })

  handleCancelPayment = () =>
    this.setState({
      modalPaymentVisible: false,
      receipt: null,
      selectedId: null,
    })

  handleReceipt = e => this.setState({ receipt: e.target.value })

  viewMoreInfo = id => {
    let order = this.state.orders.find(order => order.id === id)

    this.setState({
      modalInfo: {
        order,
        visible: true,
      },
    })
  }

  closeInfoModal = () =>
    this.setState({
      modalInfo: { ...this.state.modalInfo, visible: false },
    })

  handlePageChange = page =>
    this.getOrders(this.state.orderType, page)

  handlePaymentType = e =>
    this.setState({ paymentType: e.target.value })

  handleFilterOrderType = type => {
    this.setState({ orderType: type })
    this.getOrders(type)
  }

  handleRefreshingListOnTime = time => {
    this._timer = setInterval(() => {
      this.refreshOrders(this.state.orderType)
    }, time)
  }

  fetchMoreOrders = async page => {
    this.setState({ btnLoader: true })

    try {
      let res = await apiGet.getCashierOrders('unpaid', page)

      let orders = _get(res, 'data', null)
      if (!orders)
        throw new Error(this.props.t('dasboard.orderFetchFailed'))
      if (this._isMounted) {
        let onlyData = orders.data
        this.setState(state => ({
          orders: [...state.orders, ...onlyData],
          pagination: {
            meta: orders.meta,
            links: orders.links,
          },
          btnLoader: false,
          totalOrders: orders.meta.total,
        }))
      }
    } catch (error) {
      this.setState({ loading: false })
      errorHelper(error, this.props.t('dashboard.orderFetchFailed'))
    }
  }

  render() {
    let {
      orders,
      loading,
      modalPaymentVisible,
      receipt,
      paymentType,
      pagination,
      orderType,
      modalInfo,
      selectedId,
    } = this.state

    let isFetchButtonVisible =
      orders && orderType === 'unpaid' && pagination.links.next

    return (
      <div>
        <div className={classes.dashboard__titleContainer}>
          <h3 className={classes.dashboard__name}>Narudžbine</h3>
          <Select
            defaultValue="unpaid"
            className={classes.dashboard__select}
            onChange={this.handleFilterOrderType}
          >
            <Option value="unpaid">Neplaćeno</Option>
            <Option value="expired">Isteklo</Option>
            <Option value="paid">Plaćeno</Option>
            <Option value="">Svi</Option>
          </Select>
        </div>

        {orders && (
          <List
            locale={EmptyListImage}
            loading={loading}
            itemLayout="horizontal"
            dataSource={orders}
            renderItem={order => (
              <OrderItem
                loading={loading}
                order={order}
                viewMoreInfo={this.viewMoreInfo}
                handlePaymentModal={this.handlePaymentModal}
                cancelOrder={this.cancelOrder}
                orderType={orderType}
              />
            )}
          />
        )}

        {modalInfo.visible && (
          <OrderMoreInfo
            modalInfo={modalInfo}
            closeInfoModal={this.closeInfoModal}
          />
        )}

        <Modal
          title={`Plati porudžbinu - ${selectedId}`}
          visible={modalPaymentVisible}
          okText="Plati"
          cancelText="Odustani"
          onOk={this.handlePayment}
          onCancel={this.handleCancelPayment}
          okButtonProps={{
            disabled: !receipt,
          }}
        >
          <div>
            <h4 className={classes.dashboard__modalHeader}>
              Broj fiskalnog isečka
            </h4>
            <Input
              placeholder="Broj"
              onChange={this.handleReceipt}
              value={receipt}
              style={{
                marginBottom: 8,
              }}
            />
          </div>
          <div>
            <h4 className={classes.dashboard__modalHeader}>
              Način plaćanja
            </h4>
            <RadioGroup
              options={paymentOptions}
              defaultValue={paymentType}
              onChange={this.handlePaymentType}
            />
          </div>
        </Modal>

        {orders && orderType !== 'unpaid' && (
          <Pagination
            currentPage={this.state.pagination.meta.current_page}
            ordersLength={orders.length}
            metaTotal={pagination.meta.total}
            metaPerPage={pagination.meta.per_page}
            handlePageChange={this.handlePageChange}
          />
        )}

        {isFetchButtonVisible && (
          <div className={classes.dashboard__centerBtn}>
            <Button
              loading={this.state.btnLoader}
              size="large"
              onClick={() =>
                this.fetchMoreOrders(
                  this.state.pagination.meta.current_page + 1
                )
              }
            >
              Učitaj još
            </Button>
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation()(OrderTabs)
