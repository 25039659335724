import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

import classes from '../Dashboard.module.scss'

let BoothmanPanel = () => {
  return (
    <Link to="/order-service">
      <Button
        type="primary"
        size="large"
        className={classes.dashboard__boothmanBtn}
      >
        Poruči uslugu
      </Button>
    </Link>
  )
}

export default BoothmanPanel
