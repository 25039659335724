import React from 'react'
import { List, Button, Skeleton } from 'antd'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import moneyFormat from '../../../../helpers/moneyFormatter'
import HorizontalLine from '../../../../components/HorizontalLine'
// import ProductImage from '../../../../components/ProductImage'

import classes from '../../Dashboard.module.scss'

const OrderItem = ({
  order,
  loading,
  viewMoreInfo,
  handlePaymentModal,
  orderType,
  t,
}) => {
  let renderActions = type => {
    switch (type) {
      case 'unpaid':
        return [
          <Button
            key="pay"
            size="large"
            type="primary"
            onClick={() => handlePaymentModal(order.id)}
            className={classes.dashboard__btn}
          >
            {t('global.pay')}
          </Button>,
          <Button
            key="view-unpaid"
            className={classes.dashboard__btn}
            size="large"
            icon="eye"
            onClick={() => viewMoreInfo(order.id)}
          />,
        ]
      case 'paid':
      case 'expired':
        return [
          <Button
            key="view-expired"
            size="large"
            icon="eye"
            onClick={() => viewMoreInfo(order.id)}
          />,
        ]
      default:
        return []
    }
  }

  let renderTitle = type => {
    switch (type) {
      case 'unpaid': {
        return (
          <p className={classes.dashboard__clientName}>
            {order.id} - {order.contact.first_name}{' '}
            {order.contact.last_name}
          </p>
        )
      }
      case 'paid':
      case 'expired': {
        return (
          <p className={classes.dashboard__clientName}>
            {order.contact.first_name} {order.contact.last_name}
          </p>
        )
      }
      default: {
        return (
          <p className={classes.dashboard__clientName}>
            {order.contact.first_name} {order.contact.last_name}
          </p>
        )
      }
    }
  }

  return (
    <List.Item
      className={['section', classes.dashboard__cashierItem]}
    >
      <Skeleton title={false} loading={loading} active>
        {renderTitle(orderType)}
        <ul className={classes.dashboard__itemList}>
          {order.items.map(item => (
            <li key={item.id} className={classes.dashboard__item}>
              {/* <ProductImage src={item.product.image} border /> */}
              <p className={classes.dashboard__productName}>
                {item.category.name} ({item.quantity} kom.)
              </p>
            </li>
          ))}
        </ul>
        <HorizontalLine margin={16} />
        <div className={classes.dashboard__itemFooter}>
          <span className={classes.dashboard__price}>
            {moneyFormat(order.price)}
          </span>
          <div className={classes.dashboard__actions}>
            {renderActions(orderType)}
          </div>
        </div>
      </Skeleton>
    </List.Item>
  )
}

OrderItem.propTypes = {
  order: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  viewMoreInfo: PropTypes.func,
  handlePaymentModal: PropTypes.func,
  orderType: PropTypes.string,
}

export default withTranslation()(OrderItem)
