import React from 'react'
import { Input, Button, Form } from 'antd'
import Section from '../OrderSections'
import PropTypes from 'prop-types'

import classes from './UserInfoForm.module.scss'

const UserInfoFormShort = props => {
  const saveChanges = e => {
    e.preventDefault()
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        props.handleSave()
      }
    })
  }

  const { getFieldDecorator } = props.form

  const { user, handleUserInputs } = props

  return (
    <Section>
      <div className={classes['short-info-form__container']}>
        <Form onSubmit={saveChanges}>
          <Form.Item label="Ime">
            {getFieldDecorator('ime', {
              initialValue: user.name,
              rules: [
                {},
                {
                  required: true,
                  message: 'Molimo unesite svoje ime',
                },
              ],
            })(
              <Input
                size="large"
                style={{
                  marginBottom: 18,
                }}
                onChange={e =>
                  handleUserInputs('name', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Email">
            {getFieldDecorator('email', {
              initialValue: user.email,
              rules: [
                {},
                {
                  required: false,
                  message: 'Molimo unesite svoju email adresu',
                },
              ],
            })(
              <Input
                size="large"
                disabled
                style={{
                  marginBottom: 18,
                }}
                type="email"
              />
            )}
          </Form.Item>

          <Form.Item>
            <div className="btn__container">
              <Button type="primary" htmlType="submit" size="large">
                Snimi
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Section>
  )
}

UserInfoFormShort.propTypes = {
  user: PropTypes.object,
  handleUserInputs: PropTypes.func,
  enableEditMode: PropTypes.func,
  editEnabled: PropTypes.bool,
}

const WrappedUserFormShort = Form.create({ name: 'user info short' })(
  UserInfoFormShort
)

export default WrappedUserFormShort
