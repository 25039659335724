import React, { Component } from 'react'
import { Upload, message, Avatar, Icon } from 'antd'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'

import { apiPost } from '../../api'
import errorHelper from '../../helpers/errorHelper'
import getBase64 from '../../helpers/getBase64'

import classes from './UploadImage.module.scss'

class UploadImage extends Component {
  state = {
    loading: false,
    avatarUrl: null,
  }

  componentDidMount() {
    this.setState({ avatarUrl: this.props.imageUrl })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.setState({ avatarUrl: this.props.imageUrl })
    }
  }

  handlePostAvatar = async image => {
    let { userType, userId, t } = this.props

    this.setState({ loading: true, avatarUrl: false })

    // console.log('image', image)

    var formData = new FormData()
    formData.append('avatar', image)

    try {
      let res
      if (userId) {
        res = await apiPost.handleContractorAvatarWithId(
          formData,
          userId
        )
      }
      if (userType === 'contractor') {
        res = await apiPost.handleContractorAvatar(formData)
      }
      if (userType === 'partner' && !userId) {
        res = await apiPost.handlePartnerAvatar(formData)
      }

      let avatar = _get(res, 'data', null)

      if (!avatar)
        throw new Error(
          t('component.UploadImage.failedToUploadAvatar')
        )

      this.setState(
        { loading: false, avatarUrl: avatar.avatar },
        () => message.success(avatar.message)
      )
    } catch (error) {
      this.setState({ loading: false })
      errorHelper(
        error,
        t('component.UploadImage.failedToUploadAvatar')
      )
    }
  }

  beforeUpload = file => {
    const isJpgOrPng =
      file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(
        this.props.t('component.UploadImage.imageFormatWarning')
      )
    }
    const isLt2M = file.size / 1024 / 1024 < 10
    if (!isLt2M) {
      message.error(
        this.props.t('component.UploadImage.imageSizeWarning')
      )
    }
    return isJpgOrPng && isLt2M
  }

  handleChange = info => {
    let { status, originFileObj } = info.file

    if (status === 'uploading') {
      this.setState({ loading: true })
      return
    }
    if (status === 'done') {
      getBase64(originFileObj, imageUrl =>
        this.setState(
          {
            avatarUrl: imageUrl,
            loading: false,
          },
          () => this.handlePostAvatar(originFileObj)
        )
      )
    }
  }

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  render() {
    let { avatarUrl, loading } = this.state

    return (
      <div>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={this.dummyRequest}
          beforeUpload={this.beforeUpload}
          onChange={this.handleChange}
          accept=".jpg, .jpeg, .png"
        >
          {avatarUrl && (
            <div
              alt="avatar"
              className={classes.upload__image}
              style={{
                backgroundImage: `url(${avatarUrl})`,
              }}
            />
          )}

          {!avatarUrl && !loading && (
            <Avatar size={80} icon="user" shape="circle" />
          )}

          {loading && <Icon type="loading" />}
        </Upload>
      </div>
    )
  }
}

UploadImage.propTypes = {
  // userId: PropTypes.number,
  userType: PropTypes.string,
  imageUrl: PropTypes.string,
}

export default withTranslation()(UploadImage)
