import React, { Component, Fragment } from 'react'
import { withCookies, Cookies } from 'react-cookie'
import { instanceOf } from 'prop-types'
import { withRouter } from 'react-router'
import _get from 'lodash/get'
import axios from 'axios'
// utils
import setAuthorizationToken from './utils/setAuthorizationToken'
import Routing from './Routing'
import { apiGet } from './api'
import { WithUserContext } from './contexts/UserContext'
import { OrderStore } from './contexts/OrderContext'
import errorHelper from './helpers/errorHelper'
import { withTranslation } from 'react-i18next'
import logOutGlobally from './utils/logOut'
// components
import Navigation from './components/Navigation'
// import ContractorStartScreen from './components/ContractorStartScreen'
import SideNav from './components/SideNav'
import Footer from './components/Footer'
import Loader from './components/Loader'

axios.interceptors.request.use(request => {
  request.headers.common['Accept-Language'] = 'sr'
  return request
})

class App extends Component {
  state = {
    sideDrawerOpen: false,
    user: {},
    loaded: false,
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }

  componentDidMount() {
    const { cookies } = this.props.cookies

    if (cookies.homea) {
      setAuthorizationToken(cookies.homea)
      this.getUser()
    }

    axios.interceptors.response.use(
      response => response,
      error => {
        const { status } = error.response
        if (status === 401) {
          this.props.cookies.remove('homea')
          setAuthorizationToken(false)
          this.props.user.onDeleteUser()
        }
        return Promise.reject(error)
      }
    )

    this.setState({ loaded: true })
  }

  getUser = async () => {
    let { user, t } = this.props

    try {
      const res = await apiGet.getUser()

      let userFirstName = _get(res, 'data.first_name', null)
      let userLastName = _get(res, 'data.last_name', null)
      let userEmail = _get(res, 'data.email', null)
      let userType = _get(res, 'data.user_type', null)
      let userTypeId = _get(res, 'data.user_type_id', null)
      let userName = _get(res, 'data.name', null)

      if (!userEmail) throw new Error(t('auth.failedToGetUser'))
      user.onSetUser(
        userFirstName,
        userLastName,
        userEmail,
        true,
        userType,
        userTypeId,
        userName
      )
    } catch (error) {
      errorHelper(error, t('auth.failedToGetUser'))
      this.props.cookies.remove('homea', { path: '/' })
      setAuthorizationToken(false)
      user.onDeleteUser()
    }
  }

  logOut = () => {
    let { user, t, cookies } = this.props

    logOutGlobally(cookies, user, t)
  }

  handleSideNav = () => {
    this.setState(state => {
      return { sideDrawerOpen: !state.sideDrawerOpen }
    })
  }

  handleShowSidenav = () => {
    this.setState({ sideDrawerOpen: true })
  }

  handleCloseSidenav = () => {
    this.setState({ sideDrawerOpen: false })
  }

  render() {
    let { sideDrawerOpen, loaded } = this.state
    let { user, cookies, history } = this.props

    return (
      <div className="wrapper">
        {loaded ? (
          <Fragment>
            <Navigation
              openSideNav={this.handleSideNav}
              history={history}
            />
            <div className="content container">
              <OrderStore>
                <Routing
                  isAuthenticated={!!cookies.cookies.homea}
                  getUser={this.getUser}
                  user={user.user}
                  history={history}
                />
              </OrderStore>
            </div>
            <SideNav
              onClose={this.handleCloseSidenav}
              visible={sideDrawerOpen}
              userLoggedIn={!!cookies.cookies.homea}
              logOut={this.logOut}
              user={user.user}
            />
            <Footer />
          </Fragment>
        ) : (
          <Loader />
        )}
        {/*{user.user.loggedIn &&
          user.user.user_type === 'contractor' && (
            <ContractorStartScreen />
          )}
        */}
      </div>
    )
  }
}

export default withCookies(
  WithUserContext(withRouter(withTranslation()(App)))
)
