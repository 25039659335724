import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { message } from 'antd'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import scroll from 'react-scroll'
import { withTranslation } from 'react-i18next'

import Services from './Services'
import TabName from '../../../components/TabName'
import Section from '../../../components/OrderSections'
import { apiPost } from '../../../api'
import SectionAnimated from '../../../components/SectionAnimated'
import errorHelper from '../../../helpers/errorHelper'
import scrollToElement from '../../../helpers/scrollToElement'

const { Element } = scroll

class SelectService extends Component {
  state = {
    active: false,
    availableTime: true,
  }

  componentDidMount() {
    this.setTabActive(true)
  }

  handleSelectedService = async (value, time, price) => {
    try {
      const res = await apiPost.getServiceData(value)
      let serviceSelected = _get(res, 'data', null)
      if (!serviceSelected)
        message.error(
          this.props.t('orderService.failedToFetchService')
        )
      this.props.addService(serviceSelected, time, price)
    } catch (error) {
      errorHelper(
        error,
        this.props.t('orderService.failedToFetchService')
      )
    }
  }

  setTabActive = status => {
    this.props.changeTabStatus('SelectService', status)
    this.setState({ active: status })
  }

  handleOpenSection = () => {
    if (!this.props.activeTabs.SelectService) {
      this.setTabActive(true)
    }
  }

  handleCleanState = () => {
    this.props.handleServiceSelectComplete()
    this.setTabActive(false)
    if (this.props.secondStepComplete) {
      this.props.changeTabStatus('CalendarAndTime', true)
    }
  }

  handleScroll = () => {
    if (this.props.activeTabs.SelectService) {
      scrollToElement('select-service', -150)
    }
  }

  render() {
    // this.handleScroll()

    const { active } = this.state
    const {
      selectedService,
      serviceExist,
      servicesSelected,
      deleteService,
      changeQuantity,
      initialService,
      calculateAvailableTime,
      availableTime,
      servicesSelectedTimeSum,
      servicesSelectedPriceSum,
      activeTabs,
    } = this.props

    let serviceSummary
    let serviceId

    if (selectedService) {
      serviceSummary = Object.keys(selectedService).map(key => {
        return selectedService[key].values.filter(v => {
          return (
            v.type === 'option' ||
            v.type === 'radio' ||
            v.type === 'checkbox'
          )
        })
      })
      serviceId = Object.keys(selectedService).map(key => key)
    }

    let activeSection =
      this.props.activeTabs.SelectService || _isEmpty(selectedService)

    return (
      <Section>
        <Element name="select-service" />
        {initialService && (
          <TabName
            section="select-service"
            text="TIP NAMEŠTAJA"
            handleOpenSection={e => this.handleOpenSection(e)}
            checked={servicesSelected}
            summary={serviceSummary}
            displaySummary={serviceExist}
            deleteService={deleteService}
            serviceId={serviceId}
            changeQuant={changeQuantity}
            options={active}
            availableTime={availableTime}
            calculateAvailableTime={calculateAvailableTime}
            serviceQuantity={selectedService}
            timeSumm={servicesSelectedTimeSum}
            selectServiceActive={activeSection}
            activeTabs={activeTabs}
            priceSumm={servicesSelectedPriceSum}
          />
        )}
        <SectionAnimated active={activeSection}>
          <Services
            handleSelectedService={this.handleSelectedService}
            handleCleanStateFake={this.handleCleanState}
            selectedService={selectedService}
            editForServices={this.editForServices}
            setMaxForInputs={this.setMaxForInputs}
            timeSumm={servicesSelectedTimeSum}
            priceSumm={servicesSelectedPriceSum}
          />
        </SectionAnimated>
      </Section>
    )
  }
}

SelectService.propTypes = {
  initialService: PropTypes.object,
  addService: PropTypes.func.isRequired,
  serviceExist: PropTypes.bool.isRequired,
  servicesSelected: PropTypes.bool.isRequired,
  selectedService: PropTypes.object.isRequired,
  handleServiceSelectComplete: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  changeQuantity: PropTypes.func.isRequired,
  servicesSelectedTimeSum: PropTypes.number,
  calculateAvailableTime: PropTypes.func.isRequired,
  availableTime: PropTypes.bool.isRequired,
}

export default withRouter(withTranslation()(SelectService))
