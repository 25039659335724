import React, { Component } from 'react'
import { Input, Button, Form, Select, Checkbox } from 'antd'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'

import { apiGet } from '../../api'
import errorHelper from '../../helpers/errorHelper'

import classes from './UserInfoForm.module.scss'
let { Option } = Select

class UserInfoForm extends Component {
  state = {
    selectedCity: null,
    municipality: {},
  }

  componentDidMount() {
    this.getCity(1)
  }

  getCity = async id => {
    try {
      const res = await apiGet.getCityById(id)
      let city = _get(res, 'data', null)
      if (!city) {
        throw new Error(
          this.props.t('orderService.failedToFetchCities')
        )
      }
      this.setState({ selectedCity: city })
    } catch (error) {
      errorHelper(
        error,
        this.props.t('orderService.failedToFetchCities')
      )
    }
  }

  saveChanges = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.handleSave()
      }
    })
  }

  selectMunicipality = (value, name) => {
    this.setState({
      municipality: {
        id: value,
        name,
      },
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { user, handleUserInputs } = this.props

    return (
      <div
        className={[classes.infoForm__container, 'section'].join(' ')}
      >
        <Form onSubmit={this.saveChanges}>
          <Form.Item label="Ime">
            {getFieldDecorator('ime', {
              initialValue: user.first_name,
              rules: [
                {},
                {
                  required: true,
                  message: 'Molimo unesite svoje ime',
                },
              ],
            })(
              <Input
                size="large"
                onChange={e =>
                  handleUserInputs('first_name', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Prezime">
            {getFieldDecorator('prezime', {
              initialValue: user.last_name,
              rules: [
                {},
                {
                  required: true,
                  message: 'Molimo unesite svoje prezime',
                },
              ],
            })(
              <Input
                size="large"
                onChange={e =>
                  handleUserInputs('last_name', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Broj telefona">
            {getFieldDecorator('broj telefona', {
              initialValue: user.phone,
              rules: [
                {},
                {
                  required: true,
                  message: 'Molimo unesite svoj broj telefona',
                },
              ],
            })(
              <Input
                size="large"
                type="tel"
                onChange={e =>
                  handleUserInputs('phone', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Dodatni broj telefona">
            {getFieldDecorator('dodatni broj telefona', {
              initialValue: user.phone_additional,
              rules: [
                {},
                {
                  message: 'Molimo unesite svoj broj telefona',
                },
              ],
            })(
              <Input
                size="large"
                type="tel"
                onChange={e =>
                  handleUserInputs('phone_additional', e.target.value)
                }
              />
            )}
          </Form.Item>

          {!this.props.clientRole && (
            <Form.Item label="Adresa">
              {getFieldDecorator('adresa', {
                initialValue: user.address,
                rules: [
                  {},
                  {
                    required: false,
                    message: 'Molimo unesite svoju adresu stanovanja',
                  },
                ],
              })(
                <Input
                  size="large"
                  onChange={e =>
                    handleUserInputs('address', e.target.value)
                  }
                />
              )}
            </Form.Item>
          )}

          {this.props.clientRole && (
            <React.Fragment>
              <Form.Item label="Grad">
                {getFieldDecorator('city', {
                  initialValue: 'Beograd',
                  rules: [
                    {
                      required: false,
                      message: 'Unesite ime grada!',
                    },
                  ],
                })(
                  <Select
                    id="city"
                    size="large"
                    onChange={e =>
                      handleUserInputs('city', e.target.value)
                    }
                  >
                    <Option value="1">Beograd</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Opština">
                {getFieldDecorator('municipality', {
                  initialValue:
                    user.municipality && user.municipality.name,
                  rules: [
                    {
                      required: false,
                      message: 'Unesite ime opštine!',
                    },
                  ],
                })(
                  <Select
                    onChange={(value, e) =>
                      this.props.selectMunicipality(
                        value,
                        e.props.name
                      )
                    }
                    id="municipality"
                    size="large"
                  >
                    {this.state.selectedCity &&
                      this.state.selectedCity.municipalities.map(
                        c => {
                          return (
                            <Option
                              key={c.id}
                              value={c.id}
                              name={c.name}
                            >
                              {c.name}
                            </Option>
                          )
                        }
                      )}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Ulica">
                {getFieldDecorator('street', {
                  initialValue: user.street,
                  rules: [
                    {
                      required: false,
                      message: 'Unesite ime ulice!',
                    },
                  ],
                })(
                  <Input
                    type="text"
                    onChange={e =>
                      handleUserInputs('street', e.target.value)
                    }
                    id="street"
                    name="street"
                    size="large"
                  />
                )}
              </Form.Item>
              <div className={classes.infoForm__address}>
                <Form.Item className="grid__30" label="Broj">
                  {getFieldDecorator('street_number', {
                    initialValue: user.street_number,
                    rules: [
                      {
                        required: false,
                        message: 'Upišite broj stana!',
                      },
                    ],
                  })(
                    <Input
                      style={{ width: '100%' }}
                      id="street_num"
                      name="street_num"
                      onChange={e =>
                        handleUserInputs(
                          'street_number',
                          e.target.value
                        )
                      }
                      size="large"
                    />
                  )}
                </Form.Item>
                <Form.Item className="grid__30" label="Sprat">
                  {getFieldDecorator('floor', {
                    initialValue: user.floor,
                    rules: [
                      {
                        required: false,
                        message: 'Upišite sprat!',
                      },
                    ],
                    // initialValue: this.state.floor,
                  })(
                    <Input
                      style={{ width: '100%' }}
                      min={0}
                      onChange={e =>
                        handleUserInputs('floor', e.target.value)
                      }
                      id="floor"
                      name="floor"
                      type="number"
                      size="large"
                    />
                  )}
                </Form.Item>
                <Form.Item className="grid__30" label="Stan">
                  {getFieldDecorator('appartment', {
                    initialValue: user.apartment,
                    rules: [
                      {
                        required: false,
                        message: 'Upišite broj stana!',
                      },
                    ],
                  })(
                    <Input
                      className="grid__100"
                      id="appartment"
                      onChange={e =>
                        handleUserInputs('apartment', e.target.value)
                      }
                      name="appartment"
                      size="large"
                    />
                  )}
                </Form.Item>
              </div>
              <Form.Item>
                {getFieldDecorator('parking', {
                  initialValue: user.has_parking,
                })(
                  <Checkbox
                    id="parking"
                    checked={user.has_parking}
                    onChange={e =>
                      handleUserInputs(
                        'has_parking',
                        e.target.checked
                      )
                    }
                    name="parking"
                  >
                    <label className="label" htmlFor="parking">
                      Parking mesto
                    </label>
                  </Checkbox>
                )}
              </Form.Item>
            </React.Fragment>
          )}

          <Form.Item label="Email">
            {getFieldDecorator('email', {
              initialValue: user.email,
              rules: [
                {},
                {
                  required: false,
                  message: 'Molimo unesite svoju email adresu',
                },
              ],
            })(<Input size="large" disabled type="email" />)}
          </Form.Item>

          <Form.Item>
            <div className="btn__container">
              <Button type="primary" htmlType="submit" size="large">
                Snimi
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

UserInfoForm.propTypes = {
  user: PropTypes.object,
  handleUserInputs: PropTypes.func,
}

const WrappedUserForm = Form.create({ name: 'user info' })(
  UserInfoForm
)

export default withTranslation()(WrappedUserForm)
