import React from 'react'
import PropTypes from 'prop-types'

import timeFormat from '../../../helpers/timeFormat'
import moneyFormat from '../../../helpers/moneyFormatter'

import classes from './SelectProduct.module.scss'

const CategorySummary = ({ time, price, closed }) => {
  let summaryClass = closed
    ? [
        classes.service__summary,
        classes['service__summary--summaryClosed'],
      ].join(' ')
    : classes.service__summary

  let isSummaryVisible = time > 0 && price > 0

  return (
    <div className={summaryClass}>
      {isSummaryVisible && (
        <p className={classes.service__summaryItem}>
          Potrebno vreme: {timeFormat(time)}
          <br />
          Ukupna cena: {moneyFormat(price)}
        </p>
      )}
    </div>
  )
}

CategorySummary.propTypes = {
  time: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  closed: PropTypes.bool,
}

export default CategorySummary
