import React, { Fragment } from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

import classes from './Landing.module.scss'

const Hero = ({ userType }) => {
  return (
    <Fragment>
      <div className={classes.hero__wrapper}>
        <h1 className={classes.hero__heading}>
          Sastavljanje Ikea nameštaja po povoljnoj ceni.
        </h1>
      </div>
      <div className={classes.hero__box}>
        <h2 className={classes.hero__subheading}>
          Sastavljanje nameštaja
        </h2>
        <p className={classes.hero__text}>
          Naši obučeni sastavljači dolaze na tvoju adresu i sklapaju
          Ikea nameštaj profesionalno, brzo i kvalitetno.
        </p>

        <div className={classes.hero__buttonContainer}>
          <Link to="/order-service">
            <Button
              type="primary"
              size="large"
              className={classes.hero__button}
            >
              Zakaži majstora
            </Button>
          </Link>
        </div>
      </div>
    </Fragment>
  )
}

export default Hero
