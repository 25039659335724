import moment from 'moment'

/**
 * Funkcija se koristi kada se dobija data iz response-a pri placanju.
 * Uspesno placanje, neuspesno placanje.
 * @param {Object} order - Objekat dobijen iz response-a.
 */
const normalizeData = (order = {}) => {
  let user = {
    first_name: order.contact.first_name,
    last_name: order.contact.last_name,
    municipality: order.address.municipality,
    address: `${order.address.street} ${order.address.street_number}`,
    orderDate: moment(order.scheduled_at).format('DD.MM.YYYY'),
    time: moment(order.scheduled_at).format('H:mm'),
  }

  let service = order.items.map(item => {
    return {
      priceSingle: item.price,
      priceSum: item.price,
      name: item.category.name,
      quantity: item.quantity,
      time: item.duration,
    }
  })

  let details = {
    id: order.id,
    orderNumber: order.order_number,
  }

  return { user, service, details }
}

export default normalizeData
