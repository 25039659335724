import React from 'react'

import maestrocard from '../../assets/images/payment/maestroCard.png'
import mastercard from '../../assets/images/payment/masterCard.png'
import mastercardsecurecode from '../../assets/images/payment/masterCardSecureCode.png'
import verifiedvisa from '../../assets/images/verifiedvisa-logo.png'
import visa from '../../assets/images/payment/visa.png'
import chipCard from '../../assets/images/payment/chipCard.png'

import classes from './PaymentBranding.module.scss'

const PaymentBranding = () => {
  return (
    <div className={classes.paymentIcons__container}>
      {/* LEFT SIDE */}
      {/* <div className={classes.paymentIcons__left}>
        <a
          href="https://ipay.rs"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={ipay}
            alt="iPay"
            className={[
              classes.paymentIcons,
              classes.paymentIcons__ipay,
            ].join(' ')}
          />
        </a>
      </div> */}
      {/* CENTER */}
      <div className={classes.paymentIcons__left}>
        <a
          href="http://www.e-services.rs/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={chipCard}
            alt="Chip Card"
            className={[
              classes.paymentIcons,
              classes.paymentIcons__chipCard,
            ].join(' ')}
          />
        </a>
        <img
          src={visa}
          alt="Visa"
          className={[
            classes.paymentIcons,
            classes.paymentIcons__visa,
          ].join(' ')}
        />
        <img
          src={mastercard}
          alt="Master"
          className={[
            classes.paymentIcons,
            classes.paymentIcons__master,
          ].join(' ')}
        />
        <img
          src={maestrocard}
          alt="Maestro"
          className={[
            classes.paymentIcons,
            classes.paymentIcons__maestro,
          ].join(' ')}
        />
      </div>
      {/* RIGHT SIDE */}
      <div className={classes.paymentIcons__right}>
        <a
          href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={verifiedvisa}
            alt="Visa verified"
            className={[
              classes.paymentIcons,
              classes.paymentIcons__visaverified,
            ].join(' ')}
          />
        </a>
        <a
          href="http://www.mastercard.com/rs/consumer/credit-cards.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={mastercardsecurecode}
            alt="Master secure code"
            className={[
              classes.paymentIcons,
              classes.paymentIcons__mastercardsecurecode,
            ].join(' ')}
          />
        </a>
      </div>
    </div>
  )
}

export default PaymentBranding
