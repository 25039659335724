import React from 'react'
import PropTypes from 'prop-types'

import ClientNote from '../ClientNote'

import classes from './ClientInfo.module.scss'

let ClientInfo = ({
  firstName,
  lastName,
  email,
  phone,
  phoneAdditional,
  hasParking,
  note,
  style,
  size,
}) => {
  return (
    <div
      className={classes.ClientInfo}
      style={{ ...style, width: size === 'large' ? '100%' : null }}
    >
      <p style={{ marginBottom: 0 }}>
        Ime: {firstName}
        <br />
        Prezime: {lastName}
        <br />
        Email: <a href={`mailto: ${email}`}>{email}</a>
        <br />
        Telefon: <a href={`tel:${phone}`}>{phone}</a>
        <br />
        Dodatni Telefon:{' '}
        <a href={`tel:${phoneAdditional}`}>{phoneAdditional}</a>
        <br />
        Parking: {hasParking ? 'Da' : 'Ne'}
        <br />
        {note && 'Napomena:'}
      </p>
      <ClientNote
        text={note}
        // style={{
        //   marginRight: size === 'large' ? '0px' : '8px ',
        // }}
      />
    </div>
  )
}

ClientInfo.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  phone_additional: PropTypes.string,
  note: PropTypes.string,
  has_parking: PropTypes.bool,
  style: PropTypes.object,
  size: PropTypes.string,
}

export default ClientInfo
