import React, { Component, Fragment } from 'react'
import { Button } from 'antd'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

// import shortid from 'shortid'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import SelectCategory from './SelectCategory'
import CalendarAndTime from './CalendarAndTime'
import WrappedAddressAndContact from './AddressAndContact/Index'
import { apiPost } from '../../api'
import ServiceInfo from '../../components/ServiceInfo'
import SelectContractor from './SelectContractor'
import { WithOrderContext } from '../../contexts/OrderContext'
import JobOverview from '../JobOverview'
import Loader from '../../components/Loader'
import errorHelper from '../../helpers/errorHelper'
import setDocumentTitle from '../../helpers/setDocumentTitle'

class OrderService extends Component {
  state = {
    service: [],
    initialServiceId: null,
    initialService: null,
    servicesSelected: false,
    servicesSelectedTimeSum: 0,
    servicesSelectedPriceSum: 0,
    date: '',
    time: '',
    user: {},
    dateTimeValid: false,
    city: 1,
    addressAndContactValid: false,
    dateTimeStamp: '',
    contractorId: null,
    contractorList: [],
    ContractorPaginationData: {
      links: [],
      meta: [],
    },
    availableTime: true,
    currentLocation: null,
    loaded: false,
    // ovaj state odlucuje da li je moguce otici na /job-overview
    allowJobOverview: false,
    // works as charm
    activeTabs: {
      SelectService: false,
      AddressAndContact: false,
      CalendarAndTime: false,
      SelectContractor: false,
    },
  }

  componentDidMount() {
    // console.log(process.env)

    setDocumentTitle(this.props.t('page.title.orderService'))
    // hardcoded 1
    // sastavljanje namestaja
    // this.setState({ initialServiceId: 1 }, () =>
    //   this.getInitialServiceInfo(this.state.initialServiceId)
    // )

    // Reset job overview page
    this.setState({ allowJobOverview: true })
    let currentLocation = this.props.history.location.pathname.slice(
      1
    )
    this.setState({ currentLocation })
    this.setState({ loaded: true })

    window.addEventListener('beforeunload', this.onUnload)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  componentDidUpdate(props) {
    if (
      props.history.location.pathname.slice(1) !==
      this.state.currentLocation
    ) {
      let currentLocation = this.props.history.location.pathname.slice(
        1
      )
      this.setState({ currentLocation })
    }
  }

  // Not in use, hardcoded
  // getInitialServiceInfo = async id => {
  //   try {
  //     const res = await apiGet.getServiceList()
  //     let services = _get(res, 'data', null)
  //     if (!services)
  //       throw new Error(
  //         this.props.t('orderService.failedToFetchService')
  //       )
  //     let initialySelectedService = services.filter(s => {
  //       return s.id === id
  //     })
  //     this.setState({ initialService: initialySelectedService[0] })
  //     this.setState({ initialService: true })
  //   } catch (error) {
  //     errorHelper(
  //       error,
  //       this.props.t('orderService.failedToFetchService')
  //     )
  //   }
  // }

  getContractorList = async (
    municipality,
    date,
    time,
    duration,
    page
  ) => {
    try {
      let res
      if (page) {
        res = await apiPost.getAvailableContractors(
          municipality,
          date,
          time,
          duration,
          page
        )
      } else {
        res = await apiPost.getAvailableContractors(
          municipality,
          date,
          time,
          duration
        )
      }

      let contractors = _get(res, 'data', null)
      if (!contractors)
        throw new Error(
          this.props.t('orderService.failedToFetchContractors')
        )
      this.setState({
        contractorList: contractors.data,
        ContractorPaginationData: {
          links: contractors.links,
          meta: contractors.meta,
        },
      })
    } catch (error) {
      errorHelper(
        error,
        this.props.t('orderService.failedToFetchContractors')
      )
    }
  }

  // createOrder = () => {
  //   const {
  //     service,
  //     city,
  //     dateTimeStamp,
  //     user,
  //     date,
  //     time,
  //   } = this.state

  //   // console.log(this.props)

  //   let serviceItemsIDs = Object.keys(service).map((key, value) => {
  //     return {
  //       services: service[key].values.map(i => i.id),
  //       quantity: service[key].quantity ? service[key].quantity : 1,
  //     }
  //   })
  //   let serviceItemsNames = Object.keys(service).map((key, value) => {
  //     return {
  //       services: service[key].values
  //         .filter(f => f.type === 'option')
  //         .map(i => i.display_name),
  //       quantity: service[key].quantity ? service[key].quantity : 1,
  //       priceSingle: service[key].price,
  //       priceSum: service[key].price * service[key].quantity,
  //       time: service[key].sumTime,
  //     }
  //   })

  //   this.props.order.onSetOrder(
  //     user.name,
  //     user.last_name,
  //     user.street,
  //     user.municipality.name,
  //     user.municipality.id,
  //     date,
  //     time,
  //     serviceItemsNames,
  //     serviceItemsIDs,
  //     dateTimeStamp,
  //     this.state.servicesSelectedTimeSum,
  //     this.state.contractorId,
  //     user.email,
  //     user.street_num,
  //     user.floor,
  //     user.appartment,
  //     city,
  //     user.parkingReversed,
  //     user.phone_num,
  //     user.phone_num_additional,
  //     user.note
  //   )

  //   this.props.history.push('/job-overview')
  // }
  createOrder = () => {
    const {
      service,
      city,
      dateTimeStamp,
      user,
      date,
      time,
    } = this.state

    let serviceItemsIDs = service.map(s => {
      return {
        category: s.id,
        quantity: s.quantity ? s.quantity : 1,
      }
    })

    let serviceItemsNames = service.map(s => ({
      name: s.name,
      quantity: s.quantity ? s.quantity : 1,
      priceSingle: s.price,
      priceSum: s.price * s.quantity,
      time: s.sumTime,
      image: s.image,
      sku: s.sku,
    }))

    this.props.order.onSetOrder(
      user.name,
      user.last_name,
      user.street,
      user.municipality.name,
      user.municipality.id,
      date,
      time,
      serviceItemsNames,
      serviceItemsIDs,
      dateTimeStamp,
      this.state.servicesSelectedTimeSum,
      this.state.contractorId,
      user.email,
      user.street_num,
      user.floor,
      user.appartment,
      city,
      user.parkingReversed,
      user.phone_num,
      user.phone_num_additional,
      user.note
    )

    this.props.history.push('/job-overview')
  }

  calculateSumTimeForServices = () => {
    let summ = 0
    this.state.service.map(el => {
      return (summ += this.state.service.find(s => s.id === el.id)
        .sumTime)
    })

    this.setState({ servicesSelectedTimeSum: summ })
  }

  calculateSumPriceForServices = () => {
    let { service } = this.state
    let summ = 0

    service.map(el => {
      return (summ += service.find(s => s.id === el.id).sumPrice)
    })

    this.setState({ servicesSelectedPriceSum: summ })
  }

  calculateAvailableTime = (id, deleteOperation = false) => {
    let { service } = this.state

    if (deleteOperation) {
      let summ = 0
      service.map(el => {
        return (summ += service.find(s => s.id === el.id).sumTime)
      })

      let timeLimit = 360

      if (summ > timeLimit) {
        this.setState({ availableTime: false })
      } else {
        this.setState({ availableTime: true })
      }
    } else {
      let summ = 0
      service.map(el => {
        return (summ += service.find(s => s.id === el.id).sumTime)
      })

      let timeLimit = 360
      let selectedItemTime = service.find(s => s.id).time
      let calculated = summ + selectedItemTime

      if (calculated > timeLimit) {
        // console.log('1')

        this.setState({ availableTime: false })
      } else {
        // console.log('2')

        this.setState({ availableTime: true })
      }
    }
  }

  handleServiceSelectComplete = () => {
    this.setState({ servicesSelected: true })
    this.deleteDateAndContractorIfReselectFields()
  }

  // deleteService = val => {
  //   let newObject = [Object.assign({}, this.state.service)]
  //   delete newObject[val]
  //   this.setState({ service: newObject }, () => {
  //     this.calculateAvailableTime(val, true)
  //     this.calculateSumTimeForServices()
  //     this.calculateSumPriceForServices()
  //     if (_isEmpty(this.state.service)) {
  //       this.setState({
  //         servicesSelected: false,
  //         addressAndContactValid: false,
  //       })
  //       this.deleteDateAndContractorIfReselectFields()
  //     } else {
  //       this.deleteDateAndContractorIfReselectFields()
  //     }
  //   })
  //   this.calculateSumTimeForServices()
  //   this.calculateSumPriceForServices()

  //   // message.success('Servis je uspešno obrisan!')
  // }
  deleteService = id => {
    let newObject = [...this.state.service]
    let deletedProduct = newObject.filter(o => o.id !== id)
    this.setState({ service: deletedProduct }, () => {
      this.calculateAvailableTime(id, true)
      this.calculateSumTimeForServices()
      this.calculateSumPriceForServices()
      if (_isEmpty(this.state.service)) {
        this.setState({
          servicesSelected: false,
          addressAndContactValid: false,
        })
        this.deleteDateAndContractorIfReselectFields()
      } else {
        this.deleteDateAndContractorIfReselectFields()
      }
    })
    this.calculateSumTimeForServices()
    this.calculateSumPriceForServices()
  }

  // Add service

  // addService = (value, time, price) => {
  //   // console.log('rokanje')

  //   let id = shortid.generate()
  //   this.setState(
  //     {
  //       service: {
  //         ...this.state.service,
  //         [id]: {
  //           values: value,
  //           time: time,
  //           sumTime: time,
  //           price: price,
  //           sumPrice: price,
  //           quantity: 1,
  //         },
  //       },
  //     },
  //     () => {
  //       this.calculateSumPriceForServices()
  //       this.calculateSumTimeForServices()
  //       this.calculateAvailableTime(id)
  //     }
  //   )
  // }

  // Add product

  // addProduct = (name, id, time, price, image, sku) => {
  //   this.setState(
  //     prevState => ({
  //       service: [
  //         ...prevState.service,
  //         {
  //           id,
  //           name: name,
  //           time: time,
  //           sumTime: time,
  //           price: price,
  //           sumPrice: price,
  //           quantity: 1,
  //           image,
  //           sku,
  //         },
  //       ],
  //     }),
  //     () => {
  //       this.calculateSumPriceForServices()
  //       this.calculateSumTimeForServices()
  //       this.calculateAvailableTime(id)
  //     }
  //   )
  // }

  // Add category

  addCategory = (name, id, time, price, sku) => {
    this.setState(
      prevState => ({
        service: [
          ...prevState.service,
          {
            id,
            name: name,
            time: time,
            sumTime: time,
            price: price,
            sumPrice: price,
            quantity: 1,

            sku,
          },
        ],
      }),
      () => {
        this.calculateSumPriceForServices()
        this.calculateSumTimeForServices()
        this.calculateAvailableTime(id)
      }
    )
  }

  changeQuantity = (id, direction) => {
    let newArray = [...this.state.service]
    let selectedItem = newArray.find(a => a.id === id)

    if (direction === 'increase') {
      selectedItem.sumTime += selectedItem.time
      selectedItem.sumPrice += selectedItem.price
      selectedItem.quantity++
    } else if (direction === 'decrease') {
      selectedItem.sumTime -= selectedItem.time
      selectedItem.sumPrice -= selectedItem.price
      selectedItem.quantity--
    }

    this.setState(
      {
        service: newArray,
      },
      () => {
        this.calculateSumTimeForServices()
        this.calculateSumPriceForServices()
      }
    )

    this.deleteDateAndContractorIfReselectFields()
  }

  handleDateAndTime = (date, time, dateTimeValid) => {
    let formatDay = moment(date).format('D.M.YYYY')
    let splitDate = formatDay.split('.')
    var dateForNewStamp =
      splitDate[1] + '/' + splitDate[0] + '/' + splitDate[2]
    var lastStep = Date.parse(`${dateForNewStamp} ${time}:00`)

    this.setState({
      contractorList: [],
      contractorId: null,
      unformatedDate: date,
      date: formatDay,
      time,
      dateTimeValid: dateTimeValid,
      dateTimeStamp: lastStep / 1000,
    })
  }
  // validate address and contact
  validateAdressAndContact = param => {
    this.setState({
      addressAndContactValid: param,
    })
  }

  handleAddressAndContact = data => {
    this.setState(
      {
        user: data,
      },
      () => this.deleteDateAndContractorIfReselectFields()
    )
  }

  selectContractor = contractorId => {
    this.setState({ contractorId })
  }

  // Reset fields
  deleteDateAndContractorIfReselectFields = () => {
    this.setState({
      contractorId: null,
      contractorList: [],
      date: '',
      dateTimeStamp: '',
      dateTimeValid: false,
      time: '',
      ContractorPaginationData: {},
    })
  }

  changeTabStatus = (tabName, status) => {
    let testinjo = Object.keys(this.state.activeTabs)
      .filter(t => t !== tabName)
      .reduce((allFalse, key) => {
        allFalse[key] = false
        return allFalse
      }, {})

    this.setState({
      activeTabs: { ...testinjo, [tabName]: status },
    })

    if (this.state.activeTabs.CalendarAndTime) {
      this.setState({ contractorId: null, contractorList: [] })
    }
  }

  redirectIfSelectedContractorChangeStatus = error => {
    let errorResponse = error.response.data

    if (errorResponse && errorResponse.not_available) {
      this.props.history.goBack()
      this.changeTabStatus('CalendarAndTime', true)
    }
  }

  onUnload = e => {
    const jobOverviewURL = '/job-overview'
    let confirmationMsg =
      'Da li ste sigurni da želite da napustite stranu'

    const isJobOverview = window.location.pathname === jobOverviewURL

    if (process.env.NODE_ENV !== 'development' && !isJobOverview) {
      e.returnValue = confirmationMsg
      return confirmationMsg
    }
  }

  render() {
    const {
      service,
      addressAndContactValid,
      // date,
      dateTimeValid,
      servicesSelected,
      // initialService,
      contractorId,
      currentLocation,
      allowJobOverview,
      user,
      servicesSelectedTimeSum,
      availableTime,
      contractorList,
      ContractorPaginationData,
      unformatedDate,
      time,
      loaded,
      servicesSelectedPriceSum,
      activeTabs,
    } = this.state

    let allClosed = Object.keys(activeTabs).every(
      p => activeTabs[p] === false
    )

    let firstStepComplete = servicesSelected
    let secondStepComplete = addressAndContactValid
    let thirdStepComplete = dateTimeValid
    let fourthStepComplete = contractorId
    let allStepsComplete =
      firstStepComplete &&
      secondStepComplete &&
      thirdStepComplete &&
      fourthStepComplete &&
      allClosed

    return (
      <Fragment>
        {loaded ? (
          <div
            style={{
              display:
                currentLocation === 'order-service'
                  ? 'block'
                  : 'none',
            }}
          >
            <ServiceInfo
              // name={initialService.display_name}
              name="Sastavljanje nameštaja"
              desc="Izaberite tip nameštaja"
            />

            {/* Category Search */}

            <SelectCategory
              addCategory={this.addCategory}
              serviceExist={!_isEmpty(service)}
              servicesSelected={firstStepComplete}
              selectedService={service}
              services={service}
              handleServiceSelectComplete={
                this.handleServiceSelectComplete
              }
              deleteService={this.deleteService}
              changeQuantity={this.changeQuantity}
              servicesSelectedTimeSum={servicesSelectedTimeSum}
              servicesSelectedPriceSum={servicesSelectedPriceSum}
              calculateAvailableTime={this.calculateAvailableTime}
              availableTime={availableTime}
              changeTabStatus={this.changeTabStatus}
              activeTabs={activeTabs}
              secondStepComplete={secondStepComplete}
            />

            {/* Service Search */}

            {/* <SelectService
              initialService={initialService}
              addService={this.addService}
              serviceExist={!_isEmpty(service)}
              servicesSelected={firstStepComplete}
              selectedService={service}
              handleServiceSelectComplete={
                this.handleServiceSelectComplete
              }
              deleteService={this.deleteService}
              changeQuantity={this.changeQuantity}
              servicesSelectedTimeSum={servicesSelectedTimeSum}
              servicesSelectedPriceSum={servicesSelectedPriceSum}
              calculateAvailableTime={this.calculateAvailableTime}
              availableTime={availableTime}
              changeTabStatus={this.changeTabStatus}
              activeTabs={this.state.activeTabs}
              secondStepComplete={secondStepComplete}
            /> */}

            {/* Product Search */}

            {/* <SelectProduct
              // initialService={initialService}
              addProduct={this.addProduct}
              serviceExist={!_isEmpty(service)}
              servicesSelected={firstStepComplete}
              selectedService={service}
              services={service}
              handleServiceSelectComplete={
                this.handleServiceSelectComplete
              }
              deleteService={this.deleteService}
              changeQuantity={this.changeQuantity}
              servicesSelectedTimeSum={servicesSelectedTimeSum}
              servicesSelectedPriceSum={servicesSelectedPriceSum}
              calculateAvailableTime={this.calculateAvailableTime}
              availableTime={availableTime}
              changeTabStatus={this.changeTabStatus}
              activeTabs={activeTabs}
              secondStepComplete={secondStepComplete}
            /> */}

            <WrappedAddressAndContact
              handleAddressAndContact={this.handleAddressAndContact}
              firstStepCompleted={firstStepComplete}
              validate={this.validateAdressAndContact}
              secondStepComplete={secondStepComplete}
              changeTabStatus={this.changeTabStatus}
              activeTabs={activeTabs}
            />

            <CalendarAndTime
              // firstStepCompleted={firstStepComplete}
              // thirdStepComplete={thirdStepComplete}
              // selectedTime={date}
              // selectedDate={time}
              // contractorList={contractorList}
              activeTabs={activeTabs}
              servicesSelected={servicesSelected}
              timeSummary={servicesSelectedTimeSum}
              changeTabStatus={this.changeTabStatus}
              dateTimeValid={thirdStepComplete}
              secondStepComplete={secondStepComplete}
              handleDateAndTime={this.handleDateAndTime}
              getContractorList={this.getContractorList}
              selectedMunicipality={
                user.municipality && user.municipality.id
              }
            />

            <SelectContractor
              thirdStepComplete={thirdStepComplete}
              fourthStepComplete={fourthStepComplete}
              handleSelectContractor={this.selectContractor}
              handleContractorNextPage={this.getContractorList}
              contractorList={contractorList}
              ContractorPaginationData={ContractorPaginationData}
              user={user}
              servicesSelectedTimeSum={servicesSelectedTimeSum}
              selectedContractor={contractorId}
              date={unformatedDate}
              time={time}
              changeTabStatus={this.changeTabStatus}
              activeTabs={activeTabs}
            />

            <Button
              type="primary"
              disabled={!allStepsComplete}
              size="large"
              onClick={this.createOrder}
              style={{
                margin: '50px auto 60px auto ',
                display: 'block',
              }}
            >
              Naruči uslugu
            </Button>
          </div>
        ) : (
          <Loader />
        )}

        <div
          style={{
            display:
              currentLocation === 'job-overview' ? 'block' : 'none',
          }}
        >
          <JobOverview
            allowJobOverview={allowJobOverview}
            editOrder={false}
            redirectIfSelectedContractorChangeStatus={
              this.redirectIfSelectedContractorChangeStatus
            }
          />
        </div>
      </Fragment>
    )
  }
}

export default WithOrderContext(withTranslation()(OrderService))
