import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Checkbox } from 'antd'
import ReCAPTCHA from 'react-google-recaptcha'

import Section from '../../../components/OrderSections'
import SectionName from '../../../components/OrderSections/SectionName'
import { WithUserContext } from '../../../contexts/UserContext'
import OnlinePayment from './OnlinePayment'
import OfflinePayment from './OfflinePayment'

import classes from '../JobOverview.module.scss'

const PaymentAndSaveOrder = ({
  handlePayment,
  backendData,
  user,
  acceptedTerms,
  handleTerms,
  isPaymentLoading,
  handleRecaptcha,
  isRecaptchaPassed,
}) => {
  let isOnline =
    user.user.user_type === 'client' ||
    user.user.user_type === '' ||
    user.user.user_type === 'boothman'

  let isBoothman = user.user.user_type === 'boothman'
  let isNotRegistered = user.user.user_type === ''

  return (
    <Section>
      <SectionName text="Način plaćanja" margin={15} />
      <Checkbox
        checked={acceptedTerms}
        onChange={handleTerms}
        style={{ marginBottom: 8 }}
      >
        Prihvatam{' '}
        <Link to="/terms-and-conditions" target="_blank">
          uslove korišćenja
        </Link>
      </Checkbox>
      {isNotRegistered && (
        <div style={{ marginBottom: 8 }}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={value => handleRecaptcha(value)}
          />
        </div>
      )}
      <div className={classes.payment__container}>
        {isOnline && (
          <OnlinePayment
            isTermsAccepted={acceptedTerms}
            handlePayment={handlePayment}
            dataToSend={backendData}
            isPaymentLoading={isPaymentLoading}
            isRecaptchaPassed={isRecaptchaPassed}
            isUserGuest={isNotRegistered}
          />
        )}
        {isBoothman && (
          <OfflinePayment
            handlePayment={handlePayment}
            dataToSend={backendData}
          />
        )}
      </div>
    </Section>
  )
}

PaymentAndSaveOrder.propTypes = {
  handlePayment: PropTypes.func.isRequired,
  backendData: PropTypes.object,
  user: PropTypes.object.isRequired,
  acceptedTerms: PropTypes.bool.isRequired,
  handleTerms: PropTypes.func.isRequired,
  isPaymentLoading: PropTypes.bool,
  handleRecaptcha: PropTypes.func,
  isRecaptchaPassed: PropTypes.string,
}

export default WithUserContext(PaymentAndSaveOrder)
