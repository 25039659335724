import React from 'react'
import PropTypes from 'prop-types'

import classes from './PlatformInformation.module.scss'

const PlatformInformation = ({ orderNumber }) => {
  const OrderNumberSection = () => {
    return (
      <div
        className={classes.platformInformation__orderNumberWrapper}
      >
        <h3 className={classes.platformInformation__heading}>
          Broj narudžbine
        </h3>
        <h3 className={classes.platformInformation__heading}>
          {orderNumber}
        </h3>
      </div>
    )
  }

  return (
    <div className={classes.platformInformation__wrapper}>
      <p className={classes.platformInformation__box}>
        Homea Platform d.o.o.
        <br />
        Beograd, Studentski trg 4
        <br />
        6201 - Računarsko programiranje
        <br />
        Matični broj: 21316482
        <br />
        PIB: 110205082 <br />
      </p>
      {orderNumber && <OrderNumberSection />}
      <p className={classes.platformInformation__box}>
        Kontakt podrška <br />
        <a href="https://www.homea.rs">https://www.homea.rs</a>
        <br />
        <a href="tel:0692048013">0692048013</a>
        <br />
        <a href="mailto:info@homea.io">info@homea.io</a>
        <br />
      </p>
    </div>
  )
}

PlatformInformation.propTypes = {
  orderNumber: PropTypes.string,
}

export default PlatformInformation
