import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import _isEmpty from 'lodash/isEmpty'
import { withRouter } from 'react-router'

import HorizontalLine from '../HorizontalLine'
import OrderSection from '../OrderSections'
import { WithUserContext } from '../../contexts/UserContext'
import ClientInfo from '../ClientInfo'
import PartnerChangeContractor from '../PartnerChangeContractor'
import ContractorInfo from './ContractorInfo'
import ClientNote from '../ClientNote'
// import ProductImage from '../ProductImage'
import Gallery from '../Gallery'
import SignatureModal from '../SignatureModal'

import classes from './JobCard.module.scss'

class JobCard extends Component {
  state = {
    isSignatureModalVisible: false,
  }

  handleSignatureModal = visible =>
    this.setState({ isSignatureModalVisible: visible })

  render() {
    let {
      items,
      partnerView,
      partnerViewId,
      scheduledAt,
      duration,
      address,
      contact,
      availableContractorList,
      saveChange,
      jobId,
      selectContractor,
      contractor,
      canFinish,
      signature,
      finished,
    } = this.props

    let roles = {
      partner: partnerView,
      partnerViewWithContractorId: partnerViewId,
      contractor: !contractor,
    }

    let canChangeContractor =
      roles.partner || roles.partnerViewWithContractorId

    // Old version
    // let canSeeContractorInfo =
    //   roles.partner || roles.partnerViewWithContractorId
    let canSeeContractorInfo = roles.partner

    let canSeeReportButton = roles.contractor && canFinish

    let canSeeSignatureButton =
      roles.contractor ||
      roles.partner ||
      roles.partnerViewWithContractorId

    let isFooterVisible =
      (canSeeContractorInfo && contractor) ||
      (canChangeContractor && !_isEmpty(availableContractorList)) ||
      (finished && canSeeSignatureButton && !!signature) ||
      canSeeReportButton

    return (
      <OrderSection
        customStyle={{
          width: partnerView ? '100%' : null,
        }}
      >
        <h4 className={classes.card__heading}>
          Sastavljanje nameštaja
        </h4>
        <div className={classes.card__info}>
          <div className={classes['card__info-date']}>
            {/* <Icon type="calendar" className={classes.card__icon} /> */}
            Zakazano za:{' '}
            {moment(scheduledAt).format('DD.MM.YYYY. u HH:mm')}h
          </div>
          <div className={classes['card__info-duration']}>
            {/* <Icon
              type="clock-circle"
              className={classes.card__icon}
            /> */}
            Očekivano trajanje posla: {duration}h
          </div>
          <div className={classes['card__info-address']}>
            {/* <Icon type="environment" className={classes.card__icon} /> */}
            Adresa: {address.city}, {address.municipality},{' '}
            {address.street} {address.street_number}
            {address.floor && `, sprat ${address.floor}`}
            {address.apartment && `, stan ${address.apartment}`}
          </div>
        </div>

        <HorizontalLine margin={8} />

        <div className={classes.card__clientAndServicesContainer}>
          <ClientInfo
            firstName={contact.first_name}
            lastName={contact.last_name}
            email={contact.email}
            phone={contact.phone}
            phoneAdditional={contact.phone_additional}
            hasParking={address.has_parking}
            note={contact.note}
          />
          <div className={classes.card__services}>
            {items.map(i => {
              let isGalleryButtonVisible =
                i.finished && !_isEmpty(i.images)
              return (
                <div key={i.id} className={classes.card__service}>
                  <div className={classes.card__header}>
                    {/* <ProductImage src={i.product.image} border /> */}
                    <p className={classes.card__serviceItems}>
                      <span className={classes.card__serviceName}>
                        {i.category.name}
                      </span>{' '}
                      <br /> Količina: {i.quantity}{' '}
                      {i.quantity > 1 ? ' komada' : ' komad'}
                      <br />
                      Trajanje: {i.duration}
                      <br />
                    </p>
                  </div>
                  {i.job_note && (
                    <p style={{ marginBottom: 0 }}>Napomena: </p>
                  )}
                  <ClientNote
                    text={i.job_note}
                    style={{ marginRight: 0 }}
                  />
                  {isGalleryButtonVisible && (
                    <Gallery arrayOfImages={i.images} withButton />
                  )}
                </div>
              )
            })}
          </div>
        </div>

        {isFooterVisible && (
          <div className={classes.card__footer}>
            {canSeeContractorInfo && contractor && (
              <ContractorInfo
                image={contractor.image}
                first_name={contractor.first_name}
                last_name={contractor.last_name}
              />
            )}
            {canChangeContractor && availableContractorList && (
              <PartnerChangeContractor
                contractorList={availableContractorList}
                saveChange={saveChange}
                jobId={jobId}
                selectContractor={selectContractor}
              />
            )}
            {finished && canSeeSignatureButton && !!signature && (
              <SignatureModal signature={signature} />
            )}
            {canSeeReportButton && (
              <Button
                size="large"
                type="primary"
                onClick={() =>
                  this.props.history.push(`/order/${jobId}`)
                }
                className={classes.card__reportBtn}
              >
                Izveštaj
              </Button>
            )}
          </div>
        )}
      </OrderSection>
    )
  }
}

JobCard.propTypes = {
  scheduledAt: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  contact: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  availableContractorList: PropTypes.array,
  jobId: PropTypes.number,
  saveChange: PropTypes.func,
  selectContractor: PropTypes.func,
  changeContractor: PropTypes.bool,
  partnerViewId: PropTypes.bool,
  partnerView: PropTypes.bool,
  canFinish: PropTypes.bool,
  finished: PropTypes.bool,
  contractor: PropTypes.object,
  signature: PropTypes.string,
}

export default withRouter(WithUserContext(withTranslation()(JobCard)))
