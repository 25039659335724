import React, { Component, Fragment } from 'react'
import { message, Select, List } from 'antd'
import _get from 'lodash/get'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'

import { apiGet, apiPost } from '../../../../../../api'
import Pagination from '../../../../../../components/Pagination'
import errorHelper from '../../../../../../helpers/errorHelper'
import EmptyListImage from '../../../../../../components/EmptyListImage'
import JobCard from '../../../../../../components/JobCard'

import classes from '../../../../Dashboard.module.scss'

const { Option } = Select

class AllJobs extends Component {
  state = {
    jobList: [],
    loading: false,
    pagination: {
      meta: {},
      links: {},
    },
    selectedContractor: null,
    savedContractor: null,
    listType: 'active',
  }

  componentDidMount() {
    this.getPartnerOrders('active')
    this.setState({ listType: 'active' })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active && this.props.active) {
      this.getPartnerOrders('active')
    }
  }

  getPartnerOrders = async (type, page) => {
    this.setState({ loading: true })
    try {
      let res
      if (page) {
        res = await apiGet.getPartnerOrders(type, page)
      } else {
        res = await apiGet.getPartnerOrders(type)
      }

      let partnerOrders = _get(res, 'data', null)
      if (!partnerOrders)
        throw new Error(this.props.t('dashboard.orderFetchFailed'))
      this.setState({
        jobList: partnerOrders.data,
        pagination: {
          meta: partnerOrders.meta,
          links: partnerOrders.links,
        },
        loading: false,
      })
    } catch (error) {
      this.setState({ loading: false })
      errorHelper(error, this.props.t('dashboard.orderFetchFailed'))
    }
  }

  savePartnerChange = async jobId => {
    let { selectedContractor, jobList } = this.state
    let { t } = this.props

    try {
      const res = await apiPost.handlePartnerChangeContractor(
        jobId,
        selectedContractor.id
      )
      let contractor = _get(res, 'data', null)
      if (!contractor)
        throw new Error(t('dashboard.changeContractorFailed'))
      message.success(contractor.message)
      let newJobList = [...jobList]

      newJobList.find(
        job => job.id === jobId
      ).contractor = selectedContractor

      newJobList.find(job => job.id === jobId).available_contractors =
        contractor.available_contractors

      this.setState({
        jobList: newJobList,
      })
    } catch (error) {
      errorHelper(
        error,
        this.props.t('dashboard.changeContractorFailed')
      )
    }
  }

  selectContractor = (contractorId, jobId) => {
    let { jobList } = this.state

    let contractor = jobList
      .find(job => job.id === jobId)
      .available_contractors.find(
        contractor => contractor.id === contractorId
      )

    this.setState({
      selectedContractor: contractor,
    })
  }

  changeListType = value => {
    this.getPartnerOrders(value)
    this.setState({ listType: value })
  }

  handlePageChange = (page, pagesize) => {
    this.getPartnerOrders(this.state.listType, page)
  }

  render() {
    let { jobList, loading, listType } = this.state

    return (
      <div>
        {/* {listType && ( */}
        <Select
          className={classes.dashboard__select}
          defaultValue={listType}
          onChange={this.changeListType}
        >
          <Option value="active">Aktivni</Option>
          <Option value="finished">Završeni</Option>
          <Option value="">Svi</Option>
        </Select>
        {/* )} */}
        {!!jobList.length && (
          <Fragment>
            <List
              locale={EmptyListImage}
              loading={loading}
              itemLayout="horizontal"
              dataSource={jobList}
              renderItem={item => (
                <JobCard
                  key={item.id}
                  jobId={item.id}
                  scheduledAt={item.scheduled_at}
                  duration={item.duration}
                  contact={item.contact}
                  address={item.address}
                  items={item.items}
                  contractor={item.contractor}
                  availableContractorList={item.available_contractors}
                  selectContractor={this.selectContractor}
                  saveChange={this.savePartnerChange}
                  partnerView
                  canFinish={item.can_finish}
                  finished={item.finished}
                  signature={item.client_signature}
                />
              )}
            />
            <Pagination
              ordersLength={this.state.jobList.length}
              metaTotal={this.state.pagination.meta.total}
              metaPerPage={this.state.pagination.meta.per_page}
              handlePageChange={this.handlePageChange}
            />
          </Fragment>
        )}
      </div>
    )
  }
}

export default withRouter(withTranslation()(AllJobs))
