import React from 'react'
import PropTypes from 'prop-types'

import classes from './ServiceInfo.module.scss'

const ServiceInfo = ({ name, desc }) => (
  <div className={classes.info__container}>
    <h3 className={classes.info__name}>{name}</h3>
    <p className={classes.info__desc}>{desc}</p>
  </div>
)

ServiceInfo.propTypes = {
  name: PropTypes.string.isRequired,
  desc: PropTypes.string,
}

export default ServiceInfo
