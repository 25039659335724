import React, { createContext, Component } from 'react'

const UserContext = createContext()

class UserStore extends Component {
  state = {
    first_name: '',
    last_name: '',
    email: '',
    loggedIn: false,
    user_type: '',
    id: '',
    name: '',
  }

  setUser = (
    first_name,
    last_name,
    email,
    loggedIn,
    user_type,
    id,
    name
  ) => {
    this.setState({
      first_name,
      last_name,
      email,
      loggedIn: loggedIn,
      user_type,
      id,
      name,
    })
  }

  deleteUser = () => {
    this.setState({
      first_name: '',
      last_name: '',
      email: '',
      loggedIn: false,
      user_type: '',
      id: '',
      name: '',
    })
  }

  render() {
    return (
      <UserContext.Provider
        value={{
          user: this.state,
          onSetUser: this.setUser,
          onDeleteUser: this.deleteUser,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

const WithUserContext = Component => {
  return props => (
    <UserContext.Consumer>
      {user => <Component {...props} user={user} />}
    </UserContext.Consumer>
  )
}

export { UserContext, UserStore, WithUserContext }
