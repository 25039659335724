import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from '../trans/en.json'
import sr from '../trans/sr.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: false,
    lng: 'sr',
    resources: {
      sr: {
        translation: sr,
      },
      en: {
        translation: en,
      },
    },
    fallbackLng: 'en',
  })

export default i18n
