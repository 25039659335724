import React, { Component } from 'react'
import { Rate, Button, Skeleton, Pagination } from 'antd'
import scroll from 'react-scroll'
import moment from 'moment'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { withTranslation } from 'react-i18next'

import Section from '../../../components/OrderSections'
import TabName from '../../../components/TabName'
import SectionAnimated from '../../../components/SectionAnimated'
import SelectService from '../SelectService'
import { apiGet } from '../../../api'
import errorHelper from '../../../helpers/errorHelper'
import FeedbackListWithModal from '../../../components/FeedbackListWithModal'
import scrollToElement from '../../../helpers/scrollToElement'
import UserPlaceholder from '../../../assets/images/user_placeholder.svg'

import classes from './SelectContractor.module.scss'

const { Element } = scroll

class SelectContractor extends Component {
  state = {
    active: false,
    contractorName: '',
    feedbackModalVisible: false,
    feedback: null,
    currentPage: 1,
  }

  componentDidUpdate(props) {
    if (props.thirdStepComplete !== this.props.thirdStepComplete) {
      this.renderOnlyWhenCalendarChange()
    }

    if (props.contractorList !== this.props.contractorList) {
      this.setState({ contractorName: '' })
      this.handleScroll()
    }

    if (
      props.activeTabs.SelectContractor !==
      this.props.activeTabs.SelectContractor
    ) {
      this.setState({ currentPage: 1 })
    }
  }

  getContractorFeedback = async (id, page) => {
    try {
      let res
      if (page) {
        res = await apiGet.getContractorFeedback(id, page)
      } else {
        res = await apiGet.getContractorFeedback(id)
      }
      let feedback = _get(res, 'data', null)
      if (!feedback)
        throw new Error(
          this.props.t('orderService.feedbackFetchFailed')
        )
      this.setState({ feedback, contractorId: id })
    } catch (error) {
      errorHelper(
        error,
        this.props.t('orderService.feedbackFetchFailed')
      )
    }
  }

  setTabActive = status => {
    this.props.changeTabStatus('SelectContractor', status)
    this.setState({ active: status })
  }

  handlePageChange = page => {
    let {
      handleContractorNextPage,
      user,
      date,
      time,
      servicesSelectedTimeSum,
    } = this.props

    this.setState({ currentPage: page })

    handleContractorNextPage(
      user.municipality.id,
      moment(date).format('YYYY-MM-DD'),
      time,
      servicesSelectedTimeSum,
      page
    )
  }

  handleOpenSection = () => {
    if (this.props.fourthStepComplete) {
      this.setTabActive(!this.state.active)
    }
  }

  handleSelectedContractor = (name, lastName) => {
    this.setState({
      contractorName: `${name} ${lastName}`,
    })
    this.setTabActive(false)
  }

  openFeedback = id => {
    this.getContractorFeedback(id)
    this.setState({ feedbackModalVisible: true })
  }

  handleModalAction = () => {
    this.setState({ feedbackModalVisible: false, feedback: null })
  }

  handleFeedbackPagination = page => {
    this.getContractorFeedback(this.state.contractorId, page)
  }

  handleScroll = () => {
    let isContractorTabActive =
      this.props.activeTabs.SelectContractor &&
      !!this.props.contractorList.length

    if (isContractorTabActive) {
      setTimeout(() => {
        scrollToElement('scrollLocationSelectContractor', -150)
      }, 200)
    }
  }

  renderOnlyWhenCalendarChange = () => {
    let { activeTabs, thirdStepComplete } = this.props

    if (activeTabs.CalendarAndTime === false && thirdStepComplete) {
      this.setTabActive(true)
    }
  }

  render() {
    let {
      contractorName,
      feedbackModalVisible,
      feedback,
    } = this.state

    let {
      fourthStepComplete,
      contractorList,
      handleSelectContractor,
      ContractorPaginationData,
      activeTabs,
    } = this.props

    let showContractors =
      activeTabs.SelectContractor && !!contractorList.length

    return (
      <Section>
        <Element name="scrollLocationSelectContractor" />
        <TabName
          text="Sastavljači"
          handleOpenSection={this.handleOpenSection}
          checked={!!fourthStepComplete}
          summary={contractorName}
          displaySummary={!!contractorName}
          section="select-contractor"
        />
        <SectionAnimated active={showContractors}>
          <div className={classes.contractors__container}>
            {contractorList.length > 0 ? (
              contractorList.map(user => {
                return (
                  <div
                    className={classes.contractors__tab}
                    key={user.id}
                  >
                    {!!user.rating && (
                      <Button
                        className={classes.contractors__btn}
                        icon="message"
                        onClick={() => this.openFeedback(user.id)}
                      />
                    )}
                    <div
                      className={classes.contractors__avatar}
                      style={{
                        backgroundImage: `url(${
                          user.image ? user.image : UserPlaceholder
                        })`,
                      }}
                    />
                    <p className={classes.contractors__name}>
                      {user.first_name} {user.last_name}
                    </p>
                    <React.Fragment>
                      {!!user.rating ? (
                        <Rate
                          defaultValue={parseInt(user.rating)}
                          disabled
                          allowHalf
                          className={classes.contractors__rate}
                        />
                      ) : (
                        <span className={classes.contractors__noRate}>
                          Sastavljač je neocenjen.
                        </span>
                      )}
                    </React.Fragment>
                    <Button
                      className={classes.contractors__selectBtn}
                      type="primary"
                      size="large"
                      onClick={() => {
                        handleSelectContractor(user.id)
                        this.handleSelectedContractor(
                          user.first_name,
                          user.last_name
                        )
                      }}
                    >
                      Izaberi
                    </Button>
                  </div>
                )
              })
            ) : (
              <div className={classes.contractors__container}>
                <div className={classes.contractors__tab}>
                  <Skeleton avatar={{ size: 'large' }} />
                </div>
                <div className={classes.contractors__tab}>
                  <Skeleton avatar={{ size: 'large' }} />
                </div>
                <div className={classes.contractors__tab}>
                  <Skeleton avatar={{ size: 'large' }} />
                </div>
                <div className={classes.contractors__tab}>
                  <Skeleton avatar={{ size: 'large' }} />
                </div>
              </div>
            )}
          </div>
          {!_isEmpty(ContractorPaginationData) &&
            ContractorPaginationData.meta.total >
              ContractorPaginationData.meta.per_page && (
              <Pagination
                style={{ textAlign: 'center' }}
                // defaultCurrent={1}
                simple
                current={this.state.currentPage}
                total={ContractorPaginationData.meta.total}
                pageSize={
                  ContractorPaginationData.meta.per_page
                    ? ContractorPaginationData.meta.per_page
                    : null
                }
                onChange={this.handlePageChange}
              />
            )}
        </SectionAnimated>
        {feedback && (
          <FeedbackListWithModal
            feedbackModalVisible={feedbackModalVisible}
            feedbackData={feedback}
            onCancel={this.handleModalAction}
            customTitle="Utisci sastavljaču"
            handlePagination={this.handleFeedbackPagination}
          />
        )}
      </Section>
    )
  }
}

SelectService.propTypes = {
  thirdStepComplete: PropTypes.bool,
  fourthStepComplete: PropTypes.bool,
  handleSelectContractor: PropTypes.func,
  handleContractorNextPage: PropTypes.func,
  contractorList: PropTypes.array,
  ContractorPaginationData: PropTypes.object,
  user: PropTypes.object,
  servicesSelectedTimeSum: PropTypes.number,
  selectedContractor: PropTypes.number,
  date: PropTypes.string,
  time: PropTypes.string,
  changeTabStatus: PropTypes.func,
  activeTabs: PropTypes.object,
}

export default withTranslation()(SelectContractor)
