import React, { Component, Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import Loader from '../components/Loader'

class GuestRoute extends Component {
  state = {
    loaded: null,
  }

  componentDidMount() {
    if (this.props.userLoaded) {
      this.setState({ loaded: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userLoaded !== prevProps.userLoaded) {
      this.setState({ loaded: true })
    }
  }

  render() {
    let {
      wrapper,
      isAuthenticated,
      Component,
      path,
      roles,
      user_type,
      userLoaded,
      ...restProps
    } = this.props

    let { loaded } = this.state

    if (isAuthenticated) {
      return (
        <Fragment>
          {roles.includes(user_type) && loaded ? (
            <Route
              path={path}
              render={props => (
                <Component
                  isAuthenticated={isAuthenticated}
                  {...restProps}
                  {...props}
                />
              )}
            />
          ) : (
            <Loader />
          )}
          {!roles.includes(user_type) && loaded === true && (
            <Redirect to="/" />
          )}
        </Fragment>
      )
    } else {
      return <Redirect to="/" />
    }
  }
}

GuestRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  path: PropTypes.string,
  wrapper: PropTypes.bool,
  roles: PropTypes.array.isRequired,
  user_type: PropTypes.string.isRequired,
  user_loaded: PropTypes.bool,
}

export default GuestRoute
