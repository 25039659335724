import React, { Fragment, useState } from 'react'
import { Button, Modal } from 'antd'
import PropTypes from 'prop-types'

import classes from './SignatureModal.module.scss'

const SignatureModal = ({ signature }) => {
  let [isSignatureModalVisibile, setModalVisibility] = useState(false)

  return (
    <Fragment>
      <Button
        onClick={() => setModalVisibility(true)}
        icon="highlight"
        size="large"
        className={classes.signature__btn}
      >
        Potpis
      </Button>
      <Modal
        title="Potpis"
        visible={isSignatureModalVisibile}
        onCancel={() => setModalVisibility(false)}
        footer={null}
      >
        <img
          alt="Signature"
          src={signature}
          className={classes.signature}
        />
      </Modal>
    </Fragment>
  )
}

SignatureModal.propTypes = {
  signature: PropTypes.string.isRequired,
}

export default SignatureModal
