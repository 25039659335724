import React, { useState, Fragment } from 'react'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { message, Button, Modal, Form, Rate, Input } from 'antd'
import { withTranslation } from 'react-i18next'

import { apiPost } from '../../api'
import errorHelper from '../../helpers/errorHelper'

import classes from './OrderFeedback.module.scss'

const OrderFeedback = ({ id, canSendFeedback }) => {
  let [isFeedbackVisible, setFeedbackVisibility] = useState(false)
  let [feedbackRate, setFeedbackRate] = useState(0)
  let [feedbackComment, setFeedbackComment] = useState('')
  let [isButtonVisible, setButtonVisibility] = useState(
    canSendFeedback
  )

  let sendFeedback = async () => {
    try {
      const res = await apiPost.handleClientFeedback(
        id,
        feedbackRate,
        feedbackComment
      )
      let feedbackResult = _get(res, 'data', null)
      if (!feedbackResult) {
        throw new Error(
          this.props.t('component.OrderFeedback.failedToSendFeedback')
        )
      }
      message.success(feedbackResult.message)
      setFeedbackVisibility(false)
      setFeedbackRate(0)
      setFeedbackComment('')
      setButtonVisibility(false)
    } catch (error) {
      setFeedbackVisibility(false)
      setFeedbackRate(0)
      setFeedbackComment('')
      errorHelper(
        error,
        this.props.t('component.OrderFeedback.failedToSendFeedback')
      )
    }
  }

  let handleFeedbackModalVisibility = () => {
    setFeedbackVisibility(false)
    setFeedbackRate(0)
    setFeedbackComment('')
  }

  let isButtonDisabled =
    feedbackRate <= 0 || feedbackComment.length <= 0

  return (
    <Fragment>
      {isButtonVisible && (
        <Button
          className={classes.OrderFeedback__btn}
          onClick={() => setFeedbackVisibility(true)}
        >
          Ostavite utisak
        </Button>
      )}
      <Modal
        title="Podeli utisak o izvršenoj usluzi"
        visible={isFeedbackVisible}
        onCancel={handleFeedbackModalVisibility}
        onOk={sendFeedback}
        centered
        okText="Snimi"
        cancelText="Odustani"
        okButtonProps={{
          disabled: isButtonDisabled,
        }}
      >
        <Form.Item label="Ocenite uslugu od 1 - 5">
          <Rate
            style={{ fontSize: 25 }}
            onChange={setFeedbackRate}
            value={feedbackRate}
          />
        </Form.Item>
        <Form.Item label="Komentar na uslugu">
          <Input.TextArea
            onChange={e => setFeedbackComment(e.target.value)}
            value={feedbackComment}
          />
        </Form.Item>
      </Modal>
    </Fragment>
  )
}

OrderFeedback.propTypes = {
  id: PropTypes.number.isRequired,
  canSendFeedback: PropTypes.bool.isRequired,
}

export default withTranslation()(OrderFeedback)
