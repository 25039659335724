import React, { Component, Fragment } from 'react'
import { Select, message, List } from 'antd'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'

import { apiGet, apiPost } from '../../../../api'
import errorHelper from '../../../../helpers/errorHelper'
import Pagination from '../../../../components/Pagination'
import EmptyListImage from '../../../../components/EmptyListImage'
import OrderItem from './OrderItem'

import classes from '../../Dashboard.module.scss'

const { Option } = Select

class OrderList extends Component {
  _isMounted = false
  state = {
    orders: null,
    orderType: 'active',
    loading: false,
    pagination: {
      meta: {},
      links: {},
    },
  }

  componentDidMount() {
    this._isMounted = true
    this.getOrders(this.state.orderType)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getOrders = async (type, page) => {
    this.setState({ loading: true })
    try {
      let res
      if (page) {
        res = await apiGet.getClientOrders(type, page)
      } else {
        res = await apiGet.getClientOrders(type)
      }
      let orders = _get(res, 'data', null)
      if (!orders)
        throw new Error(this.props.t('dashboard.orderFetchFailed'))
      if (this._isMounted) {
        this.setState({
          orders: orders.data,
          pagination: {
            meta: orders.meta,
            links: orders.links,
          },
          loading: false,
        })
      }
    } catch (error) {
      this.setState({ loading: false })
      errorHelper(error, this.props.t('dashboard.orderFetchFailed'))
    }
  }

  cancelOrder = async id => {
    try {
      const res = await apiPost.clientCancelOrder(id)
      let order = _get(res, 'data', null)
      if (!order)
        throw new Error(this.props.t('dashboard.cancelOrderFail'))
      message.success(this.props.t('dashboard.cancelOrderSuccess'))

      let newOrdersObject = this.state.orders.filter(
        order => order.id !== id
      )
      this.setState({
        orders: newOrdersObject,
      })
    } catch (error) {
      // console.log('error', error.response.data)

      errorHelper(error, this.props.t('dashboard.cancelOrderFail'))
    }
  }

  handleFilterOrderType = type => {
    this.setState({ orderType: type })
    this.getOrders(type)
  }

  handlePageChange = page =>
    this.getOrders(this.state.orderType, page)

  render() {
    let { orders, loading, orderType, pagination } = this.state

    return (
      <div>
        <div className={classes.dashboard__titleContainer}>
          <h3 className={classes.dashboard__name}>Narudžbine</h3>
          <Select
            defaultValue="active"
            className={classes.dashboard__select}
            onChange={this.handleFilterOrderType}
          >
            <Option value="active">Aktivni</Option>
            <Option value="finished">Završeni</Option>
            <Option value="">Svi</Option>
          </Select>
        </div>
        {orders && (
          <Fragment>
            <List
              locale={EmptyListImage}
              loading={loading}
              itemLayout="horizontal"
              dataSource={orders}
              renderItem={order => (
                <OrderItem
                  order={order}
                  cancelOrder={this.cancelOrder}
                  loading={loading}
                  orderType={orderType}
                  canSendFeedback={order.can_send_feedback}
                  orderId={order.id}
                />
              )}
            />
            <Pagination
              ordersLength={orders.length}
              metaTotal={pagination.meta.total}
              metaPerPage={pagination.meta.per_page}
              handlePageChange={this.handlePageChange}
            />
          </Fragment>
        )}
      </div>
    )
  }
}

export default withTranslation()(OrderList)
