import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'

import classes from './QuantityInput.module.scss'

const QuantityInput = ({
  changeQuant,
  selectedId,
  calculateAvailableTime,
  availableTime,
  serviceQuantity,
}) => {
  return (
    <div className={classes.quantity__container}>
      <Button
        size="large"
        icon="minus"
        onClick={() => {
          if (serviceQuantity > 1) {
            changeQuant(selectedId, 'decrease')
            calculateAvailableTime(selectedId)
          }
        }}
        disabled={serviceQuantity === 1}
      />
      <span className={classes.quantity__number}>
        {serviceQuantity}
      </span>
      <Button
        size="large"
        icon="plus"
        onClick={() => {
          changeQuant(selectedId, 'increase')
          calculateAvailableTime(selectedId)
        }}
        disabled={!availableTime}
      />
    </div>
  )
}

QuantityInput.propTyes = {
  changeQuant: PropTypes.func.isRequired,
  calculateAvailableTime: PropTypes.func.isRequired,
  selectedId: PropTypes.number.isRequired,
  availableTime: PropTypes.bool.isRequired,
  serviceQuantity: PropTypes.object.isRequired,
}

export default QuantityInput
