import React from 'react'
import PropTypes from 'prop-types'

import UserPlaceholder from '../../assets/images/user_placeholder.svg'

import classes from './ProfileAvatar.module.scss'

let ProfileAvatar = ({ src, alt, imageStyle }) => {
  let imageSource = src ? src : UserPlaceholder

  return (
    <img
      src={imageSource}
      alt={alt}
      style={{ ...imageStyle }}
      className={classes.ProfileAvatar__img}
    />
  )
}

ProfileAvatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  imageStyle: PropTypes.object,
}

export default ProfileAvatar
