import React, { Fragment, Component } from 'react'
import { Select, message, List } from 'antd'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

import Pagination from '../../../components/Pagination'
import { apiGet, apiPost } from '../../../api'
import errorHelper from '../../../helpers/errorHelper'
import ProfileAvatar from '../../../components/ProfileAvatar'
import isPageForbidden from '../../../helpers/isPageForbidden'
import EmptyListImage from '../../../components/EmptyListImage'
import JobCard from '../../../components/JobCard'

import classes from '../Dashboard.module.scss'

const { Option } = Select

class JobList extends Component {
  _isMounted = false
  state = {
    orders: null,
    pagination: {
      meta: {},
      links: {},
    },
    orderType: 'active',
    loading: false,
    selectedContractor: null,
    savedContractor: null,
  }

  componentDidMount() {
    this._isMounted = true

    if (this.props.loadedContractorId) {
      this.getContractorOrders(
        this.state.orderType,
        this.props.loadedContractorId
      )
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getContractorOrders = async (type, id, page) => {
    let { t, history } = this.props

    this.setState({ loading: true })
    try {
      let res
      if (page) {
        res = await apiGet.getContractorOrdersWithId(type, id, page)
      } else {
        res = await apiGet.getContractorOrdersWithId(type, id)
      }
      let orders = _get(res, 'data', null)
      if (!orders) throw new Error(t('dashboard.orderFetchFailed'))
      if (this._isMounted) {
        this.setState({
          orders: orders.data,
          pagination: { meta: orders.meta, links: orders.links },
          loading: false,
        })
      }
    } catch (error) {
      this.setState({ loading: false })
      errorHelper(error, t('dashboard.orderFetchFailed'))
      isPageForbidden(error) && history.push('/')
    }
  }

  savePartnerChange = async jobId => {
    try {
      const res = await apiPost.handlePartnerChangeContractor(
        jobId,
        this.state.selectedContractor.id
      )
      let contractor = _get(res, 'data', null)
      if (!contractor)
        throw new Error(
          this.props.t('dashboard.contractorChangeFailed')
        )

      message.success(contractor.message)

      let newJobList = this.state.orders.filter(
        job => job.id !== jobId
      )

      this.setState({
        orders: newJobList,
      })
    } catch (error) {
      errorHelper(
        error,
        this.props.t('dashboard.contractorChangeFailed')
      )
    }
  }

  selectContractor = (loadedContractorId, jobId) => {
    let contractor = this.state.orders
      .find(job => job.id === jobId)
      .available_contractors.find(
        contractor => contractor.id === loadedContractorId
      )

    this.setState({ selectedContractor: contractor })
  }

  filterOrderType = type => {
    this.setState({ orderType: type })
    this.getContractorOrders(type, this.props.loadedContractorId)
  }

  handlePageChange = page => {
    this.getContractorOrders(
      this.state.orderType,
      this.props.loadedContractorId,
      page
    )
  }

  render() {
    let { orders, loading, pagination } = this.state
    let { loadedContractor, loadedContractorId } = this.props

    return (
      <Fragment>
        {loadedContractor && (
          <div className={classes.dashboard__titleContainer}>
            <div className={classes.dashboard__partnerName}>
              <div className={classes.dashboard__avatar}>
                <ProfileAvatar src={loadedContractor.image} />
              </div>
              <p className={classes.dashboard__contractorBasics}>
                {loadedContractor.first_name}{' '}
                {loadedContractor.last_name}
              </p>
            </div>

            <Select
              defaultValue="active"
              className={classes.dashboard__select}
              onChange={this.filterOrderType}
            >
              <Option value="active">Aktivni</Option>
              <Option value="finished">Završeni</Option>
              <Option value="">Svi</Option>
            </Select>
          </div>
        )}

        {orders && (
          <Fragment>
            <List
              locale={EmptyListImage}
              loading={loading}
              itemLayout="horizontal"
              dataSource={orders}
              renderItem={order => {
                let contractorId = { id: loadedContractorId }

                return (
                  <JobCard
                    key={order.id}
                    jobId={order.id}
                    orders={order.items}
                    scheduledAt={order.scheduled_at}
                    duration={order.duration}
                    contact={order.contact}
                    address={order.address}
                    items={order.items}
                    partnerViewId
                    availableContractorList={
                      order.available_contractors
                    }
                    saveChange={this.savePartnerChange}
                    selectContractor={this.selectContractor}
                    canFinish={order.can_finish}
                    contractor={contractorId}
                    signature={order.client_signature}
                    finished={order.finished}
                  />
                )
              }}
            />
            <Pagination
              ordersLength={orders.length}
              metaTotal={pagination.meta.total}
              metaPerPage={pagination.meta.per_page}
              handlePageChange={this.handlePageChange}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

JobList.propTypes = {
  loadedContractor: PropTypes.object.isRequired,
  loadedContractorId: PropTypes.string,
}

export default withRouter(withTranslation()(JobList))
