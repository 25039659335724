import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'

import { WithUserContext } from '../../contexts/UserContext'
import ContractorPanel from './ContractorPanel'
import CashierPanel from './CashierPanel'
import ClientPanel from './ClientPanel'
import setDocumentTitle from '../../helpers/setDocumentTitle'
import PartnerPanel from './PartnerPanel'

const Dashboard = ({ user, t }) => {
  setDocumentTitle(t('page.title.dashboard'))

  let cashier = user.user.user_type === 'cashier'
  let contractor = user.user.user_type === 'contractor'
  let client = user.user.user_type === 'client'
  let partner = user.user.user_type === 'partner'

  return (
    <Fragment>
      {contractor && <ContractorPanel />}
      {cashier && <CashierPanel />}
      {client && <ClientPanel />}
      {partner && <PartnerPanel />}
    </Fragment>
  )
}

export default WithUserContext(withTranslation()(Dashboard))
