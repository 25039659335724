import React, { Component, Fragment } from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { Tabs, message } from 'antd'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Scheduler from '../../components/Scheduler'
import MunicipalityPicker from '../../components/MunicipalityPicker'
import { apiGet, apiPost } from '../../api'
import UserInfoForm from '../../components/UserInfoForm'
import UserAvatar from '../../components/UserAvatar'
import UserChangePassword from '../../components/UserChangePassword'
import Loader from '../../components/Loader'
import UserInfoFormShort from '../../components/UserInfoFormShort'
import errorHelper from '../../helpers/errorHelper'
import setDocumentTitle from '../../helpers/setDocumentTitle'

const { TabPane } = Tabs

class Profile extends Component {
  state = {
    loadedUser: {},
  }

  componentDidMount() {
    setDocumentTitle(this.props.t('page.title.profile'))

    this.getUserProfile()
  }

  editTime = async workingDays => {
    try {
      const res = await apiPost.handleContractorWorkingHours(
        workingDays
      )
      let workingHours = _get(res, 'data', null)
      if (!workingHours)
        throw new Error(this.props.t('profile.editWorkingTimeFailed'))
      message.success(workingHours.message)
    } catch (error) {
      errorHelper(
        error,
        this.props.t('profile.editWorkingTimeFailed')
      )
    }
  }

  getUserProfile = async () => {
    try {
      let res
      if (this.props.user.user_type === 'contractor') {
        res = await apiGet.getContractor()
      }
      if (this.props.user.user_type === 'partner') {
        res = await apiGet.getPartner()
      }
      if (this.props.user.user_type === 'client') {
        res = await apiGet.getClient()
      }
      if (
        this.props.user.user_type === 'boothman' ||
        this.props.user.user_type === 'cashier'
      ) {
        res = await apiGet.getUser()
      }
      let user = _get(res, 'data', null)
      if (!user)
        throw new Error(this.props.t('profile.failedToGetUserInfo'))
      this.setState({
        loadedUser: user,
      })
    } catch (error) {
      errorHelper(error, this.props.t('profile.failedToGetUserInfo'))
    }
  }

  editPersonalInfo = async () => {
    const { loadedUser } = this.state
    let { user } = this.props

    let userObject = {
      first_name: loadedUser.first_name,
      last_name: loadedUser.last_name,
      address: loadedUser.address,
      phone: loadedUser.phone,
      phone_additional: loadedUser.phone_additional,
    }

    let onlyClientObject = {
      first_name: loadedUser.first_name,
      last_name: loadedUser.last_name,
      address: loadedUser.address,
      phone: loadedUser.phone,
      phone_additional: loadedUser.phone_additional,
      street: loadedUser.street,
      street_number: loadedUser.street_number,
      floor: loadedUser.floor,
      apartment: loadedUser.apartment,
      municipality_id:
        loadedUser.municipality && loadedUser.municipality.id,
      has_parking: loadedUser.has_parking,
    }

    let userObjectOnlyName = {
      name: loadedUser.name,
    }

    try {
      let res
      if (user.user_type === 'contractor') {
        res = await apiPost.handleContractorProfileEdit(userObject)
      }
      if (user.user_type === 'client') {
        res = await apiPost.handleClientProfileEdit(onlyClientObject)
      }
      if (
        user.user_type === 'boothman' ||
        user.user_type === 'cashier'
      ) {
        res = await apiPost.handleUserProfileEdit(userObjectOnlyName)
      }
      if (user.user_type === 'partner') {
        res = await apiPost.handlePartnerProfileEdit(userObject)
      }

      let userChanged = _get(res, 'data', null)
      if (!userChanged)
        throw new Error(this.props.t('profile.editUserInfoFailed'))
      message.success(userChanged.message)
      this.setState({ edit: false })
    } catch (error) {
      errorHelper(error, this.props.t('profile.editUserInfoFailed'))
    }
  }

  handleInputChange = (field, value) => {
    // console.log(field, value)

    let newObj = Object.assign({}, this.state.loadedUser)
    newObj[field] = value
    this.setState({ loadedUser: newObj })
  }

  selectMunicipality = (value, name) => {
    let newObj = Object.assign({}, this.state.loadedUser)
    newObj['municipality'] = {
      id: value,
      name,
    }

    this.setState({ loadedUser: newObj })
  }

  render() {
    const { user } = this.props
    const { loadedUser } = this.state

    let isContractor = user.user_type === 'contractor'
    let isClient = user.user_type === 'client'
    let isPartner = user.user_type === 'partner'
    let isUser =
      user.user_type === 'boothman' || user.user_type === 'cashier'

    return (
      <Fragment>
        <UserAvatar
          userType={user.user_type}
          imageUrl={loadedUser.image && loadedUser.image}
        />
        {!_isEmpty(loadedUser) ? (
          <Tabs>
            {isContractor && (
              <TabPane tab="Radno vreme" key="0">
                <Scheduler
                  workStatus={this.state.loadedUser.active}
                  workingDays={
                    loadedUser.working_hours &&
                    loadedUser.working_hours
                  }
                  editTime={this.editTime}
                />
              </TabPane>
            )}
            {isContractor && (
              <TabPane tab="Opštine" key="1">
                <MunicipalityPicker
                  selectedMunicipality={loadedUser.municipalities}
                />
              </TabPane>
            )}
            {isUser && (
              <TabPane tab="Podaci" key="2">
                <UserInfoFormShort
                  user={loadedUser}
                  handleUserInputs={this.handleInputChange}
                  handleSave={this.editPersonalInfo}
                />
              </TabPane>
            )}
            {(isContractor || isClient || isPartner) && (
              <TabPane tab="Podaci" key="3">
                <UserInfoForm
                  user={loadedUser}
                  handleUserInputs={this.handleInputChange}
                  handleSave={this.editPersonalInfo}
                  clientRole={isClient}
                  selectMunicipality={this.selectMunicipality}
                />
              </TabPane>
            )}

            <TabPane tab="Promena lozinke" key="4">
              <UserChangePassword />
            </TabPane>
          </Tabs>
        ) : (
          <Loader />
        )}
      </Fragment>
    )
  }
}

Profile.propTypes = {
  user: PropTypes.object.isRequired,
}

export default withTranslation()(Profile)
