/* eslint-disable default-case */
import React, { Component, Fragment } from 'react'
import { Select, Button, notification } from 'antd'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import _findIndex from 'lodash/findIndex'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { apiGet } from '../../../api'
import InputWrapper from '../../../components/OrderSections/InputWrapper'
import InputSkeleton from '../../../components/InputSkeleton'
import errorHelper from '../../../helpers/errorHelper'
import ServiceOrderSummary from './ServiceOrderSummary'

import classes from './SelectService.module.scss'

const { Option } = Select

class Services extends Component {
  state = {
    fields: [],
    selectedValues: [],
    sumTime: 0,
    sumPrice: 0,
    editSelectValues: {
      select: [],
      option: [],
    },
    disabledValues: [],
  }

  componentDidMount() {
    this.getService(1)
  }

  componentDidUpdate(props) {
    if (props.selectedService !== this.props.selectedService) {
      this.disableSelectedValuesInList()
    }
  }

  getService = async (id, index) => {
    let { fields } = this.state

    try {
      const res = await apiGet.getService(id)
      const service = _get(res, 'data', null)
      if (!service)
        throw new Error(
          this.props.t('orderService.failedToFetchService')
        )
      // find index and delete from that on
      fields.splice(index + 1)
      if (service.length > 1) {
        this.setState({ fields: [...fields, service] })
      } else {
        this.setState({ fields: [...fields, ...service] })
      }
    } catch (error) {
      errorHelper(
        error,
        this.props.t('orderService.failedToFetchService')
      )
    }
  }

  calculateTimeAndPrice = () => {
    let { selectedValues } = this.state
    this.setState({ sumTime: 0, sumPrice: 0 })

    let selectTime = _filter(selectedValues, 'time')
      ? _filter(selectedValues, 'time').reduce((sum, cur) => {
          return sum + cur.time
        }, 0)
      : 0

    let selectPrice = _filter(selectedValues, 'price')
      ? _filter(selectedValues, 'price').reduce((sum, cur) => {
          return sum + cur.price
        }, 0)
      : 0

    this.setState(
      {
        sumTime: selectTime,
        sumPrice: selectPrice,
      },
      () => this.sendValues()
    )
  }

  selectHandler = (index, id, value) => {
    let { selectedValues } = this.state

    this.resetingAllFields(index)
    // this.getService(id, index)

    let time = value.props.option.value ? value.props.option.value : 0
    let price = value.props.option.itemPrice
      ? value.props.option.itemPrice
      : 0

    let repeatedObject =
      selectedValues[_findIndex(selectedValues, index)]

    if (repeatedObject) {
      let modifiedArray = selectedValues
      modifiedArray.splice(
        _findIndex(modifiedArray, repeatedObject),
        1
      )

      this.setState(
        {
          selectedValues: [
            ...modifiedArray,
            {
              [index]: id,
              time: time,
              parent_id: value.props.option.parent_id,
              id: id,
              price: price,
              display_name: value.props.option.display_name,
            },
          ],
        },
        () => {
          this.calculateTimeAndPrice()
          // this.sendValues()
        }
      )
    } else {
      this.setState(
        {
          selectedValues: [
            ...selectedValues,
            {
              [index]: id,
              time: time,
              parent_id: value.props.option.parent_id,
              id: id,
              price: price,
              display_name: value.props.option.display_name,
            },
          ],
        },
        () => {
          this.calculateTimeAndPrice()
          // this.sendValues()
        }
      )
    }

    // setTimeout(() => {
    //   this.sendValues()
    // }, 1000)
  }

  renderElement = (el, index) => {
    switch (el.type) {
      case 'select':
        return (
          <InputWrapper key={el.id}>
            <Select
              className={classes.service__select}
              size="large"
              showSearch
              placeholder={el.placeholder}
              optionFilterProp="children"
              onChange={(id, value, options) =>
                this.selectHandler(index, id, value, options)
              }
              filterOption={(input, option) => {
                return (
                  option.props.children[0]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.children[2]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                )
              }}
            >
              {el.items.map((article, i) => {
                // disabled values are those that are already selected for ordering
                let disabledValues = this.state.disabledValues.find(
                  d => {
                    return d === article.id
                  }
                )

                return (
                  <Option
                    disabled={!!disabledValues}
                    key={article.id}
                    value={article.id}
                    option={{
                      value: article.value,
                      parent_id: article.parent_id,
                      itemPrice: article.price,
                      display_name: article.display_name,
                    }}
                  >
                    {article.code} - {article.display_name}
                  </Option>
                )
              })}
            </Select>
          </InputWrapper>
        )
    }
  }

  resetingAllFields = (index, deleteFields) => {
    if (deleteFields) {
      this.setState({ fields: [] })
      this.getService(1)
    }
    if (index === 0) {
      this.setState({
        selectedValues: [],
        radioValues: [],
        checkboxValues: [],
        sumTime: 0,
        sumPrice: 0,
      })
    }
  }

  disableSelectedValuesInList = () => {
    let { selectedService } = this.props

    let filteredSelectedService = Object.keys(selectedService).map(
      service => {
        return selectedService[service].values
      }
    )

    let findId = filteredSelectedService.map(s => _find(s, 'code').id)

    this.setState({
      disabledValues: findId,
    })
  }

  sendValues = () => {
    let { handleSelectedService, timeSumm, t } = this.props
    let { selectedValues, sumTime, sumPrice } = this.state

    let values = []
    selectedValues.filter(r => r.time).map(v => values.push(v.id))

    let timeCalculated = timeSumm

    if (sumTime + timeCalculated > 360) {
      // message.error(t('orderService.exceededHours'))
      notification['info']({
        message: t('orderService.exceededHours'),
        description: t('orderService.exceededHoursDescription'),
      })
    } else {
      handleSelectedService(values, sumTime, sumPrice)
      this.resetingAllFields(0, true)
    }
  }

  render() {
    let { fields } = this.state
    let {
      selectedService,
      handleCleanStateFake,
      timeSumm,
      priceSumm,
    } = this.props

    let renderFields = fields.map((el, index) => {
      if (el.length > 1) {
        return el.map(element => {
          return this.renderElement(element, index)
        })
      } else {
        return this.renderElement(el, index)
      }
    })

    return (
      <Fragment>
        {fields.length ? (
          renderFields
        ) : (
          <InputSkeleton height="40px" width="100%" />
        )}

        {!_isEmpty(selectedService) && (
          <div
            className={[
              'btn__container',
              classes.service__btnContainer,
            ].join(' ')}
          >
            <ServiceOrderSummary
              time={timeSumm}
              price={priceSumm}
              closed={false}
            />
            {!_isEmpty(selectedService) && (
              <Button
                type="primary"
                onClick={handleCleanStateFake}
                size="large"
              >
                Dalje
              </Button>
            )}
          </div>
        )}
      </Fragment>
    )
  }
}

Services.propTypes = {
  handleSelectedService: PropTypes.func.isRequired,
  handleCleanStateFake: PropTypes.func.isRequired,
  selectedService: PropTypes.object.isRequired,
  timeSumm: PropTypes.number.isRequired,
  priceSumm: PropTypes.number.isRequired,
}

export default withTranslation()(Services)
