import React, { Component } from 'react'
import { Select, message, Button } from 'antd'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'

import { apiGet, apiPost } from '../../api'
import { WithUserContext } from '../../contexts/UserContext'
import errorHelper from '../../helpers/errorHelper'

const { Option } = Select

class MunicipalityPicker extends Component {
  state = {
    municipalities: [],
    selectedMunicipalities: [],
  }

  componentDidMount() {
    this.getMunicipalities()
    let selectedMunicipalities = this.props.selectedMunicipality.map(
      m => {
        return m.name
      }
    )
    this.setState({ selectedMunicipalities })
  }

  getMunicipalities = async () => {
    try {
      const res = await apiGet.getMunicipalities()
      let municipalities = _get(res, 'data', null)
      if (!municipalities)
        throw new Error(
          this.props.t(
            'component.MunicipalityPicker.failedToFetchMunicipalities'
          )
        )
      this.setState({ municipalities })
    } catch (error) {
      errorHelper(
        error,
        this.props.t(
          'component.MunicipalityPicker.failedToFetchMunicipalities'
        )
      )
    }
  }

  saveMunicipalities = async () => {
    let municipalityId = this.state.municipalities
      .filter(g => this.state.selectedMunicipalities.includes(g.name))
      .map(g => g.id)

    try {
      let res
      if (this.props.withId) {
        res = await apiPost.handleSaveContractorMunicipalityWithId(
          municipalityId,
          this.props.withId
        )
      } else {
        res = await apiPost.handleSaveContractorMunicipality(
          municipalityId
        )
      }

      let municipality = _get(res, 'data', null)
      if (!municipality)
        throw new Error(
          this.props.t(
            'component.MunicipalityPicker.failedToSaveMunicipalities'
          )
        )
      message.success(
        this.props.t(
          'component.MunicipalityPicker.municipalitiesSavedSuccessfully'
        )
      )
    } catch (error) {
      errorHelper(
        error,
        this.props.t(
          'component.MunicipalityPicker.failedToSaveMunicipalities'
        )
      )
    }
  }

  selectMunicipalities = selectedMunicipalities => {
    this.setState({
      selectedMunicipalities,
    })
  }

  render() {
    const { municipalities, selectedMunicipalities } = this.state

    const municipalitiesMapped = municipalities.map(m => (
      <Option key={m.name}>{m.name}</Option>
    ))

    return (
      <div>
        <div className="form__section">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Izaberite opštine koje pokrivate"
            onChange={e => this.selectMunicipalities(e)}
            value={selectedMunicipalities}
          >
            {municipalitiesMapped}
          </Select>
        </div>
        <div className="btn__container">
          <Button
            type="primary"
            onClick={this.saveMunicipalities}
            size="large"
          >
            Snimi
          </Button>
        </div>
      </div>
    )
  }
}

export default WithUserContext(withTranslation()(MunicipalityPicker))
