import _get from 'lodash/get'
import { message } from 'antd'

const errorHelper = (error, defaultMsg) => {
  let errorMsgOne = _get(error.response, 'message', null)

  if (errorMsgOne) {
    message.error(errorMsgOne)
  } else {
    let errorMsg = _get(error.response.data, 'message', null)
    if (!errorMsg) {
      message.error(defaultMsg)
      throw new Error(defaultMsg)
    } else if (errorMsg) {
      message.error(errorMsg)
    } else if (!errorMsgOne) {
      message.error(defaultMsg)
      throw new Error(defaultMsg)
    }
  }
}

export default errorHelper
