import React, { Component, Fragment } from 'react'
import { Select, List } from 'antd'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Pagination from '../../../components/Pagination'
import { apiGet } from '../../../api'
import errorHelper from '../../../helpers/errorHelper'
import EmptyListImage from '../../../components/EmptyListImage'
import JobCard from '../../../components/JobCard'

import classes from '../Dashboard.module.scss'

const { Option } = Select

class JobList extends Component {
  state = {
    orders: null,
    orderType: 'active',
    pagination: {
      meta: {},
      links: {},
    },
    loading: false,
  }

  componentDidMount() {
    this._isMounted = true
    this.getContractorOrders(this.state.orderType)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getContractorOrders = async (type, page) => {
    this.setState({ loading: true })

    try {
      let res
      if (page) {
        res = await apiGet.getContractorOrders(type, page)
      } else {
        res = await apiGet.getContractorOrders(type)
      }
      let orders = _get(res, 'data', null)
      if (!orders)
        throw new Error(this.props.t('dashboard.orderFetchFailed'))
      if (this._isMounted) {
        this.setState({
          orders: orders.data,
          pagination: { meta: orders.meta, links: orders.links },
          loading: false,
        })
      }
    } catch (error) {
      this.setState({ loading: false })
      errorHelper(error, this.props.t('dashboard.orderFetchFailed'))
    }
  }

  filterOrderType = type => {
    this.setState({ orderType: type })
    this.getContractorOrders(type)
  }

  removeJob = id => {
    let newOrdersObject = this.state.orders.filter(
      order => order.id !== id
    )

    this.setState({
      orders: newOrdersObject,
    })
  }

  handlePageChange = page => {
    this.getContractorOrders(this.state.orderType, page)
  }

  render() {
    let { orders, pagination, loading, orderType } = this.state

    return (
      <Fragment>
        <div className={classes.dashboard__titleContainer}>
          <h3 className={classes.dashboard__name}>Poslovi</h3>
          <Select
            defaultValue={orderType}
            className={classes.dashboard__select}
            onChange={this.filterOrderType}
          >
            <Option value="active">Aktivni</Option>
            <Option value="finished">Završeni</Option>
            <Option value="">Svi</Option>
          </Select>
        </div>

        {orders && (
          <Fragment>
            <List
              locale={EmptyListImage}
              loading={loading}
              itemLayout="horizontal"
              dataSource={orders}
              renderItem={order => (
                <JobCard
                  key={order.id}
                  jobId={order.id}
                  scheduledAt={order.scheduled_at}
                  duration={order.duration}
                  contact={order.contact}
                  address={order.address}
                  items={order.items}
                  canFinish={order.can_finish}
                  removeJob={this.removeJob}
                  signature={order.client_signature}
                  finished={order.finished}
                />
              )}
            />
            <Pagination
              ordersLength={orders.length}
              metaTotal={pagination.meta.total}
              metaPerPage={pagination.meta.per_page}
              handlePageChange={this.handlePageChange}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

JobList.propTypes = {
  orders: PropTypes.array,
}

export default withTranslation()(JobList)
