import moment from 'moment'

let timeFormat = minutes => {
  return `${moment
    .utc()
    .startOf('day')
    .add({ minutes: minutes })
    .format('H:mm')}h`
}

export default timeFormat
