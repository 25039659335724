import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BACKEND_URL

export const apiPost = {
  // AUTH
  registration: (email, password, first_name, last_name) => {
    return axios.post(`${BASE_URL}/api/register`, {
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name,
    })
  },
  login: (email, password) => {
    return axios.post(`${BASE_URL}/api/login`, {
      email: email,
      password: password,
    })
  },
  handleCodeLogin: code => {
    return axios.post(`${BASE_URL}/api/login/code`, { code })
  },
  logOut: () => {
    return axios.post(`${BASE_URL}/api/logout`)
  },
  //// Verification
  resendEmail: email => {
    return axios.post(`${BASE_URL}/api/email/resend`, {
      email,
    })
  },
  verifyRegistration: data => {
    return axios.post(`${BASE_URL}/api/email/verify`, data)
  },
  //// Social
  handleGoogle: code => {
    return axios.post(
      `${BASE_URL}/api/oauth/google/callback?code=${code}`
    )
  },
  handleFacebook: code => {
    return axios.post(
      `${BASE_URL}/api/oauth/facebook/callback?code=${code}`
    )
  },
  //// Password
  resetLink: email => {
    return axios.post(`${BASE_URL}/api/password/email`, {
      email: email,
    })
  },
  resetPassword: (email, password, password_confirmation, token) => {
    return axios.post(`${BASE_URL}/api/password/reset`, {
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      token: token,
    })
  },
  updatePassword: (pass, repeatedPass) => {
    return axios.post(`${BASE_URL}/api/password/update`, {
      password: pass,
      password_confirmation: repeatedPass,
    })
  },
  // SERVICES
  getServiceData: value => {
    return axios.post(`${BASE_URL}/api/services/selected`, {
      services: value,
    })
  },
  // ORDERS
  createOrder: values => {
    return axios.post(`${BASE_URL}/api/orders`, values)
  },
  //// Feedback
  sendFeedback: values => {
    return axios.post(`${BASE_URL}/api/feedback/post`, values)
  },
  // CONTRACTOR
  //// Availability
  getAvailableDates: (municipality, fromDate, toDate, duration) => {
    return axios.post(`${BASE_URL}/api/contractors/available/dates`, {
      municipality,
      from_date: fromDate,
      to_date: toDate,
      duration,
    })
  },
  getAvailableTime: (municipality, date, duration) => {
    return axios.post(`${BASE_URL}/api/contractors/available/day`, {
      municipality,
      date,
      duration,
    })
  },
  getAvailableContractors: (
    municipality,
    date,
    time,
    duration,
    page
  ) => {
    if (page) {
      return axios.post(
        `${BASE_URL}/api/contractors/available/time?page=${page}`,
        {
          municipality,
          date,
          time,
          duration,
        }
      )
    } else {
      return axios.post(
        `${BASE_URL}/api/contractors/available/time`,
        {
          municipality,
          date,
          time,
          duration,
        }
      )
    }
  },
  //// Working hours
  handleContractorWorkingHours: hours => {
    return axios.post(`${BASE_URL}/api/contractor/working-hours`, {
      working_hours: hours,
    })
  },
  //// Municipalities
  handleSaveContractorMunicipality: municipalities => {
    return axios.post(`${BASE_URL}/api/contractor/municipalities`, {
      municipalities: municipalities,
    })
  },
  //// Account
  handleContractorProfileEdit: contractor => {
    return axios.post(`${BASE_URL}/api/contractor`, contractor)
  },
  handleContractorAvatar: image => {
    return axios.post(`${BASE_URL}/api/contractor/avatar`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  handleContractorWorkingStatus: () => {
    return axios.post(`${BASE_URL}/api/contractor/status`)
  },
  //// Orders
  finishOrderItem: (orderItemId, duration, note) => {
    return axios.post(
      `${BASE_URL}/api/contractor/order-items/finish`,
      { order_item: orderItemId, duration, note }
    )
  },
  handleContractorOrderItemUploadImage: data => {
    return axios.post(
      `${BASE_URL}/api/contractor/order-items/images`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },
  handleContractorOrderItemDeleteImage: image_id => {
    return axios.post(
      `${BASE_URL}/api/contractor/order-items/images/delete`,
      { image_id }
    )
  },
  finishOrder: (id, signature, contractorId) => {
    if (contractorId) {
      return axios.post(
        `${BASE_URL}/api/contractor/orders/finish/${contractorId}`,
        {
          order: id,
          signature,
        }
      )
    } else {
      return axios.post(`${BASE_URL}/api/contractor/orders/finish`, {
        order: id,
        signature,
      })
    }
  },
  // PARTNER
  handlePartnerChangeContractor: (order, contractor) => {
    return axios.post(`${BASE_URL}/api/partner/change-contractor`, {
      order,
      contractor,
    })
  },
  handlePartnerProfileEdit: partner => {
    return axios.post(`${BASE_URL}/api/partner`, partner)
  },
  handlePartnerAvatar: image => {
    return axios.post(`${BASE_URL}/api/partner/avatar`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  //// Contractor actions
  ////// Working hours
  handleContractorWorkingHoursWithId: (hours, id) => {
    return axios.post(
      `${BASE_URL}/api/contractor/working-hours/${id}`,
      {
        working_hours: hours,
      }
    )
  },
  ////// Municipalities
  handleSaveContractorMunicipalityWithId: (municipalities, id) => {
    return axios.post(
      `${BASE_URL}/api/contractor/municipalities/${id}`,
      {
        municipalities: municipalities,
      }
    )
  },
  ////// Account
  handleContractorProfileEditWithId: (contractor, id) => {
    return axios.post(`${BASE_URL}/api/contractor/${id}`, contractor)
  },
  handleContractorAvatarWithId: (image, id) => {
    return axios.post(
      `${BASE_URL}/api/contractor/avatar/${id}`,
      image,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },
  handleContractorWorkingStatusWithId: id => {
    return axios.post(`${BASE_URL}/api/contractor/status/${id}`)
  },
  // CLIENT
  handleClientProfileEdit: client => {
    return axios.post(`${BASE_URL}/api/client`, client)
  },
  clientCancelOrder: id => {
    return axios.post(`${BASE_URL}/api/client/orders/cancel`, {
      order: id,
    })
  },
  handleClientFeedback: (id, rating, comment) => {
    return axios.post(`${BASE_URL}/api/client/feedback`, {
      order: id,
      rating,
      message: comment,
    })
  },
  // BOOTHMAN
  handleUserProfileEdit: client => {
    return axios.post(`${BASE_URL}/api/user/update`, client)
  },
  // CASHIER
  handleCashierPayment: (id, receipt_number, payment_type) => {
    return axios.post(`${BASE_URL}/api/cashier/order-paid`, {
      order: id,
      receipt_number,
      payment_type,
    })
  },
  // PAYMENTS
  payment: id => {
    return axios.post(`${BASE_URL}/api/payments`, { order: id })
  },
  paymentCheck: code => {
    return axios.post(`${BASE_URL}/api/payments/check`, { code })
  },
  //JOB APPLICATION
  createJobApplication: informations => {
    return axios.post(
      `${BASE_URL}/api/job-application`,
      informations,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },
}

export const apiGet = {
  // AUTH
  getUser: () => {
    return axios.get(`${BASE_URL}/api/user`)
  },
  //// Social
  getGoogle: () => {
    return axios.get(`${BASE_URL}/api/oauth/google`)
  },
  getFacebook: () => {
    return axios.get(`${BASE_URL}/api/oauth/facebook`)
  },
  // SERVICES
  getServiceList: () => {
    return axios.get(`${BASE_URL}/api/services`)
  },
  getService: id => {
    return axios.get(`${BASE_URL}/api/services/${id}`)
  },
  // GEO
  getCities: q => {
    return axios.get(`${BASE_URL}/api/geo/cities`, {
      params: {
        q: q,
      },
    })
  },
  getCityById: id => {
    return axios.get(`${BASE_URL}/api/geo/cities/${id}`)
  },
  getMunicipalities: () => {
    return axios.get(`${BASE_URL}/api/geo/municipalities`)
  },
  // ORDERS
  getOrderById: id => {
    return axios.get(`${BASE_URL}/api/orders/${id}`)
  },
  //// Feedback
  validateFeedback: code => {
    return axios.get(`${BASE_URL}/api/feedback`, { params: { code } })
  },
  // CONTRACTOR
  getContractor: () => {
    return axios.get(`${BASE_URL}/api/contractor`)
  },
  //// Working hours
  getContractorWorkingHours: () => {
    return axios.get(`${BASE_URL}/api/contractor/working-hours`)
  },
  //// Orders
  getContractorOrders: (type, page) => {
    if (page) {
      return axios.get(`${BASE_URL}/api/contractor/orders`, {
        params: { scope: type, page: page },
      })
    } else {
      return axios.get(`${BASE_URL}/api/contractor/orders`, {
        params: { scope: type },
      })
    }
  },
  //// Feedback
  getContractorFeedback: (id, page) => {
    if (page) {
      return axios.get(`${BASE_URL}/api/feedback/contractor/${id}`, {
        params: { page },
      })
    } else {
      return axios.get(`${BASE_URL}/api/feedback/contractor/${id}`)
    }
  },
  // PARTNER
  getPartner: () => {
    return axios.get(`${BASE_URL}/api/partner`)
  },
  getContractorList: page => {
    if (page) {
      return axios.get(`${BASE_URL}/api/partner/contractors`, {
        params: { page: page },
      })
    } else {
      return axios.get(`${BASE_URL}/api/partner/contractors`)
    }
  },
  getPartnerOrders: (type, page) => {
    if (page) {
      return axios.get(`${BASE_URL}/api/partner/orders`, {
        params: {
          scope: type,
          page: page,
        },
      })
    } else {
      return axios.get(`${BASE_URL}/api/partner/orders`, {
        params: { scope: type },
      })
    }
  },
  //// Contractor actions
  getContractorById: id => {
    return axios.get(`${BASE_URL}/api/contractor/${id}`)
  },
  ////// Orders
  getContractorOrdersWithId: (type, id, page) => {
    if (page) {
      return axios.get(`${BASE_URL}/api/contractor/orders/${id}`, {
        params: { scope: type, page: page },
      })
    } else {
      return axios.get(`${BASE_URL}/api/contractor/orders/${id}`, {
        params: { scope: type },
      })
    }
  },
  // CLIENT
  getClientOrders: (type, page) => {
    if (page) {
      return axios.get(`${BASE_URL}/api/client/orders`, {
        params: { scope: type, page: page },
      })
    } else {
      return axios.get(`${BASE_URL}/api/client/orders`, {
        params: { scope: type },
      })
    }
  },
  getClient: () => {
    return axios.get(`${BASE_URL}/api/client`)
  },
  // BOOTHMAN
  clientSearch: query => {
    return axios.get(`${BASE_URL}/api/client/search`, {
      params: { q: query },
    })
  },
  // CASHIER
  getCashierOrders: (type, page) => {
    if (page) {
      return axios.get(`${BASE_URL}/api/cashier/orders`, {
        params: { scope: type, page: page },
      })
    } else {
      return axios.get(`${BASE_URL}/api/cashier/orders`, {
        params: { scope: type },
      })
    }
  },
  // PRODUCT
  productSearch: q =>
    axios.get(`${BASE_URL}/api/products`, {
      params: { q },
    }),
  productCategories: () => axios.get(`${BASE_URL}/api/categories`),
}
