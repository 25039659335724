import React from 'react'

import classes from './Section.module.scss'

const Section = ({ children, customStyle }) => (
  <div className={classes.section} style={customStyle}>
    {children}
  </div>
)

export default Section
