import React from 'react'
import moment from 'moment'
import { Rate } from 'antd'
import PropTypes from 'prop-types'

import classes from './FeedbackItems.module.scss'

const FeedbackItems = ({ feedbackData }) => {
  return feedbackData.data.map(fb => {
    return (
      <div
        key={fb.created_at}
        className={classes.feedback__container}
      >
        <div className={classes.feedback__section}>
          <div className={classes.feedback__feedbackHeader}>
            <p className={classes.feedback__feedbackClient}>
              {fb.client.first_name} {fb.client.last_name}
            </p>
            <Rate
              defaultValue={fb.rating}
              disabled
              allowHalf
              className={classes.feedback__rate}
            />
          </div>
          <p>{fb.message}</p>
          <span className={classes.feedback__feedbackDate}>
            {moment(fb.created_at).format('DD.MM.YYYY.')}
          </span>
        </div>
      </div>
    )
  })
}

FeedbackItems.propTypes = {
  feedbackData: PropTypes.object.isRequired,
}

export default FeedbackItems
