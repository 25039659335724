import React, { Component, Fragment } from 'react'
import { Result, Button } from 'antd'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { withTranslation } from 'react-i18next'

import Loader from '../../components/Loader'
import { apiPost } from '../../api'
import JobOverview from '../JobOverview'
import setDocumentTitle from '../../helpers/setDocumentTitle'
import PlatformInformation from '../../components/PlatformInformation'
import OrderOverview from '../../components/OrderOverview'
import normalizeDataForOrderPreview from '../../helpers/normalizeDataForOrderPreview'

class PaymendLanding extends Component {
  state = {
    paymentStatus: null,
    order: {},
    transaction: {},
  }

  componentDidMount() {
    let { t, location } = this.props

    setDocumentTitle(t('page.title.paymentLanding'))

    this.handlePaymentCheck(queryString.parse(location.search).code)
  }

  handlePaymentCheck = async code => {
    try {
      const res = await apiPost.paymentCheck(code)

      let payment = _get(res, 'data', null)
      let transactionInformation = _get(res, 'data.transaction')

      if (!payment)
        throw new Error(this.props.t('paymentLanding.paymentFailed'))

      let {
        pgOrderId,
        pgTranApprCode,
        responseMsg,
        responseCode,
        pgTranId,
        pgTranDate,
        amount,
        pgTranRefId,
      } = transactionInformation

      this.setState({ paymentStatus: true })
      if (transactionInformation) {
        this.setState({
          transaction: {
            'Broj narudžbine': pgOrderId,
            'Autorizacioni kod': pgTranApprCode,
            'Status transakcije': responseMsg,
            'Kod statusa transakcije': responseCode,
            'Broj transakcije': pgTranId,
            'Datum transakcije': pgTranDate,
            'Iznos transakcije': amount,
            'Referentni ID transakcije': pgTranRefId,
          },
          order: payment.order,
        })
      }
    } catch (error) {
      const data = _get(error, 'response.data', null)

      if (data) {
        const { order, transaction } = data

        this.setState({
          paymentStatus: false,
          order: order,
          transaction: {
            'Broj narudžbine': transaction.pgOrderId,
            'Autorizacioni kod': transaction.pgTranApprCode,
            'Status transakcije': transaction.responseMsg,
            'Kod statusa transakcije': transaction.responseCode,
            'Broj transakcije': transaction.pgTranId,
            'Datum transakcije': transaction.pgTranDate,
            'Iznos transakcije': transaction.amount,
            'Referentni ID transakcije': transaction.pgTranRefId,
          },
        })
      }

      // Old error message
      // message.error(this.props.t('paymentLanding.paymentFailed'))
      // errorHelper(error, this.props.t('paymentLanding.paymentFailed'))
    }
  }

  render() {
    let { paymentStatus, order, transaction } = this.state
    let orderNotEmpty = !_isEmpty(order)

    let renderView = () => {
      if (paymentStatus === true) {
        return (
          <Fragment>
            {orderNotEmpty && (
              <PlatformInformation
                orderNumber={
                  normalizeDataForOrderPreview(order).details
                    .orderNumber
                }
              />
            )}
            <Result
              status="success"
              title="Uspešno ste platili Homea uslugu!"
              extra={[
                <Link to="/" key="landing">
                  <Button size="large" key="home">
                    Početna strana
                  </Button>
                </Link>,
                <Link to="/order-service" key="new order">
                  <Button
                    size="large"
                    key="new_service"
                    type="primary"
                  >
                    Nova usluga
                  </Button>
                </Link>,
              ]}
            />
            {orderNotEmpty && (
              <OrderOverview
                user={normalizeDataForOrderPreview(order).user}
                service={normalizeDataForOrderPreview(order).service}
                alertType="success"
                transactionInfo={transaction}
                edit
                isAlertVisible
              />
            )}
          </Fragment>
        )
      } else if (paymentStatus === false) {
        return (
          <JobOverview
            allowJobOverview={true}
            editOrder={true}
            order={normalizeDataForOrderPreview(order)}
            transactionInfo={transaction}
          />
        )
      } else {
        return <Loader />
      }
    }

    return renderView()
  }
}

export default withTranslation()(PaymendLanding)
