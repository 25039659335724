import React, { Fragment } from 'react'
import { Avatar } from 'antd'
import PropTypes from 'prop-types'

import UploadImage from '../UploadImage'

import classes from './UserAvatar.module.scss'

const UserAvatar = ({ userType, imageUrl, userId }) => {
  return (
    <Fragment>
      {userType === 'contractor' || userType === 'partner' ? (
        <div className={classes.image}>
          <UploadImage
            userType={userType}
            userId={userId}
            imageUrl={imageUrl}
          />
        </div>
      ) : (
        <div className={classes.image}>
          <Avatar size={100} icon="user" shape="circle" />
        </div>
      )}
    </Fragment>
  )
}

UserAvatar.propTypes = {
  userType: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  userId: PropTypes.string,
}

export default UserAvatar
